import React, { useContext, useEffect, useState } from 'react';
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import { AvocadoPageContext } from '../../AvocadoPageContext';
import styled from 'styled-components';
import UntitledGallery from '../../../../UntitledGallery';
import Monospace from '../../../../Monospace';
import { useHistory } from 'react-router-dom';

const IMAGES = [
  {
    sizes: '(max-width: 2848px) 100vw, 2848px',
    srcSet: [
      '/images/untitled/IMG_0142_gqbm5h_c_scale,w_600.jpg 600w',
      '/images/untitled/IMG_0142_gqbm5h_c_scale,w_1328.jpg 1328w',
      '/images/untitled/IMG_0142_gqbm5h_c_scale,w_1797.jpg 1797w',
      '/images/untitled/IMG_0142_gqbm5h_c_scale,w_2288.jpg 2288w',
      '/images/untitled/IMG_0142_gqbm5h_c_scale,w_2707.jpg 2707w',
      '/images/untitled/IMG_0142_gqbm5h_c_scale,w_2848.jpg 2848w',
    ],
    src: '/images/untitled/IMG_0142_gqbm5h_c_scale,w_2848.jpg',
  },
  {
    sizes: '(max-width: 2848px) 100vw, 2848px',
    srcSet: [
      '/images/untitled/IMG_0143_m3jxrc_c_scale,w_600.jpg 600w',
      '/images/untitled/IMG_0143_m3jxrc_c_scale,w_1349.jpg 1349w',
      '/images/untitled/IMG_0143_m3jxrc_c_scale,w_1892.jpg 1892w',
      '/images/untitled/IMG_0143_m3jxrc_c_scale,w_2359.jpg 2359w',
      '/images/untitled/IMG_0143_m3jxrc_c_scale,w_2782.jpg 2782w',
      '/images/untitled/IMG_0143_m3jxrc_c_scale,w_2848.jpg 2848w',
    ],
    src: '/images/untitled/IMG_0143_m3jxrc_c_scale,w_2848.jpg',
  },
  {
    sizes: '(max-width: 2848px) 100vw, 2848px',
    srcSet: [
      '/images/untitled/IMG_0144_a6ausf_c_scale,w_600.jpg 600w',
      '/images/untitled/IMG_0144_a6ausf_c_scale,w_1340.jpg 1340w',
      '/images/untitled/IMG_0144_a6ausf_c_scale,w_1881.jpg 1881w',
      '/images/untitled/IMG_0144_a6ausf_c_scale,w_2352.jpg 2352w',
      '/images/untitled/IMG_0144_a6ausf_c_scale,w_2794.jpg 2794w',
      '/images/untitled/IMG_0144_a6ausf_c_scale,w_2848.jpg 2848w',
    ],
    src: '/images/untitled/IMG_0144_a6ausf_c_scale,w_2848.jpg',
  },
  {
    sizes: '(max-width: 2848px) 100vw, 2848px',
    srcSet: [
      '/images/untitled/IMG_0145_lvbbbj_c_scale,w_600.jpg 600w',
      '/images/untitled/IMG_0145_lvbbbj_c_scale,w_1326.jpg 1326w',
      '/images/untitled/IMG_0145_lvbbbj_c_scale,w_1877.jpg 1877w',
      '/images/untitled/IMG_0145_lvbbbj_c_scale,w_2348.jpg 2348w',
      '/images/untitled/IMG_0145_lvbbbj_c_scale,w_2777.jpg 2777w',
      '/images/untitled/IMG_0145_lvbbbj_c_scale,w_2848.jpg 2848w',
    ],
    src: '/images/untitled/IMG_0145_lvbbbj_c_scale,w_2848.jpg',
  },
  {
    sizes: '(max-width: 2848px) 100vw, 2848px',
    srcSet: [
      '/images/untitled/IMG_0146_lvmsq2_c_scale,w_600.jpg 600w',
      '/images/untitled/IMG_0146_lvmsq2_c_scale,w_1345.jpg 1345w',
      '/images/untitled/IMG_0146_lvmsq2_c_scale,w_1886.jpg 1886w',
      '/images/untitled/IMG_0146_lvmsq2_c_scale,w_2357.jpg 2357w',
      '/images/untitled/IMG_0146_lvmsq2_c_scale,w_2787.jpg 2787w',
      '/images/untitled/IMG_0146_lvmsq2_c_scale,w_2848.jpg 2848w',
    ],
    src: '/images/untitled/IMG_0146_lvmsq2_c_scale,w_2848.jpg',
  },
  {
    sizes: '(max-width: 2848px) 100vw, 2848px',
    srcSet: [
      '/images/untitled/IMG_0147_xvxq4j_c_scale,w_600.jpg 600w',
      '/images/untitled/IMG_0147_xvxq4j_c_scale,w_1334.jpg 1334w',
      '/images/untitled/IMG_0147_xvxq4j_c_scale,w_1871.jpg 1871w',
      '/images/untitled/IMG_0147_xvxq4j_c_scale,w_2338.jpg 2338w',
      '/images/untitled/IMG_0147_xvxq4j_c_scale,w_2776.jpg 2776w',
      '/images/untitled/IMG_0147_xvxq4j_c_scale,w_2848.jpg 2848w',
    ],
    src: '/images/untitled/IMG_0147_xvxq4j_c_scale,w_2848.jpg',
  },
];

const Video = styled.video`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
`;

const Unitled = () => {
  const pageCtx = useContext(AvocadoPageContext);
  useEffect(() => pageCtx.setBgColor('#fff'), []);

  const [ready, setReady] = useState(false);

  const [exit, setExit] = useState(false);
  const onExit = () => setExit(true);
  const history = useHistory();
  const onExited = () => history.push('/read/pinky/grid');

  return (
    <Slide in direction="up" timeout={500}>
      <Box margin="auto">
        <Fade in={!exit} timeout={5000} onExited={onExited}>
          <Video autoPlay muted loop>
            <source src="/video/untitled.mp4" type="video/mp4" />
          </Video>
        </Fade>
        <Fade in={ready && !exit} timeout={3000}>
          <Box margin={4}>
            <Box display="flex" alignItems="flex-end">
              <UntitledGallery images={IMAGES} onLoad={setReady} />
              <Box bgcolor="#D2D3D5" width={700} marginLeft={1} padding={2} border={1}>
                <Monospace>
                  One of my earlier works that first brought my interest to the concept of perception was Untitled (2018). It started as a simple curiosity to the history of art and environment: I took pictures of structures, shapes, patterns that surrounded me and tried to rework them in my own way. It was a method of imitating existing reality that turned out to be a technique in which I also dematerialized actual things and objects, giving them a different meaning in the flat two-dimensional space of the drawing. The suggestion of the space was another subject I liked to play around with: I would make the illusion of depth clear in one part of the work and completely destroy it in the other. Creating rules within a pictorial plane and deconstructing them again and again was the key principle of my artistic process.
                  <br /><br />
                  However, even though some of the roots of my depictions were connecting to the real objects, patterns and materials that we could find in the physical world, I never considered them to be representations of those pre-mentioned “already existing things”. They were (and are) imitations or mimics, in their authentic way.
                  <br /><br />
                  I see perception as a tool in making art and delivering it to the audience.
                </Monospace>
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" marginTop={2}>
              <Monospace>
                <b>Untitled (2018)</b><br />
                acrylic on fabric<br />
                1,12 m x 1,70 m<br />
              </Monospace>
              <Monospace style={{ cursor: 'pointer' }} onClick={onExit}>
                <b>NEXT</b>
              </Monospace>
            </Box>
          </Box>
        </Fade>
      </Box>
    </Slide>
  );
};

export default Unitled;
