import React, { useState, useMemo } from 'react';
import { createGlobalStyle } from 'styled-components';

const PotatoPageStyle = createGlobalStyle`
  body {
    background-color: ${props => props.bgColor || props.theme.palette.potato} !important;
    transition: ${props => props.theme.transitions.create(['background-color'], { duration: 1000 })};
  }
`;

export const PotatoPageContext = React.createContext({});

export const PotatoPageProvider = ({ children }) => {
  const [state, set] = useState({});

  const value = useMemo(() => ({
    ...state,
    setBgColor: bgColor => set({ ...state, bgColor }),
  }), [state]);

  return (
    <PotatoPageContext.Provider value={value}>
      <PotatoPageStyle bgColor={state.bgColor} />
      {children}
    </PotatoPageContext.Provider>
  );
};
