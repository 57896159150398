import React from 'react';
import styled, { keyframes } from 'styled-components';

const draw = keyframes`
  0% {
    stroke-dashoffset: -1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
`;

const Stuvg = styled.svg`
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  pointer-events: none;

  path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: ${draw} 4s linear forwards;
  }
`;

const Peppestuffoni = () => (
  <Stuvg width="545" height="739" viewBox="0 0 545 739" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M534.394 1C512.606 5.31734 490.442 9.07834 469.014 15.9411C445.403 23.5031 422.094 33.3391 398.589 41.5134C368.842 51.8583 340.594 66.9652 312.239 82.3142C290.381 94.146 271.494 107.423 253.252 126.85C236.294 144.909 221.96 164.645 212.039 190.063C203.91 210.891 198.601 233.226 191.125 254.425C181.146 282.719 170.072 310.408 160.566 338.971C149.284 372.871 135.219 406.179 117.951 435.729C94.8291 475.3 59.9024 507.059 44.9464 553.822C35.379 583.737 34.1273 621.161 37.3768 652.95C39.488 673.604 45.9469 692.058 49.3761 712.14C50.0559 716.122 49.8204 720.04 49.9368 724.065C50.0001 726.253 51.4406 726.971 51.9554 728.949" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M520.222 1.00007C505.911 1.00007 491.896 1.53133 477.72 3.80155C465.965 5.68393 453.1 13.5539 442.283 19.3892C431.78 25.0542 422.629 34.1606 412.509 40.867C400.071 49.1086 386.837 54.9695 373.539 60.4773C358.401 66.7473 342.534 70.9574 327.673 78.2199C310.541 86.5912 293.854 98.4224 278.33 110.904C253.911 130.536 237.086 162.983 225.062 195.809C202.115 258.453 178.713 322.961 142.188 374.816C124.881 399.388 109.684 422.901 101.929 454.693C95.8269 479.71 86.6077 500.809 77.7064 524.371C72.4735 538.222 68.3275 552.711 63.1278 566.608C55.3251 587.462 46.7994 608.052 39.5778 629.246C31.9191 651.723 29.4926 677.394 31.1671 701.796C31.7902 710.877 35.8332 718.78 38.7928 726.363" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M544.5 0.999998C518.347 6.24968 492.212 11.767 466 16.5158C460.202 17.5661 454.177 18.0178 448.506 19.9638C425.25 27.9439 402.099 37.1244 378.865 45.3924C361.829 51.4546 345.24 58.2622 328.625 66.0801C308.104 75.7359 285.434 83.8747 266.386 97.9737C253.834 107.264 242.197 115.738 233.584 131.304C222.927 150.564 212.632 169.954 202.464 189.632C197.346 199.538 191.809 209.123 187.101 219.37C182.083 230.29 179.101 242.569 175.606 254.353C172.298 265.507 168.813 276.365 167.027 288.114C164.62 303.957 163.257 320.017 159.401 335.451C151.681 366.362 141.208 397.873 125.31 423.374C117.476 435.94 108.962 448.004 99.5172 458.572C92.5795 466.335 85.5874 474.335 79.5558 483.282C69.3145 498.474 61.3944 514.504 49.7258 528.393C33.5373 547.663 6.20022 567.519 4.70051 599.22C3.64355 621.562 6.26803 639.009 14.0644 658.984C21.5476 678.158 32.5624 695.481 40.9226 714.223C44.1455 721.449 49.9501 729.511 49.9501 738" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M511.138 12.6369C486.109 19.7741 460.932 26.2106 436.003 33.8992C427.355 36.5664 418.729 38.97 411.219 45.2488C403.8 51.452 396.12 57.1591 388.005 61.7702C369.284 72.4079 350.136 81.9042 331.261 91.7961C322.681 96.2927 313.594 100.566 305.917 107.24C300.288 112.133 294.199 115.953 288.423 120.529C276.612 129.886 270.333 147.75 263.415 162.479C254.803 180.814 246.114 198.476 236.837 216.21C232.47 224.558 229.195 233.542 225.286 242.213C222.125 249.227 217.538 254.281 213.848 260.746C204.653 276.852 200.416 297.61 194.503 315.841C189.796 330.356 184.906 344.842 181.438 359.946C178.776 371.542 177.482 383.563 174.373 395.001C168.12 418.008 157.717 440.497 146.618 460.152C131.644 486.67 111.949 508.497 96.3221 534.571C73.4509 572.733 61.4074 621.074 59.4832 668.897C58.9436 682.306 59.9585 697.439 55.8946 710.129C54.5519 714.322 53.2116 718.327 51.9135 722.484" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M508.11 11.3438C447.351 41.8023 380.169 54.3736 322.738 95.5314C309.93 104.711 297.694 115.188 285.395 125.414C274.679 134.324 262.841 142.069 253.266 152.997C244.381 163.138 236.627 172.678 230.95 186.256C227.521 194.454 223.586 202.812 221.305 211.684C219.346 219.307 219.415 227.412 218.277 235.245C213.131 270.685 201.32 300.187 187.606 331.142C167.276 377.031 156.471 428.352 124.021 463.888C115.979 472.695 106.436 482.065 99.6864 492.477C95.4728 498.977 93.0198 506.119 90.6028 513.883C87.321 524.425 83.2421 534.319 80.6221 545.202C71.6007 582.675 59.7347 621.075 55.7825 660.062C53.5245 682.335 46.2633 704.377 39.8021 725.07" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M511.138 20.3947C469.235 37.2327 425.034 56.49 387.501 86.4086C359.443 108.774 334.398 136.784 308.384 162.766C292.827 178.305 277.534 194.13 264.761 213.48C236.52 256.258 215.087 306.537 182.952 345.005C177.242 351.84 170.407 356.787 164.505 363.322C152.246 376.898 144.79 399.842 136.693 417.34C121.759 449.621 104.468 479.393 87.0142 509.501C80.9732 519.922 76.2409 530.941 70.5292 541.538C62.4743 556.483 51.6181 566.896 40.5871 578.101C29.7686 589.091 20.6313 602.027 13.8971 617.609C1.92455 645.312 -7.24049 689.371 12.5514 714.726" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M512.147 20.3948C480.788 20.8749 451.492 28.3072 422.097 42.6628C404.867 51.0773 390.242 67.7204 376.118 82.4579C360.057 99.2175 346.38 119.611 330.364 136.62C319.551 148.103 309.3 159.773 297.843 170.093C291.301 175.986 284.003 179.949 277.096 185.178C273.176 188.146 268.593 192.361 263.863 192.361C257.512 192.361 252.459 194.023 246.706 198.108C232.11 208.469 215.256 225.082 207.512 244.512C204.367 252.402 203.523 261.152 201.848 269.653C199.237 282.914 195.551 295.94 191.643 308.658C180.407 345.227 160.052 375.215 144.88 409.008C138.306 423.65 132.71 439.201 125.367 453.257C121.95 459.798 118.594 465.79 115.667 472.723C105.029 497.914 88.6104 520.215 73.7814 541.323C57.2094 564.912 46.622 591.127 36.4939 619.908C29.3757 640.135 25.6666 661.435 20.1211 682.33C16.4007 696.348 16.5886 709.25 16.5886 723.777" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M319.572 142.378C316.38 145.595 311.524 146.68 307.589 147.055C305.903 147.216 304.147 147.073 302.787 145.611C299.389 141.957 297.423 134.598 296.558 129.096C295.665 123.419 296.186 118.806 299.045 114.371C305.53 104.31 323.399 99.5405 330.257 111.512C332.946 116.207 332.496 121.698 332.42 127.306C332.365 131.288 330.071 133.446 328.613 136.545C325.698 142.743 319.713 145.496 314.51 145.496" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M505.083 19.1018C483.01 26.6204 461.17 33.4702 440.376 45.6797C433.012 50.0039 426.053 55.3978 418.508 59.1842C407.928 64.494 398.519 73.6358 388.79 81.0212C377.254 89.7794 368.76 105.129 358.4 115.86C347.646 126.999 338.215 139.596 327.673 150.986C323.573 155.415 318.947 156.257 314.664 159.893C306.707 166.649 299.881 175.465 291.899 182.305C278.864 193.474 266.36 205.689 253.771 217.646C248.366 222.779 243.248 228.335 237.678 233.162C231.855 238.208 229.63 247.686 224.053 253.419C213.129 264.647 213.404 284.369 206.334 298.961C201.325 309.3 197.554 320.473 193.213 331.285C189.508 340.515 185.117 349.338 181.214 358.438C173.994 375.272 171.127 394.086 169.439 413.03C168.206 426.868 169.243 445.867 159.683 455.555C156.966 458.308 153.343 459.233 150.151 460.583C141.888 464.077 133.711 467.929 125.591 471.933C115.188 477.062 100.604 484.468 94.3596 497.218C84.8921 516.548 82.6699 540.31 76.9774 561.58C73.9981 572.712 70.602 583.468 67.1089 594.335C63.1998 606.497 55.5073 615.959 51.9135 628.384C45.7921 649.547 44.8485 673.001 44.8485 695.332C44.8485 702.931 44.4923 711.025 40.8114 717.312" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M507.101 11.3439C483.264 12.7556 459.728 13.443 436.451 20.3947C426.019 23.5103 416.594 28.7494 406.733 33.9711C384.604 45.6887 363.555 60.7647 341.466 72.7605C323.274 82.6403 303.686 92.1182 287.526 107.025C277.512 116.261 266.005 124.567 257.247 135.614C252.539 141.553 246.213 146.859 243.341 154.721C239.096 166.345 233.424 178.069 230.669 190.422C225.846 212.049 223.239 234.537 217.829 255.861C215.444 265.261 210.802 273.686 207.68 282.727C203.478 294.891 198.504 306.579 194.335 318.786C191.927 325.838 190.733 332.955 189.176 340.336C187.111 350.128 182.419 358.492 178.074 366.842C173.334 375.951 168.323 384.868 163.44 393.851C158.914 402.176 152.452 408.329 147.459 416.191C140.045 427.866 138.542 441.888 135.908 455.986C132.548 473.977 125.684 484.457 115.779 497.146C107.208 508.127 102.148 524.097 96.7706 537.875C88.3437 559.466 77.9632 579.435 68.0621 599.938C58.3465 620.057 52.4475 640.986 46.6428 663.294C44.2003 672.682 41.9726 681.927 39.8021 691.381C38.7664 695.892 38.9635 700.506 37.7835 704.957C36.2404 710.778 32.7371 716.271 32.7371 722.484" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M499.027 7.46492C479.273 14.5708 460.261 23.606 440.6 30.8822C432.572 33.8534 425.054 38.3973 416.938 41.0825C409.767 43.4551 402.563 46.0464 395.631 49.415C363.785 64.8901 333.299 84.9629 301.431 100.272C280.281 110.433 258.091 112.767 239.08 129.867C212.513 153.764 211.599 192.558 199.269 226.985C194.325 240.789 186.52 253.275 180.653 266.492C172.284 285.348 165.937 306.273 159.683 326.401C151.895 351.462 144.284 374.846 139.665 401.178C136.083 421.6 126.54 436.38 116.62 452.538C101.421 477.294 84.0399 503.015 74.0057 532.057C65.9656 555.327 53.4714 575.652 42.6057 596.778C37.5217 606.662 32.9199 616.766 29.8214 627.953C26.9951 638.157 27.0671 648.543 25.5039 659.056C22.5382 679.003 13.5607 696.713 13.5607 717.312" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M506.092 11.3438C484.426 25.7267 459.916 33.6105 436.395 41.3698C424.408 45.3242 412.674 48.0943 400.453 50.2051C392.943 51.5024 385.105 52.1654 377.8 54.8742C368.305 58.3956 359.226 63.746 349.765 67.5168C332.163 74.5321 315.67 81.7993 299.02 91.9398C285.898 99.9314 271.344 103.718 258.705 113.346C245.413 123.471 237.417 147.439 229.772 163.341C216.653 190.628 206.492 219.703 193.662 247.098C186.047 263.357 181.78 281.178 176 298.458C168.792 320.001 159.694 341.155 153.907 363.394C147.657 387.416 145.168 412.517 139.497 436.735C134.211 459.308 124.107 487.092 108.434 500.594C98.4859 509.163 88.8775 513.998 77.2578 517.187C62.4394 521.255 51.6964 538.983 44.6243 554.971C33.6538 579.772 24.3897 609.867 19.2239 637.507C16.1774 653.807 14.57 668.566 14.57 685.275C14.57 692.568 13.7688 699.485 13.5607 706.681C13.4607 710.139 11.5421 712.373 11.5421 716.019" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M324 106C311.391 106 298.077 101.949 294.111 116.111C292.554 121.674 291.68 130.789 293.667 136.389C296.217 143.577 306.857 144.584 313.111 144.889C320.949 145.271 330.12 146.914 335.389 139.889C339.182 134.832 339.312 128.507 338.944 122.444C338.134 109.068 326.379 110 316 110C308.804 110 306.243 110.262 306 119C305.868 123.755 305.741 128.667 306.778 133.333C307.827 138.054 313.766 137.931 317.667 138.556C325.056 139.738 327.397 136.261 328.222 129.111C328.759 124.463 331.25 118.866 328.222 114.667C324.739 109.835 297.552 107.386 294.444 114.222C292.909 117.601 294 123.295 294 126.889C294 135.651 300.285 139.698 308.389 140.944C320.375 142.789 327.544 144.575 328.889 129.111C329.216 125.353 330.486 117.297 327.222 114.556C321.939 110.118 311.645 112 305.278 112C299.393 112 296.921 119.237 296.167 124.389C295.124 131.513 298.122 136.837 304.889 139.056C313.403 141.847 322.126 143.848 331.111 144C336.486 144.091 337.529 138.591 338.444 134.111C340.247 125.286 341.253 116.93 331.222 113.444C322.057 110.26 312.374 108 302.667 108C293.792 108 290.51 121.733 293.444 129C295.184 133.307 306.008 136.504 310.222 137C312.089 137.22 314.118 137 316 137" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M470 31C444.503 39.9988 421.072 51.5164 399.111 66.8889C392.575 71.4641 386.488 75.4155 380.444 80.7778C368.105 91.7268 359.619 106.381 348 118C340.508 125.492 332.059 131.426 323.889 138.111C318.465 142.549 311.381 147.44 307.778 153.444C299.763 166.802 292.322 180.077 280.556 190.667C261.129 208.15 233.779 220.496 223.833 245.944C218.624 259.273 213.461 272.745 207.056 285.556C201.177 297.313 192.973 306.638 185 316.889C174.479 330.415 172.334 347.133 165.278 362.278C155.869 382.473 146.036 403.054 140.222 424.667C136.481 438.573 132.816 451.123 125.278 463.611C116.62 477.954 106.227 490.129 95 502.444C85.016 513.397 74.2973 523.266 63.6667 533.556C54.5771 542.354 48.4073 553.408 40.1111 562.889C31.3228 572.933 23.6649 582.563 20.5556 595.444C19.7865 598.631 20.0497 601.795 19 604.944C17.5815 609.2 15.4489 613.425 14.4444 617.778C13.6966 621.019 14.363 623.982 12.9444 627.056C11.8653 629.394 10.6216 631.514 10 634" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M481 28C436.781 36.6638 395.928 46.6341 355.778 67C343.09 73.436 329.808 76.7395 318.222 85.4444C307.949 93.1637 300.507 101.429 294.111 112.444C286.977 124.73 278.471 137.43 268.889 147.889C255.99 161.969 244.876 174.844 237.556 192.889C224.088 226.085 217.769 261.647 206.444 295.556C202.472 307.449 197.889 319.077 191.111 329.667C186.273 337.226 180.829 344.348 176.667 352.333C164.33 376 171.876 405.387 162.556 430.333C158.66 440.761 152.313 450.083 145.333 458.667C131.245 475.993 118.58 494.306 105.556 512.444C87.9355 536.983 69.2621 562.971 57.3333 590.889C52.7077 601.715 47.0726 613.202 46 625" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M487 28C457.917 32.9558 431.299 43.5307 403.333 52.5556C387.891 57.5389 373.699 65.7821 358.111 70.2222C330.7 78.0301 301.758 84.5831 278.722 102.5C247.924 126.455 230.833 160.659 208.556 191.889C200.938 202.568 188.862 215.41 186.167 228.889C185.385 232.796 186.014 237.476 185.944 241.389C185.702 255.097 184.611 268.7 183.222 282.333C181.525 298.99 180.405 316.585 174.667 332.444C167.612 351.945 158.761 370.855 150.556 389.889C141.666 410.51 136.243 432.863 126.111 452.889C112.519 479.755 91.3706 503.474 70.5 524.833C56.6432 539.015 43.2684 553.1 30.1111 567.944C23.7912 575.075 15.1744 583.648 12.5 593.111C10.0057 601.937 8.33552 610.991 6.11111 619.889C4.97513 624.433 6.03755 629.925 4 634" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M501 28C486.921 31.0605 475.026 37.3892 462.722 44.6111C447.087 53.7881 430.195 58.3931 415.889 70.1111C396.073 86.3426 379.873 104.351 356.444 115.556C331.697 127.392 316.219 149.744 296.611 167.889C276.566 186.438 253.915 205.822 243.667 231.889C237.774 246.876 239.482 262.96 229.778 276.444C223.034 285.816 215.969 293.773 208.611 302.556C203.138 309.088 198.671 316.765 193.778 323.722C186.12 334.611 176.075 345.026 170.222 357.056C165.312 367.148 165.482 379.262 163.222 390.111C160.812 401.681 158.991 411.657 154.222 422.556C151.26 429.326 147.244 435.512 143.944 442.111C139.003 451.994 133.154 463.006 123.278 468.667C112.524 474.83 100.528 478.055 89.3333 483.222C83.2855 486.014 75.6554 488.547 71.5556 494.111C65.9538 501.713 60.7822 510.841 56.6667 519.333C52.4638 528.006 47.3083 533.475 41.2222 540.778C31.7536 552.14 21.6642 562.06 14.3333 575.056C9.41909 583.767 9 594.25 9 603.944C9 610.574 9.1049 616.426 11.2222 622.778C12.4919 626.587 13.4628 634.463 16 637" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
  </Stuvg>

);

export default Peppestuffoni;
