import React from 'react';
import Box from '@material-ui/core/Box';
import text from './text.svg';
import text_date_smotri from './text_date_smotri.svg';
import lines from './lines.svg';
import bubbles from './bubbles.svg';
import img_karina from './img_karina.png';
import img_building from './img_building.png';
import imgs_1 from './imgs_1.png';
import imgs_2 from './imgs_2.png';
import img_0 from './img_0.png';

const Section11n12 = () => {
  return (
    <Box position="relative" marginTop="3383px">
      <Box className="rlx" position="absolute" left={250}>
        <img src={text_date_smotri} />
      </Box>
      <Box position="absolute" left={-13} top={720}>
        <img src={lines} />
      </Box>

      <Box className="rlx" position="absolute" left={-97} top={125}>
        <img src={imgs_1} width={226} />
      </Box>
      <Box className="rlx" data-rellax-speed={0.5} position="absolute" left={-10} top={0}>
        <img src={imgs_2} width={280} />
      </Box>
      <Box className="rlx" data-rellax-speed={0.3} position="absolute" left={-93} top={270}>
        <img src={img_building} width={316} />
      </Box>
      <Box position="absolute" left={150} top={380}>
        <img src={img_0} width={110} />
      </Box>
      <Box className="rlx" position="absolute" left={51} top={400}>
        <img src={img_karina} width={148} />
      </Box>

      <Box className="rlx" data-rellax-speed={0.5} position="absolute" left={20} top={50}>
        <img src={bubbles} />
      </Box>
      <Box position="absolute" left={148}>
        <img src={text} />
      </Box>
    </Box>
  );
};

export default Section11n12;
