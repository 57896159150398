import React, { useState, useRef } from 'react';
import Zoom from '@material-ui/core/Zoom';
import Box from '@material-ui/core/Box';
import Moveable from "react-moveable";
import ExplorerIcon from '../../../../Win98/ExplorerIcon';
import FolderWindow from '../../../../Win98/FolderWindow';
import interviews from './interviews';

const InterviewsFolder = () => {
  const [open, setOpen] = useState(false);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const wbRef = useRef();
  const translate = useRef([0, 0]);

  return (
    <>
      <ExplorerIcon
        icon="/images/win98_folder_open.png"
        name="Interviews"
        onDoubleClick={onOpen}
      />
      <Box
        id="movable-folder-interviews"
        ref={wbRef}
        position="fixed"
        top={16}
        left={16}
        zIndex={open ? 1 : -1}
      >
        <Zoom in={open}>
          <FolderWindow
            title="Interviews"
            onClose={onClose}
            icon="/images/win98_folder_open.png"
            width={640}
            maxWidth="90vw"
            height={480}
            maxHeight="80vh"
          >
            {open && interviews}
          </FolderWindow>
        </Zoom>
      </Box>
      {open && (
        <Moveable
          target={document.querySelector('#movable-folder-interviews .Window__heading')}
          origin={false}
          edge={false}

          draggable
          throttleDrag={0}
          onDragStart={({ set }) => {
            set(translate.current);
          }}
          onDrag={({ beforeTranslate }) => {
            translate.current = beforeTranslate;
            wbRef.current.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px)`;
          }}
        />
      )}
    </>
  );
};

export default InterviewsFolder;
