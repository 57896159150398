import React, { useState } from 'react';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Typist from 'react-typist';
import 'react-typist/dist/Typist.css';
import { useHistory } from 'react-router-dom';
import Monospace from '../../Monospace';

const Text = styled(Monospace)`
  text-align: left;
  font-size: 16px;
`;

const HiddenText = styled(Text)`
  opacity: 0;
`;

const TypistText = styled(Text)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

const cursor = {
  show: true,
  blink: true,
  element: '|',
  hideWhenDone: true,
  hideWhenDoneDelay: 300,
};

const outTimeout = 1000;

const Page2 = () => {
  const [out, setOut] = useState(false);
  const history = useHistory();
  const onOut = () => {
    setOut(true);
    setTimeout(() => {
      history.push('/read/3');
    }, outTimeout);
  }

  const [dTitle, setDTitle] = useState(false);
  const onDTitle = () => setDTitle(true);
  const renderTitle = () => (
    <Box position="relative">
      <HiddenText>
        "Your work reminds me of... Do you know about Frank Stella?"|
      </HiddenText>
      <TypistText>
        <Typist onTypingDone={onDTitle} cursor={cursor}>
          "Your work reminds me of... Do you know about Frank Stella?"
        </Typist>
      </TypistText>
    </Box>
  );

  const [dAuthor, setDAuthor] = useState(false);
  const onDAuthor = () => setDAuthor(true);
  const renderAuthor = () => {
    const T = dTitle ? Typist : 'div';
    const props = dTitle ? { onTypingDone: onDAuthor, cursor: cursor } : undefined;
    return (
      <Box position="relative" marginBottom={6}>
        <HiddenText>
          Karina Puuffin|
        </HiddenText>
        <TypistText hidden={!dTitle}>
          <T {...props}>
            Karina Puuffin
          </T>
        </TypistText>
      </Box>
    );
  };

  const [dPurpose, setDPurpose] = useState(false);
  const onDPurpose = () => setDPurpose(true);
  const renderPurpose = () => {
    const T = dAuthor ? Typist : 'div';
    const props = dAuthor ? { onTypingDone: onDPurpose, cursor: cursor } : undefined;
    return (
      <Box position="relative">
        <HiddenText>
          A thesis submitted for the|
        </HiddenText>
        <TypistText hidden={!dAuthor}>
          <T {...props}>
            A thesis submitted for the
          </T>
        </TypistText>
      </Box>
    );
  };

  const [dPurpose1, setDPurpose1] = useState(false);
  const onDPurpose1 = () => setDPurpose1(true);
  const renderPurpose1 = () => {
    const T = dPurpose ? Typist : 'div';
    const props = dPurpose ? { onTypingDone: onDPurpose1, cursor: cursor } : undefined;
    return (
      <Box position="relative" marginBottom={4}>
        <HiddenText>
          degree of Bachelor of Arts|
        </HiddenText>
        <TypistText hidden={!dPurpose}>
          <T {...props}>
            degree of Bachelor of Arts
          </T>
        </TypistText>
      </Box>
    );
  };

  const [dFooter, setDFooter] = useState(false);
  const onDFooter = () => setDFooter(true);
  const renderFooter = () => {
    const T = dPurpose1 ? Typist : 'div';
    const props = dPurpose1 ? { onTypingDone: onDFooter, cursor: cursor } : undefined;
    return (
      <Box position="relative">
        <HiddenText>
          Mirerva Art Academy|
        </HiddenText>
        <TypistText hidden={!dPurpose1}>
          <T {...props}>
            Minerva Art Academy
          </T>
        </TypistText>
      </Box>
    );
  };

  const [dFooter2, setDFooter2] = useState(false);
  const onDFooter2 = () => setDFooter2(true);
  const renderFooter1 = () => {
    const T = dFooter ? Typist : 'div';
    const props = dFooter ? { onTypingDone: onDFooter2, cursor: cursor } : undefined;
    return (
      <Box position="relative">
        <HiddenText>
          2020|
        </HiddenText>
        <TypistText hidden={!dFooter}>
          <T {...props}>
            2020
          </T>
        </TypistText>
      </Box>
    );
  };

  return (
    <Fade in={!out} timeout={out ? outTimeout : 0}>
      <Box
        height="100%"
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        padding={4}
        style={{ cursor: dFooter2 ? 'pointer' :'text' }}
        onClick={dFooter2 ? onOut : undefined}
      >
        <Box height="50%" display="flex" flexDirection="column" justifyContent="flex-end" alignItems="center">
          {renderTitle()}
        </Box>
        <Box height="50%" display="flex" flexDirection="column" justifyContent="flex-end" alignItems="center">
          {renderAuthor()}
          {renderPurpose()}
          {renderPurpose1()}
          {renderFooter()}
          {renderFooter1()}
        </Box>
      </Box>
    </Fade>
  );
};

export default Page2;
