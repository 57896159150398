import React from 'react';
import { createGlobalStyle } from 'styled-components';
import CssBaseline from '@material-ui/core/CssBaseline';

const LayoutCss = createGlobalStyle`
  @import url("https://fonts.googleapis.com/css?family=VT323&display=swap");

  body,
  html,
  #root {
    height: 100%;
  }

  #root {
    display: flex;
    flex-direction: column;
  }

  /* stupid!!1 packard belle overrides */
  html {
    font-size: 16px !important;
  }
  body {
    font-family: ${props => props.theme.typography.fontFamily} !important;
  }
`;

export const GlobalStyle = () => [
  <CssBaseline key="CssBaseline" />,
  <LayoutCss key="LayoutCss" />
];
