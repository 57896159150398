import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Zoom from '@material-ui/core/Zoom';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Monospace from '../../../../Monospace';
import Next from './Next';

const Wrapper = styled.div`
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Stage = styled.div`
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: ${props => props.theme.spacing(4)}px;
`;

const Outline = styled.svg`
  position: absolute;
  transform: scale(${props => props.scale});
`;

const NextWrapper = styled.div`
  position: absolute;
  left: 100%;
  margin-left: 100px;
  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale3d(1.2, 1.2, 1);
  }
`;

const Stages = () => {
  const [isIn, setIsIn] = useState(false);
  const onIsIn = () => setIsIn(true);

  const [isNextIn, setIsNextIn] = useState(false);
  const onIsNextIn = () => setIsNextIn(true);

  const [i, setI] = useState(0);
  const onI = () => setI(prevI => prevI + 1);

  const [isOut, setIsOut] = useState(false);
  const onOut = () => setIsOut(true);
  const history = useHistory();
  const onOuted = () => history.push('/read/pinky/parsons');

  return (
    <Fade in={!isOut} timeout={1000} onEntered={onIsIn} onExited={onOuted}>
      <Wrapper>
        <Box maxWidth={600} marginTop={6}>
          <Monospace>
            But how do we perceive an artwork?
          </Monospace>
          <Monospace>
            In order to understand what kind of factors influence our visual perception Michael Parsons in his book “How We Understand Art: A Cognitive Developmental Account of Aesthetic Experience” has discerned five stages of understanding art based on over 300 interviews with children and adults.
          </Monospace>
        </Box>
        <Box marginY={4} display="flex" alignItems="center" justifyContent="space-evenly" maxWidth={1200}>
          <Zoom in={isIn} onEntered={onI} timeout={500}>
            <Stage>
              <Outline scale={1.33} width="400" height="139" viewBox="0 0 400 139" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M365 27C353.01 21.7189 341.315 16.8805 328.222 15.3889C304.25 12.6578 280.011 11.4899 256.111 8.33332C224.459 4.15279 192.813 -0.630416 160.722 1.99999C136.045 4.0227 111.098 2.99999 86.3333 2.99999C63.0734 2.99999 39.7876 1.98523 17 7.55554C12.1681 8.73667 5.85946 13.4291 2.88889 17.3333C0.214492 20.8482 1.98469 31.7075 2.22222 35.5555C3.00947 48.309 7 60.6412 7 73.4444C7 80.4587 5.63616 88.9022 8.05555 95.5555C10.5294 102.359 12.7493 112.341 18.4444 117.222C24.5705 122.473 32.7779 125.929 40.7778 126C65.6308 126.22 90.4909 127.346 115.333 127.944C146.426 128.694 176.144 138 207.5 138C230.551 138 253.535 138.125 276.556 136.778C286.952 136.169 298.199 132.972 308.333 130.389C318.275 127.855 328.862 125.076 338.333 121.111C347.817 117.141 357.913 115.463 367.444 111.889C383.305 105.941 394.427 92.7979 397.333 76.1111C398.617 68.7437 400.247 58.2475 394.5 52.5C393.014 51.0141 389.813 49.7069 387.778 49.2222C383.929 48.3058 383.219 46.0859 380 44.2222C373.856 40.6652 368.147 34.2938 365 28" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              </Outline>
              <Box width={425} marginLeft={-2} marginTop={-1}>
                <Monospace>
                  <b>
                    Stage 1
                  </b>
                </Monospace>
                <Monospace>
                  This is the level on which most of the preschool children are judging the works of art. Their interest is based on the subjective associations they get from looking at the paintings. “I like this, because we have a similar plant in our classroom” or “… because we have same wallpapers in our house”.
                </Monospace>
              </Box>
            </Stage>
          </Zoom>
          <Zoom in={i > 0} onEntered={onI} timeout={500}>
            <Stage>
              <Outline scale={1.38} width="349" height="158" viewBox="0 0 349 158" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M129.877 4.6057C152.162 -0.330206 175.957 1.48532 198.593 1C205.055 1 210.904 5.97759 217.15 7.33906C221.091 8.19822 227.862 11.5883 230.609 14.6668C235.733 20.4116 244.928 28.0747 252.901 29.4967C267.88 32.1682 283.224 33.4317 298.079 36.5918C309.168 38.9506 319.372 40.9111 328.553 47.8742C333.485 51.6147 338.945 54.4965 341.538 60.3778C346.845 72.418 348 88.6318 348 101.553C348 113.016 344.528 117.386 333.711 123.071C321.999 129.225 310.884 136.063 299.146 142.088C289.709 146.931 280.771 148.983 270.634 151.31L270.273 151.393C257.862 154.242 247.096 156.889 234.166 156.976C212.37 157.123 191.885 156.734 170.608 151.974C151.845 147.777 131.135 147.611 111.972 146.101C92.702 144.582 74.2378 144.026 55.2328 140.169C51.8955 139.491 50.5954 136.615 48.1182 134.644C46.0848 133.025 43.46 132.512 41.1814 131.329C37.1824 129.252 32.4334 129.161 28.4344 127.2C18.0936 122.128 6.00328 115.513 1.87308 104.17C-0.673718 97.1748 3.05599 87.7565 4.12605 80.7908C5.47601 72.003 8.12547 63.4789 10.2921 54.8529C11.9876 48.1023 14.6069 41.5756 15.8652 34.7308C17.037 28.3568 26.5738 24.7681 31.8731 22.169C40.7896 17.7958 53.4129 15.6793 63.2368 14.6086C75.9629 13.2216 89.1401 12.49 101.715 10.1887C111.101 8.47101 120.566 6.66815 129.877 4.6057Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              </Outline>
              <Box width={400}>
                <Monospace>
                  <b>
                    Stage 2
                  </b>
                </Monospace>
                <Monospace>
                  Most of the elementary school children reason at stage 2. The importance of a subject matter is more in front here, and paintings are called to be good if they are realistic and beautiful. The judgements are not anymore based on subjective associations but on objective values.
                </Monospace>
              </Box>
            </Stage>
          </Zoom>
        </Box>
        <Fade in={i > 1} onEntered={onIsNextIn} timeout={2000}>
          <Box maxWidth={680} position="relative" display="flex" alignItems="center">
            <Monospace>
              The next stages are more complicated because they depend on individual’s art knowledge and experience. It is also about how far the person wants to think about an artwork and dive into its further investigation.
            </Monospace>
            <Fade in={i > 4} timeout={1000}>
              <NextWrapper onClick={onOut}>
                <Next />
              </NextWrapper>
            </Fade>
          </Box>
        </Fade>
        <Box marginTop={6} marginBottom={8} display="flex" alignItems="center" justifyContent="space-evenly" maxWidth={1200}>
          <Zoom in={isNextIn} onEntered={onI} timeout={500}>
            <Stage>
              <Outline scale={1.42} width="254" height="222" viewBox="0 0 254 222" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M155 4C129.422 4 104.024 1 78.5555 1C61.9028 1 44.6876 3.2375 29.8333 11.0556C15.0323 18.8455 9.37783 33.8271 7.88888 49.8333C6.61015 63.5796 2.17265 76.7902 1.22221 90.6667C0.196825 105.637 2.53508 120.774 6.77776 135.111C8.59181 141.241 11.9596 146.791 13.4444 153C15.406 161.203 15.7759 169.604 17.7778 177.778C19.4829 184.74 18.6493 191.286 23.6111 197C29.7581 204.078 38.4209 205.25 46.2222 209.889C59.0548 217.519 76.5668 217.706 91 218.778C108.95 220.111 128.187 221.524 146.222 219.833C158.814 218.653 172.708 215.612 184.889 212.222C198.931 208.315 212.65 202.858 223.667 193.333C232.339 185.835 237.634 178.024 241.167 167.278C242.994 161.721 245.984 156.618 247.722 151.056C249.373 145.773 248.591 140.393 249.222 135C249.695 130.956 251.574 127.312 252.333 123.333C253.364 117.93 253.188 112.092 252.944 106.611C252.606 98.998 252 91.5465 252 83.8889C252 72.2877 252.731 60.1008 250.444 48.6667C249.201 42.4478 243.838 38.7344 239 35.4444C237.042 34.1132 233.205 34.2372 231 33.7778C226.124 32.7619 221.144 31.8506 216.333 30.5556C213.22 29.7172 210.314 28.1817 207.222 27.2222C200.769 25.2196 194.883 20.974 189.833 16.5556C184.947 12.2804 177.187 10.5535 171.167 8.83333C166.889 7.61128 163.266 6 158.778 6C156.55 6 154.051 6.10234 153 4" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              </Outline>
              <Box width={300}>
                <Monospace>
                  <b>
                    Stage 3
                  </b>
                </Monospace>
                <Monospace>
                  The dominant idea of this stage is expressiveness. Beauty and realism do not matter anymore, intensity and interestingness of the experience they reproduce in the respondent are becoming the main criteria here. “I like it, because it moves me, makes me think of my mother and my childhood” etc. Moreover, the viewer is aware of the subjectivity of aesthetic experience.
                </Monospace>
              </Box>
            </Stage>
          </Zoom>
          <Zoom in={i > 2} onEntered={onI} timeout={500}>
            <Stage>
              <Outline scale={1.44} width="252" height="230" viewBox="0 0 252 230" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M69.6667 3C84.1373 -0.113922 100.437 2 115 2C120.672 2 127.783 1.00529 132 5C137.219 9.94477 144.83 9 151.722 9C165.214 9 180.157 10.5071 192.778 15.5556C200.454 18.6259 207.956 24.5501 214.667 29.2778C219.763 32.8686 225.611 35.1474 229.278 40.4444C234.231 47.5995 237.77 55.4084 239.556 63.8889C240.906 70.3021 245.577 75.7566 248.222 81.7778C252.819 92.2401 250.681 103.551 248 114.389C245.589 124.132 245 132.864 245 142.833C245 160.038 249.693 186.658 238 200.778C231.137 209.066 218.623 210.82 209.778 216.389C205.085 219.344 200.13 220.901 195 223C165.474 235.079 130.845 226 100 226C84.925 226 70.4289 226.329 55.5555 223.556C45.4348 221.669 36.1385 219.646 26.4444 216C17.5286 212.646 13.8316 205.31 9.66666 197.333C3.8369 186.168 3.04473 170.011 1.55555 157.556C0.173728 145.998 0.314347 134.852 2.05555 123.389C3.95463 110.887 6.85809 98.7392 7.99999 86.1111C8.87936 76.3863 7.49956 65.5299 9.44444 56C11.3298 46.7619 17.0173 36.5787 22.5555 29C28.1695 21.3178 38.8263 16.8687 47 12.5L47.0692 12.463C54.4027 8.54338 61.4767 4.7624 69.6667 3Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              </Outline>
              <Box width={300}>
                <Monospace>
                  <b>
                    Stage 4
                  </b>
                </Monospace>
                <Monospace>
                  The formal aspects of the work like medium, colour, form and texture are playing an important role. At this stage people are aware of artistic tradition and art history and can relate it to the medium of the work or (and) to the tools that were used. Interpretations are now flexible and can be changed from the talks with the others. A personal connection to the subject is not dominant.
                </Monospace>
              </Box>
            </Stage>
          </Zoom>
          <Zoom in={i > 3} onEntered={onI} timeout={500}>
            <Stage>
              <Outline scale={1.5} width="232" height="161" viewBox="0 0 232 161" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M119 2C96.5459 1.67692 74.0146 -1.12088 51.7778 2.77778C39.9311 4.8548 31.2309 6.29975 21.8889 14.1667C12.797 21.823 10.2059 26.2973 8.44445 37.6667C7.01334 46.9038 5.47585 55.248 4.77778 64.5556C3.65622 79.5097 -1.49079 93.9349 1.55556 109.167C3.68157 119.797 10.3743 127.658 19.1111 133.444C31.366 141.561 44.7212 147.073 59.1667 149.556C65.4577 150.637 71.4643 152.376 77.6111 154C82.7298 155.352 86.6455 157.981 91.5556 159.556C96.3407 161.09 105.041 155.908 107 151.5C108.037 149.167 111.273 150 113.333 150C132.357 150 151.089 158.758 170.111 156.111C188.437 153.561 205.543 146.633 212.944 129.056C214.397 125.606 214.836 122.137 215.611 118.5C216.764 113.09 219.544 109.036 222.5 104.444C228.235 95.5366 231 85.7754 231 75.1111C231 69.628 227.782 67.7225 226 63C224.553 59.1663 222.888 54.5625 220.944 51C215.863 41.6843 214.234 28.8467 202.556 25.2222C195.46 23.0202 188.237 20.8275 180.889 19.7778C174.084 18.8056 167.272 19.0934 160.389 18.5C152.111 17.7864 146.533 14.6764 139.778 10C138.17 8.88697 136.912 8.00158 135.222 7C133.029 5.70012 130.599 6.66619 128.5 5.5C125.193 3.66293 121.838 1 118 1" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              </Outline>
              <Box width={300}>
                <Monospace>
                  <b>
                    Stage 5
                  </b>
                </Monospace>
                <Monospace>
                  This is the highest stage that Parsons identifies as ‘autonomous’. Instead of trying to put a work into a certain artistic tradition, viewers now raise questions doubting established views.
                </Monospace>
              </Box>
            </Stage>
          </Zoom>
        </Box>
      </Wrapper>
    </Fade>
  );
};

export default Stages;
