import React, { useContext, useEffect, useState, useCallback } from 'react';
import styled, { ThemeContext } from 'styled-components';
import Zoom from '@material-ui/core/Zoom';
import Grow from '@material-ui/core/Grow';
import Box from '@material-ui/core/Box';
import { PotatoPageContext } from '../../../../PotatoPageContext';
import Cube from './Cube';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: ${props => props.theme.spacing(4)}px;
`;

const Threed = styled.div`
  transition: ${props => props.theme.transitions.create(['transform'])};
  transform: skew(-12deg) rotate(-12deg);
`;

const Txt = styled.div`
  display: flex;
  font-size: 64px;
  text-transform: uppercase;
  font-family: "VT323", sans-serif;
  color: hsl(57.6,100%,50%);
  letter-spacing: 0.05em;
  text-shadow:
    ${Array(4).fill().map((_, i, arr) => `-4px -${i + 1}px hsl(57.6, 100%, ${50 - (100 / arr.length * (i + 1))}%)`).join(',')},
    ${Array(2).fill().map((_, i) => `-${i + 1}px -2px black`).join(',')};
  cursor: pointer;
  transition: ${props => props.theme.transitions.create(['transform', 'text-shadow'])};
  transform-origin: right;

  &:hover {
    transform: scale(1.1);
    text-shadow: none;

    ${Threed} {
      transform: skew(0deg) rotate(0deg);
    }
  }
`;

const Pitaya1 = () => {
  const pageCtx = useContext(PotatoPageContext);
  const theme = useContext(ThemeContext);
  useEffect(() => pageCtx.setBgColor(theme.palette.pitaya), []);

  const [enter, setEnter] = useState(false);
  const [cubeSize, setCubeSize] = useState({ w: 490, h: 400 });
  const onEnter = useCallback(() => {
    setEnter(true);
    setCubeSize({ h: window.innerHeight * 2 });
  }, []);

  return (
    <Zoom in timeout={1000}>
      <Wrapper>
        <Cube width={cubeSize.w} height={cubeSize.h} hideObjects={enter} />
        <Grow in={!enter} timeout={500}>
          <Box marginLeft="auto" marginTop={2} marginRight={-8}>
            <Txt onClick={onEnter}>
              {'enter the space'.split('').map((l, i) => (
                <Threed key={i}>
                  {l === ' ' ? <>&nbsp;</> : l}
                </Threed>
              ))}
            </Txt>
          </Box>
        </Grow>
      </Wrapper>
    </Zoom>
  );
};

export default Pitaya1;
