import React, { useState, useEffect, useContext } from 'react';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { PulsatingPitaya, PulsatingPepper, PulsatingBlueberry, ButtonWrapper } from './Buttons.styled';
import { PotatoPageContext } from '../../../../PotatoPageContext';

const devourDuration = 2000;

const Buttons = () => {
  const pageCtx = useContext(PotatoPageContext);
  const theme = useContext(ThemeContext);

  const [devour, setDevour] = useState();
  const onDevour = v => {
    pageCtx.setBgColor(theme.palette[v]);
    setDevour(v);
  }

  const history = useHistory();

  useEffect(() => {
    if (!devour) {
      return;
    }
    const timeout = setTimeout(() => {
      history.push(`${history.location.pathname}/${devour}`);
    }, devourDuration / 2);
    return () => clearTimeout(timeout);
  }, [devour, history]);

  return (
    <Box display="flex" alignItems="flex-end" marginBottom="60px">
      <ButtonWrapper
        marginLeft="200px"
        onClick={() => onDevour('pitaya')}
        transitionDuration={devourDuration}
        data-devoured={devour === 'pitaya'}
      >
        <PulsatingPitaya />
      </ButtonWrapper>
      <ButtonWrapper
        marginLeft="380px"
        marginBottom="50px"
        onClick={() => onDevour('blueberry')}
        transitionDuration={devourDuration}
        data-devoured={devour === 'blueberry'}
      >
        <PulsatingBlueberry />
      </ButtonWrapper>
      <ButtonWrapper
        marginBottom="-100px"
        marginLeft="-40px"
        onClick={() => onDevour('pepper')}
        transitionDuration={devourDuration}
        data-devoured={devour === 'pepper'}
      >
        <PulsatingPepper />
      </ButtonWrapper>
    </Box>
  );
};

export default Buttons;
