import React from 'react';
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';
import Buttons from './Buttons';
import Monospace from '../../../../../../Monospace';

const Home = () => {
  return (
    <Slide direction="left" in timeout={1000}>
      <Box margin="auto">
        <Buttons />
        <Box maxWidth={700}>
          <Monospace>
            I wanted to get an overview of what people think about art in Ukraine. I asked for volunteers on social media who could help me with my research by participating in interviews about art, their opinion about it and the current art environment in the country. I also included two people from Russia to see if the situation there is any different and if the novelty associated with contemporary art is somehow connected to the shared history of these two countries.
          </Monospace>
          <br />
          <Monospace>
            To my surprise, people I interviewed showed an open interest to art. Moreover, most of them were criticizing established attitude towards modern (contemporary) art in their society. Of course, the reason behind it might be the age of the participants (19 – 21). However, it was nice to see that younger generation in Ukraine and Russia is slowly changing the general mind-set towards art that was determined by their ancestors many years ago.
          </Monospace>
        </Box>
      </Box>
    </Slide>
  );
};

export default Home;
