import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Zoom from '@material-ui/core/Zoom';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useHistory } from 'react-router-dom';
import CD from '../../../../Win98/CD';
import InterviewsFolder from './InterviewsFolder';

const Desktop = () => {
  const [slide, setSlide] = useState(false);
  const onSlide = () => {
    setSlide(true);
  };

  const history = useHistory();
  const onExited = () => {
    history.push('/read/potato/art-in-ukraine')
  };

  return (
    <>
      <CD />
      <Slide in={!slide} direction="right" timeout={{ enter: 0, exit: 1000 }} onExited={onExited}>
        <Box
          margin="auto"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Fade in timeout={1000}>
            <div>
              <InterviewsFolder />
            </div>
          </Fade>

          <Fade in={!slide} timeout={{ enter: 5000, exit: 0 }}>
            <Box position="fixed" margin={4} bottom={0} right={0}>
              <Zoom in timeout={2000}>
                <Button endIcon={<ChevronRightIcon />} onClick={onSlide}>
                  Next
                </Button>
              </Zoom>
            </Box>
          </Fade>
        </Box>
      </Slide>
    </>
  );
};

export default Desktop;
