import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Fade from '@material-ui/core/Fade';
import Grow from '@material-ui/core/Grow';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router-dom';
import pages from './pages';
import Matex from '../../../../Matex';

const GlobalStyle = createGlobalStyle`
  body {
    transition: ${props => props.theme.transitions.create(['background-color'], { duration: 1000 })};
    background-color: ${props => props.bgColor || 'transparent'} !important;
  }
`;

const Video = styled.video`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
`;

const PageNum = styled.div`
  font-family: 'VT323', sans-serif;
  font-size: 64px;
  font-weight: bold;
  color: #FF21A6;
  cursor: pointer;
  transition: ${props => props.theme.transitions.create(['opacity'])};

  &:hover, &[data-active="true"] {
    opacity: 0.7;
  }
`;

const Next = styled.div`
  background-color: #FF9AE9;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-top: ${props => props.theme.spacing(6)}px;
  margin-left: ${props => props.theme.spacing(4)}px;
  cursor: pointer;
  transition: ${props => props.theme.transitions.create(['opacity'])};

  &:hover {
    opacity: 0.7;
  }
`;

const Welcome = styled.div`
  font-family: 'VT323', sans-serif;
  font-size: 32px;
  font-weight: bold;
  color: #FF21A6;
`;

const str1 = "welcome to the new";
const str2 = "interactive virtual space";

const Space = () => {
  const [bgColor, setBgColor] = useState('#F29CEA');
  useEffect(() => {
    const timeout = setTimeout(() => setBgColor('#000'), 1000);
    return () => clearTimeout(timeout);
  }, []);

  const [ready, setReady] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setReady(true), 2000);
    return () => clearTimeout(timeout);
  }, []);

  const pageWelcome = (
    <Box marginLeft={-8} marginTop={-4}>
      {ready && (
        <Welcome>
          <Matex value={str1} />
          <br />
          <Matex value={str2} wait={str1.length} />
        </Welcome>
      )}
    </Box>
  );

  const [page, setPage] = useState(1);
  const pageSwitch = (
    <Box marginLeft="auto" display="flex" marginRight={-6}>
      <PageNum onClick={() => setPage(1)} data-active={page === 1}>
        1
      </PageNum>
      <Box marginX={2} />
      <PageNum onClick={() => setPage(2)} data-active={page === 2}>
        2
      </PageNum>
    </Box>
  );

  const [ready2, setReady2] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setReady2(true), 4000);
    return () => clearTimeout(timeout);
  })

  const [exit, setExit] = useState(false);
  const onExit = () => setExit(true);
  const history = useHistory();
  const onExited = () => history.push('/read/pinky/after-space');

  return (
    <>
      <GlobalStyle bgColor={bgColor} />
      <Fade in={ready && !exit} timeout={1000}>
        <Box margin="auto">
          <Video autoPlay muted loop>
            <source src="/video/grid.mp4" type="video/mp4" />
          </Video>
          <Box maxWidth={800} height="90vh" maxHeight={720} paddingY={4} display="flex" flexDirection="column" position="relative">
            <Box display="flex">
              {pageWelcome}
              <Fade in={ready2 && !exit} timeout={2000}>
                {pageSwitch}
              </Fade>
            </Box>
            <Grow in={ready2 && !exit} timeout={500}>
              <Box bgcolor="#000" color="#F3BCEE" fontFamily="'VT323', sans-serif" fontSize={20} lineHeight={1} overflow="hidden">
                <Box padding={3} paddingRight={6} width="calc(100% + 24px)" height="100%" overflow="auto">
                  {pages[page]}
                </Box>
              </Box>
            </Grow>
            <Fade in={ready2 && !exit} timeout={2000} onExited={onExited}>
              <Next onClick={onExit} />
            </Fade>
          </Box>
        </Box>
      </Fade>
    </>
  );
};

export default Space;
