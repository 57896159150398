import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import Fade from '@material-ui/core/Fade';
import { createGlobalStyle } from 'styled-components';
import Rellax from 'rellax';
import Magnifier from 'react-magnifier';
import { useHistory } from 'react-router-dom';
import Monospace from '../Monospace';
import Button from '@material-ui/core/Button';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const GlobalStyle = createGlobalStyle`
  body {
    transition: ${props => props.theme.transitions.create(['background-color'], { duration: 1500 })};
    background-color: ${props => props.bgColor || '#E5E5E5'} !important;
  }
`;

export default function RedAndGreen() {
  useEffect(() => {
    new Rellax('.rellax', { center: true, speed: -1 });
  }, []);

  const [exit, setExit] = useState(false);
  const onExit = () => setExit(true);
  const history = useHistory();
  const onExited = () => history.push('/read/teotr');

  return (
    <Grow in={!exit} direction="up" timeout={1000}>
      <Box marginX="auto" marginTop={8}>
        <Fade in={!exit} timeout={1500} onExited={onExited}>
          <Box position="relative">
            <GlobalStyle />
            <Box display="flex">
              <Box width={385} marginX={2}>
                <Magnifier src="/images/square-painting/1.jpg" width={385} alt="" />
              </Box>
              <Box width={385} marginX={2} paddingTop={2}>
                <img src="/images/square-painting/title.png" width={250} alt="" />
                <Box marginTop={1} marginLeft={-2}>
                  <Monospace>
                    2020
                    <br />
                    installation, mixed medium
                  </Monospace>
                </Box>
              </Box>
            </Box>
            <Box position="absolute" top={488} left={418}>
              <video autoPlay muted loop width={102}>
                <source src="/video/sp_1.mp4" type="video/mp4" />
              </video>
            </Box>
            <Box position="absolute" top={708} left={430}>
              <Magnifier src="/images/square-painting/2.jpg" width={365} />
            </Box>
            <Box position="absolute" top={931} left={0}>
              <Magnifier src="/images/square-painting/3.jpg" width={365} />
            </Box>
            <Box position="absolute" top={1167} left={0}>
              <Magnifier src="/images/square-painting/4.jpg" width={365} />
            </Box>
            <Box position="absolute" top={1491} left={69}>
              <Magnifier src="/images/square-painting/5.jpg" width={365} />
            </Box>
            <Box position="absolute" top={1491} left={460}>
              <Magnifier src="/images/square-painting/6.jpg" width={290} />
            </Box>
            <Box position="absolute" top={1681} left={460}>
              <Magnifier src="/images/square-painting/7.jpg" width={290} />
            </Box>
            <Box position="absolute" top={1871} left={460}>
              <Magnifier src="/images/square-painting/8.jpg" width={290} />
            </Box>
            <Box position="absolute" top={2121} left={202}>
              <video autoPlay muted loop width={330}>
                <source src="/video/sp_2.mp4" type="video/mp4" />
              </video>
            </Box>
            <Box position="absolute" top={2375} left={0} display="flex" justifyContent="space-between" marginBottom={8}>
              <Magnifier src="/images/square-painting/9.jpg" height={164} />
              <Box width={64} />
              <Magnifier src="/images/square-painting/10.jpg" height={164} />
              <Box width={64} />
              <Magnifier src="/images/square-painting/11.jpg" height={164} />
            </Box>
            <Box margin={4} position="absolute" top={2550} left="50%" style={{ transform: 'translateX(-50%)' }}>
              <Button onClick={onExit} endIcon={<ChevronRightIcon />}>
                next
              </Button>
            </Box>
          </Box>
        </Fade>
      </Box>
    </Grow>
  );
}
