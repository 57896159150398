import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';

const grey = a => `rgba(128, 128, 128, ${a})`;

const Drawable = styled(Box)`
  transition: ${props => props.theme.transitions.create(['width', 'height', 'background-color'], { duration: 2000 })};
  transition-delay: ${props => props.done ? 0 : 200}ms;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.borderColor || grey(1)};
  background-color: ${props => props.getBgColor ? props.getBgColor(props.done) : grey(props.done ? 1 : 0.2)};
  padding: ${props => props.theme.spacing(props.done ? 3 : 0)}px;
`;

const CursorWrapper = styled(Box)`
  transition: ${props => props.theme.transitions.create(['top', 'left', 'transform'], { duration: 300 })};
`;

const Cursor = props => {
  let os = 'mac';
  if (navigator.appVersion.toLowerCase().includes('win')) {
    os = 'win';
  }
  return (
    <CursorWrapper {...props}>
      <img src={`/images/cursor_${os}.png`} />
    </CursorWrapper>
  )
};

const DrawingBox = ({ children, onDrawn, getBgColor, borderColor, ...restProps }) => {
  const [render, setRender] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setRender(true);
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const ref = useRef();
  const [rect, setRect] = useState();
  useEffect(() => {
    setRect(ref.current.getBoundingClientRect());
  }, [render]);

  const [done, setDone] = useState();
  const onTransitionEnd = event => {
    if (event.target === event.currentTarget) {
      setTimeout(() => {
        setDone(true);
        if (onDrawn) {
          onDrawn();
        }
      }, 500);
    }
  };

  const style = {
    width: 0,
    height: 0,
  };
  if (render) {
    style.width = rect.width;
    style.height = rect.height;
  }

  return (
    <Box ref={ref} visibility={render ? undefined : 'hidden'} {...restProps}>
      <Drawable
        height="100%"
        style={style}
        onTransitionEnd={onTransitionEnd}
        done={done}
        position="relative"
        getBgColor={getBgColor}
        borderColor={borderColor}
      >
        <Fade in={done} timeout={1000}>
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
          >
            {children}
          </Box>
        </Fade>
        <Fade in={render && !done}>
          <span>
            <Cursor position="absolute" top="100%" left="100%" />
          </span>
        </Fade>
      </Drawable>
    </Box>
  );
};

export default DrawingBox;
