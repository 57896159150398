import React, { useState, useRef, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import styled from 'styled-components';

const SimilarLabel = styled.span`
  color: #188038;
  margin-right: 6px;
`;

const SynonymButton = styled(Button).attrs({ variant: 'outlined' })`
  border-radius: 32px !important;
  text-transform: none !important;
  padding: 0 8px !important;
  margin: 3px 6px 3px 0 !important;
  min-width: initial !important;
`;

const ToggleButton = styled(SynonymButton)`
  padding: 0 !important;
`;

const getComputedWidth = node => {
  const cs = getComputedStyle(node);
  return node.offsetWidth + parseInt(cs.marginLeft, 10) + parseInt(cs.marginRight, 10);
};

const renderWord = word => (
  <SynonymButton
    key={word}
    component="a"
    href={`https://www.google.com/search?q=define+${word}`}
    target="_blank"
    rel="noreferrer noopener"
  >
    {word}
  </SynonymButton>
);

const Symonyms = ({ words }) => {
  const boxRef = useRef(null);

  const [childCount, setChildCount] = useState(undefined);

  useEffect(() => {
    const node = boxRef.current;

    const firstRow = Array.from(node.children).reduce((acc, child) => {
      if (acc.width > node.clientWidth) {
        return acc;
      }

      const childWidth = getComputedWidth(child);
      if (acc.width + childWidth < node.clientWidth) {
        acc.width += childWidth;
        acc.count += 1;
      }

      return acc;
    }, { width: 0, count: 0 });

    setChildCount(firstRow.count - 2);
  }, []);

  const [open, setOpen] = useState(false);

  const onClick = () => setOpen(prev => !prev);

  return (
    <Typography component="div">
      <Box ref={boxRef} display="flex" flexWrap="wrap" alignItems="center">
        <SimilarLabel>
          Similar:
        </SimilarLabel>
        {words.slice(0, childCount).map(renderWord)}
        {!open && (
          <ToggleButton onClick={onClick}>
            <ExpandMoreIcon />
          </ToggleButton>
        )}
        <Collapse in={open}>
          {words.slice(childCount, words.length).map(renderWord)}
          <ToggleButton onClick={onClick}>
            <ExpandLessIcon />
          </ToggleButton>
        </Collapse>
      </Box>
    </Typography>
  );
};

export default Symonyms;
