import React, { useContext, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';
import Grow from '@material-ui/core/Grow';
import Button from '@material-ui/core/Button';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Rellax from 'rellax';
import { AvocadoPageContext } from '../../AvocadoPageContext';
import { Wrapper } from './Diary.styled';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4n5 from './Section4n5';
import Section6 from './Section6';
import Section7n8 from './Section7n8';
import Section9 from './Section9';
import Section10 from './Section10';
import Section11n12 from './Section11n12';
import { useHistory } from 'react-router-dom';

const Diary = () => {
  const pageCtx = useContext(AvocadoPageContext);
  useEffect(() => pageCtx.setBgColor('#E5E5E5'), []);

  const [exit, setExit] = useState(false);
  const onExit = () => setExit(true);
  const history = useHistory();
  const onExited = () => history.push('/read/avocado/feldman');

  useEffect(() => {
    new Rellax('.rlx', { center: true, speed: -0.5 });
  }, []);

  return (
    <Slide in timeout={500} direction="up">
      <Box marginX="auto">
        <Grow in timeout={1000}>
          <div>
            <Slide direction="right" in={!exit} timeout={{ enter: 0, exit: 1000 }} onExited={onExited}>
              <Wrapper>
                <Section1 />
                <Section2 />
                <Section3 />
                <Section4n5 />
                <Section6 />
                <Section7n8 />
                <Section9 />
                <Section10 />
                <Section11n12 />
                <Box position="fixed" zIndex={1} right={24} bottom={24}>
                  <Button onClick={onExit} endIcon={<ChevronRightIcon />}>
                    Next
                  </Button>
                </Box>
              </Wrapper>
            </Slide>
          </div>
        </Grow>
      </Box>
    </Slide>
  );
};

export default Diary;
