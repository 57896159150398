import React, { useRef, useEffect, useState, useCallback } from 'react';
import YouTube from 'react-youtube';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import { StyledBox } from './QuoteBox';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const Soundtrack = props => {
  const mounted = useRef();
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, [])

  const playVideoTimeout = useRef();
  useEffect(() => () => clearTimeout(playVideoTimeout.current), []);
  const [videoPlayer, setVideoPlayer] = useState();
  const onReadyVideo = useCallback(({ target: player }) => {
    setVideoPlayer(player);
  }, []);

  const [playing, setPlaying] = useState(false);
  const toggleSound = useCallback(() => setPlaying(v => {
    if (v) {
      videoPlayer.pauseVideo();
      return false;
    }
    videoPlayer.playVideo();
    videoPlayer.unMute();
    videoPlayer.setVolume(80);
    return true;
  }), [videoPlayer]);

  return (
    <StyledBox display="flex" {...props} onClick={toggleSound} style={{ cursor: 'pointer' }}>
      <Tooltip title={playing ? 'Pause' : 'Play'}>
        {playing ? <VolumeOffIcon /> : <VolumeUpIcon />}
      </Tooltip>
      <Box visibility="hidden" position="fixed" margin={4}>
        <YouTube
          videoId="OTMlupDYPek"
          onReady={onReadyVideo}
          opts={{ width: 0, height: 0, playerVars: { start: 1 } }}
        />
      </Box>
    </StyledBox>
  );
};

export default Soundtrack;
