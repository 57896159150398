import React, { useState, useCallback } from 'react';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import Magnifier from "react-magnifier";

const MainImg = styled.img`
  transition: ${props => props.theme.transitions.create(['filter'])};

  &[data-loading="true"] {
    filter: blur(10px) brightness(0.4);
  }
`;

const ThumbImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  transition: ${props => props.theme.transitions.create(['filter'])};

  &:hover {
    filter: brightness(2);
  }

  &[data-selected="true"] {
    filter: brightness(0.2);
  }
`;

const UntitledGallery = ({ images, onLoad: onLoadProp }) => {
  const [activeImage, setActiveImage] = useState(images[0]);
  const [didNotifyInitialLoad, setDidNotifyInitialLoad] = useState(false);
  const [dimensions, setDimensions] = useState();
  const [loadedImages, setLoadedImages] = useState([]);
  const onLoad = e => {
    const { width, height, src } = e.target;
    if (!didNotifyInitialLoad) {
      setDidNotifyInitialLoad(true);
      setDimensions({ width: width, height: height })
      if (onLoadProp) {
        onLoadProp(true);
      }
    }
    setLoadedImages(prev => [...prev, src]);
  };

  const renderImage = useCallback((image, i, arr) => (
    <Box
      key={image.src}
      display="flex"
      width={120}
      height={`${100 / arr.length}%`}
      position="relative"
      margin={1}
      marginTop={i === 0 ? 0 : 1}
      marginBottom={arr[i + 1] ? 1 : 0}
    >
      <img height="100%" src="/images/blank.png" alt="" />
      <Box
        position="absolute"
        top={0}
        right={0}
        bottom={0}
        left={0}
      >
        <ThumbImg
          src={image.srcSet[0].slice(0, -5)}
          alt=""
          onClick={() => setActiveImage(image)}
          data-selected={image.src === activeImage.src}
        />
      </Box>
    </Box>
  ), [activeImage]);

  const loading = !loadedImages.find(srcs => srcs.endsWith(activeImage.src));

  const mainImgComponent = dimensions && !loading ? Magnifier : undefined;

  return (
    <Box display="flex" height="80vh">
      <Box height="100%" position="relative" display="flex" flexDirection="column" alignItems="center" justifyContent="center" bgcolor="black">
        <MainImg
          as={mainImgComponent}
          height="100%"
          alt=""
          data-loading={loading}
          onLoad={onLoad}
          {...activeImage}
          {...dimensions}
        />
        <Box position="absolute" color="white">
          {loading && <CircularProgress color="inherit" />}
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" marginLeft={1}>
        {images.map(renderImage)}
      </Box>
    </Box>
  );
};

export default UntitledGallery;
