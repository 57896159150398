import React, { useRef, useEffect } from 'react';
import Rellax from 'rellax';
import Box from '@material-ui/core/Box';
import text from './text.svg';
import img3 from './3.png'
import img4 from './4.png'
import img2 from './2.png'
import img1 from './1.png'
import lychee from './lychee.svg';

const Section2 = () => {
  const img1Ref = useRef();
  const img2Ref = useRef();
  const img3Ref = useRef();
  const img4Ref = useRef();
  const lycheeRef = useRef();
  useEffect(() => {
    new Rellax(img1Ref.current,   { speed: -0.5 });
    new Rellax(img2Ref.current,   { speed:  0.5 });
    new Rellax(img3Ref.current,   { speed: -0.5 });
    new Rellax(img4Ref.current,   { speed: -0.25 });
    new Rellax(lycheeRef.current, { speed:  0.2 });
  }, []);

  return (
    <Box position="relative" marginTop="379px">
      <Box ref={img4Ref} position="absolute" top={-45} left={602}>
        <img src={img4} />
      </Box>
      <Box ref={img3Ref} position="absolute" top={-78} left={-15}>
        <img src={img3} />
      </Box>
      <Box ref={img2Ref} position="absolute" top={28} left={106}>
        <img src={img2} />
      </Box>
      <Box ref={img1Ref} position="absolute" top={110} left={532}>
        <img src={img1} />
      </Box>
      <Box ref={lycheeRef} position="absolute" top={82} left={576} zIndex={1}>
        <img src={lychee} />
      </Box>
      <Box position="absolute" left={219}>
        <img src={text} />
      </Box>
    </Box>
  );
};

export default Section2;
