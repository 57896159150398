import styled from 'styled-components';

export const Footer = styled.footer`
  display: flex;
  margin: 2px 1px 1px;
  user-select: none;

  & > div {
    flex-grow: 1;
    box-shadow: inset -1px -1px 0px #ffffff, inset 1px 1px 0px 0px #808088, inset -2px -2px 0px #bbc3c4, inset 2px 2px 0px 0px #787878;
    padding: 2px 6px;
  }
`;
