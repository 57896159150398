import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Home from './Subpages/Home';
import SubAvocado1 from './Subpages/SubAvocado1';
import SubAvocado2 from './Subpages/SubAvocado2';
import Diary from './Subpages/Diary';
import Something from './Subpages/Something';
import Google from './Subpages/Google';
import Association from './Subpages/Association';
import { AvocadoPageProvider } from './AvocadoPageContext';
import Feldman from './Subpages/Feldman';
import Unitled from './Subpages/Untitled';

const Page5Index = () => {
  const match = useRouteMatch();
  return (
    <AvocadoPageProvider>
      <Switch>
        <Route path={`${match.url}/1`}>
          <SubAvocado1 />
        </Route>
        <Route path={`${match.url}/2`}>
          <SubAvocado2 />
        </Route>
        <Route path={`${match.url}/diary`}>
          <Diary />
        </Route>
        <Route path={`${match.url}/something`}>
          <Something />
        </Route>
        <Route path={`${match.url}/google`}>
          <Google />
        </Route>
        <Route path={`${match.url}/association`}>
          <Association />
        </Route>
        <Route path={`${match.url}/feldman`}>
          <Feldman />
        </Route>
        <Route path={`${match.url}/untitled`}>
          <Unitled />
        </Route>
        <Route>
          <Home />
        </Route>
      </Switch>
    </AvocadoPageProvider>
  );
};

export default Page5Index;
