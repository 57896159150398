import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Home from './Subpages/Home';
import Stages from './Subpages/Stages';
import Parsons from './Subpages/Parsons';
import Grid from './Subpages/Grid';
import Space from './Subpages/Space';
import Green from './Subpages/Green';
import Lime from './Subpages/Lime';
import WhatIsPaining from './Subpages/WhatIsPaining';
import AfterSpace from './Subpages/AfterSpace';
import InternetArt from './Subpages/InternetArt';
import Untitled2 from './Subpages/Untitled2';
import Memory from './Subpages/Memory';

const Page6Index = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.url}/stages`}>
        <Stages />
      </Route>
      <Route path={`${match.url}/parsons`}>
        <Parsons />
      </Route>
      <Route path={`${match.url}/grid`}>
        <Grid />
      </Route>
      <Route path={`${match.url}/space`}>
        <Space />
      </Route>
      <Route path={`${match.url}/green`}>
        <Green />
      </Route>
      <Route path={`${match.url}/lime`}>
        <Lime />
      </Route>
      <Route path={`${match.url}/what-is-painting`}>
        <WhatIsPaining />
      </Route>
      <Route path={`${match.url}/after-space`}>
        <AfterSpace />
      </Route>
      <Route path={`${match.url}/internet-art`}>
        <InternetArt />
      </Route>
      <Route path={`${match.url}/untitled2`}>
        <Untitled2 />
      </Route>
      <Route path={`${match.url}/memory`}>
        <Memory />
      </Route>
      <Route>
        <Home />
      </Route>
    </Switch>
  );
};

export default Page6Index;
