import React, { useState, useMemo } from 'react';
import { createGlobalStyle } from 'styled-components';

const AvocadoPageStyle = createGlobalStyle`
  body {
    background-color: ${props => props.bgColor || props.theme.palette.avocado} !important;
    transition: ${props => props.theme.transitions.create(['background-color'], { duration: 1000 })};
  }
`;

export const AvocadoPageContext = React.createContext({});

export const AvocadoPageProvider = ({ children }) => {
  const [state, set] = useState({});

  const value = useMemo(() => ({
    ...state,
    setBgColor: bgColor => set({ ...state, bgColor }),
  }), [state]);

  return (
    <AvocadoPageContext.Provider value={value}>
      <AvocadoPageStyle bgColor={state.bgColor} />
      {children}
    </AvocadoPageContext.Provider>
  );
};
