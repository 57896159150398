import React from 'react';

const EverythingLines = () => {
  return (
    <svg width="803" height="486" viewBox="0 0 803 486" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M800 1C777.305 30.3697 753.439 58.7183 731.889 88.8889C720.112 105.377 707.777 124.128 700.222 142.889C689.331 169.934 684.85 199.34 677 227.333C665.004 270.111 650.827 310.93 632.222 351.333C621.913 373.721 610.268 394.64 592.444 412C567.423 436.371 532.885 452.069 499.111 460.278C469.099 467.572 438.102 466.362 407.444 465.778C379.173 465.239 349.501 467.564 321.5 463.611C289.975 459.161 259.378 447.78 229 438.667C220.06 435.985 210.907 436.034 201.667 435.333C186.935 434.216 172.33 432.509 157.556 432C145.851 431.596 134.143 430.577 122.444 430C113.373 429.553 104.573 426.533 96 423.778C85.4936 420.401 73.1872 417.414 63.8889 411.167C51.0493 402.54 39.4122 392.109 26.7778 383.222C20.5075 378.812 12.9258 375.444 7 371" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M802 2C786.217 28.7095 762.343 50.0729 745.222 76.1111C742.256 80.6215 738.602 85.7085 737.444 91.1111C735.425 100.536 731.812 107.114 727.056 115.5C718.155 131.194 711.872 148.035 704.5 164.444C698.019 178.87 693.629 193.594 687.778 208.222C682.389 221.694 678.869 235.655 674.111 249.333C670.719 259.086 666.288 268.263 663.667 278.333C658.111 299.673 659.422 322.55 653.556 343.889C645.678 372.543 635.643 397.336 615.222 419C599.313 435.878 576.337 447.21 554.222 453.389C544.32 456.156 534.034 454.488 523.889 455.444C508.776 456.869 493.287 459.238 478.278 461.556C462.148 464.046 447.029 465.056 430.667 465C408.13 464.922 386.756 460.733 364.5 457.611C340.032 454.179 316.131 451.763 292.667 443.556C285.534 441.061 279.192 438.544 271.833 436.778C255.53 432.865 239.358 430.247 222.667 428.556C207.375 427.006 192.512 422.719 177.222 421.444C150.53 419.22 122.156 424.739 95.7778 419.667C73.8396 415.448 54.3936 405.774 36.8889 392.222C25.9212 383.731 15.3134 377.157 3 371" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M801 11C793.296 20.8443 786.357 31.5857 779.444 42C767.266 60.3493 754.75 78.393 742.556 96.7778C728.497 117.974 717.706 139.63 711.222 164.222C709.473 170.859 705.229 176.893 701.889 182.778C694.843 195.191 688.362 207.703 684 221.333C676.816 243.783 673.802 266.805 670.444 290C667.806 308.231 658.62 324.786 656.944 343.222C655.419 360.001 651.991 376.594 646.778 392.556C639.129 415.971 623.516 441.763 601.556 454.278C572.943 470.584 541.798 477.134 509.444 481.056C482.106 484.369 455.036 486.825 427.444 484.222C398.263 481.469 370.262 472.757 341.944 465.611C314.75 458.749 286.702 452.108 261 440.778C229.486 426.885 197.97 415.142 164.167 407.722C142.502 402.967 119.408 405 97.3333 405C78.9494 405 62.0101 404.291 43.8889 400.667C35.4134 398.972 28.4949 397.284 20.5 393.389C14.6172 390.523 5.6191 387.619 1 383" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M801 9C787.821 23.5551 774.498 38.1516 763.444 54.4444C756.557 64.5972 752.102 75.6624 747.111 86.7778C742.129 97.8733 738.156 109.314 733.333 120.444C725.311 138.957 713.115 155.662 703.444 173.333C683.014 210.668 667.525 250.739 650.444 289.667C645.674 300.539 638.668 311.689 636.222 323.444C633.678 335.674 630.702 347.267 626.667 359.111C620.375 377.58 615.527 394.519 604.778 411.222C590.49 433.423 572.572 455.214 548.556 467.222C526.739 478.131 501.944 478 478.111 478C460.942 478 443.686 478.054 426.667 475.556C417.815 474.256 409.285 474 400.333 474C391.881 474 384.828 472.523 376.556 470.889C367.336 469.068 357.559 469.237 348.222 468C334.315 466.157 320.435 464.266 306.556 462.222C277.991 458.016 249.458 453.696 220.889 449.556C200.139 446.548 179.225 443.723 158.556 440.333C134.942 436.461 110.208 434.467 87.4444 426.556C73.1448 421.586 61.6016 410.283 46.3333 408C40.2873 407.096 33.9427 406.802 28.1111 404.889C18.0171 401.578 11.7388 396.462 3 391" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M801 21C791.302 25.7309 783.094 42.361 778.667 51.8889C772.946 64.2014 767.947 76.913 763 89.5556C755.675 108.274 747.242 126.531 739.222 145C732.912 159.532 725.585 173.131 717.611 186.833C711.471 197.383 704.127 207.432 699.333 218.667C695.545 227.545 691.969 235.83 689.944 245.278C688.103 253.87 687.421 262.739 686.222 271.444C684.242 285.816 682.368 299.941 676.944 313.5C671.346 327.497 665.185 341.281 659.444 355.222C651.541 374.417 642.795 393.836 633.556 412.444C621.734 436.254 608.443 458.11 583.667 470.056C558.166 482.351 531.703 483 503.944 483C487.588 483 470.865 480.21 455.556 474.278C410.444 456.797 365.17 447 316.556 447C288.543 447 260.518 447.865 232.667 444.444C218.229 442.671 203.843 436.891 190 432.667C174.273 427.867 158.569 422.999 142.833 418.222C113.236 409.237 84.0614 401.381 53.5 396.556C43.1895 394.928 34.3706 393.376 24.8889 389C18.5231 386.062 12.2682 383.134 6 380" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

export default EverythingLines;
