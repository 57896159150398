import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ListenMuteIcon = props => (
  <SvgIcon {...props}>
    <path d="M3 9V15H7L12 20V4L7 9H3Z" />
  </SvgIcon>
);

export default ListenMuteIcon;
