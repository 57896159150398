import React from 'react';
import Box from '@material-ui/core/Box';
import { GlobalPackardBelleWindowCss, GlobalPackardBelleCssOverrides, StyledPBWIndow } from './Window.styled';

export { View } from './Window.styled';

const Window = ({ title, icon, onClose, children, ...restProps }) => {
  return (
    <Box {...restProps}>
      <StyledPBWIndow title={title} icon={icon} onClose={onClose}>
        <GlobalPackardBelleCssOverrides />
        <GlobalPackardBelleWindowCss />
        {children}
      </StyledPBWIndow>
    </Box>
  );
};

export default Window;
