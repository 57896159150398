import Typography from '@material-ui/core/Typography';
import styled, { css } from 'styled-components';

export const monospaceCss = css`
  font-family: ${props => props.theme.typography.monospaceFontFamily} !important;
  font-size: ${props => props.fontSize}px !important;
  letter-spacing: -0.04em;
`;

const Monospace = styled(Typography)`
  ${monospaceCss};
`;

export default Monospace;
