import React from 'react';
import $ from 'jquery';
import '@ksedline/turnjs';
import { createGlobalStyle } from 'styled-components';

const options = {
  width: 800,
  height: 600,
  autoCenter: true,
  display: "double",
  acceleration: true,
  elevation: 50,
  gradients: !$.isTouch,
};

const GlobalStyle = createGlobalStyle`
  .magazine {
    user-select: none;
    margin-left: 0 !important;

    img {
      user-select: none;
    }
  }

  .magazine .page {
    height: 100%;
  }

  .magazine .page img {
    max-width: 100%;
    height: 100%;
  }
`;

export default class Turn extends React.Component {
  static defaultProps = {
    style: {},
    className: "",
    options,
  };

  componentDidMount() {
    if (this.el) {
      $(this.el).turn(Object.assign({}, this.props.options));
    }
    document.addEventListener("keydown", this.handleKeyDown, false);
  }

  componentWillUnmount() {
    if (this.el) {
      $(this.el)
        .turn("destroy")
        .remove();
    }
    document.removeEventListener("keydown", this.handleKeyDown, false);
  }

  handleKeyDown = event => {
    if (event.keyCode === 37) {
      $(this.el).turn("previous");
    }
    if (event.keyCode === 39) {
      $(this.el).turn("next");
    }
  };

  render() {
    return (
      <>
        <GlobalStyle />
        <div
          className={this.props.className}
          style={Object.assign({}, this.props.style)}
          ref={el => (this.el = el)}
        >
          {this.props.children}
        </div>
      </>
    );
  }
}
