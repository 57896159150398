import React, { useState, useEffect } from 'react';
import { useTransition, animated } from 'react-spring'
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import ListenMuteIcon from './ListenMuteIcon';
import ListenDownIcon from './ListenDownIcon';
import ListenUpIcon from './ListenUpIcon';

const Wrapper = styled(Box).attrs({ component: 'span' })`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;

  svg {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

const keyframes = [
  { id: 0, i: animated(ListenMuteIcon) },
  { id: 1, i: animated(ListenDownIcon) },
  { id: 2, i: animated(ListenUpIcon) },
];

const ListenAnimation = ({ play, ...restProps }) => {
  const [index, setIndex] = useState(play ? 0 : 2);

  const transitions = useTransition(keyframes[index], item => item.id, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 1 },
  });

  useEffect(() => {
    if (!play) {
      return;
    }

    const interval = setInterval(() => setIndex(state => (state + 1) % 3), 333);
    return () => clearInterval(interval);
  }, [play]);

  const renderKeyframe = ({ item, props, key }) => React.createElement(item.i, {
    key,
    style: props,
  });

  return (
    <Wrapper {...restProps}>
      {transitions.map(renderKeyframe)}
    </Wrapper>
  );
};

export default ListenAnimation;
