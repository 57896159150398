import React from 'react';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import { Link } from 'react-router-dom';
import Monospace from '../Monospace';

export default function Teotr() {
  return (
    <Fade in timeout={500}>
      <Box margin="auto" textAlign="center">
        <Monospace>
          You have reached the end of this route.
          <br />
          <Link to="/read/3">
          CLICK HERE
          </Link>
          &nbsp;
          to go to the beginning of the text.
        </Monospace>
      </Box>
    </Fade>
  );
}
