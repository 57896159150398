import React, { useCallback, useRef, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

const DelayLink = ({ delay, onDelayStart, onDelayEnd, ...restProps }) => {
  const timeout = useRef();
  useEffect(() => () => {
    clearTimeout(timeout.current);
  }, []);

  const history = useHistory();
  const onClick = useCallback(event => {
    event.preventDefault();

    if (onDelayStart) {
      onDelayStart(event);
    }

    timeout.current = setTimeout(() => {
      if (restProps.replace) {
        history.replace(restProps.to);
      } else {
        history.push(restProps.to);
      }

      if (onDelayEnd) {
        onDelayEnd(event);
      }
    }, delay);
  }, [delay, onDelayStart, onDelayEnd, history, restProps.replace, restProps.to]);

  return <Link {...restProps} onClick={onClick} />;
};

export default DelayLink;
