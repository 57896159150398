import React, { useState } from 'react';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import DelayLink from '../../DelayLink';
import Monospace from '../../Monospace';

const TIMEOUT = 1000;

const Flowers = styled.img`
  cursor: pointer;
`;

const Page3 = () => {
  const [isIn, setIsIn] = useState(true);

  const handleOut = () => {
    setIsIn(false);
  };

  return (
    <Fade in={isIn} timeout={TIMEOUT}>
      <Box padding={3} display="flex" height="100%" maxWidth={1200} margin="auto">
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Monospace>
            When I was a little kid, I really liked drawing. I used to draw on the wallpaper and all kinds of different surfaces that I could find in my room. My parents were highly enthusiastic about it; they encouraged my little hobby by providing me art supplies and giving positive feedback. Simple caricatures are important because they show that your child was interested in creativity and has found a way to a self-expression. However, when time went by and my drawings kept an elementary style in them, the admiration of my artistic skills from my parents’ side abruptly subsided. I thought it was a funny coincidence.
          </Monospace>
          <Box marginY={8}>
            <Monospace>
              In 1995, the Metropolitan Museum of Art opened an exhibition called “Rembrandt/Not Rembrandt”, where the works of a genius Dutch painter were displayed alongside those made by his students and admirers. The public had a chance to see the paintings that were proclaimed masterpieces and some of the other works of a lesser value that, however, were once thought to be created by Rembrandt. The idea of the exhibition was to reveal the line between genius and imitation, authenticity and fakery. That brought up an interesting question of how the verdict of art history warps what we see.
            </Monospace>
          </Box>
          <Monospace>
            I once asked my friend, who came to the Netherlands to do Masters program in Fine Arts, about his further plans after graduation. The only thing he replied was “I do not want to go back to Russia”. He told me that a lot of the stuff he was doing was somehow different from the art that is highly in demand in the Russian contemporary art scene, because of that huge influence his work was perceived not exactly how he would’ve wanted it to be perceived.
          </Monospace>
        </Box>
        <Fade in timeout={5000}>
          <Box marginLeft={6} alignSelf="center">
            <DelayLink to="/read/4" delay={TIMEOUT} onDelayStart={handleOut}>
              <Flowers width={400} src="/images/flowers.jpg" />
            </DelayLink>
          </Box>
        </Fade>
      </Box>
    </Fade>
  );
};

export default Page3;
