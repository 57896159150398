import React, { useState, useContext, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import { PotatoPageContext } from '../../../PotatoPageContext';
import { ThemeContext } from 'styled-components';
import { useHistory } from 'react-router-dom';

const Variation1i = () => {
  const pageCtx = useContext(PotatoPageContext);
  const theme = useContext(ThemeContext);
  useEffect(() => pageCtx.setBgColor(theme.palette.blueberry), []);

  const [loaded, setLoaded] = useState(false);
  const onLoad = () => setLoaded(true);

  const [exit, setExit] = useState(false);
  const onExit = () => setExit(true);
  const history = useHistory();
  const onExited = () => history.push('/read/potato/art-in-ukraine/blueberry/variation1');

  return (
    <>
      <Fade in={loaded} timeout={500}>
        <Box margin="auto">
          <Slide in={!exit} timeout={{ enter: 0, exit: 500 }} onExited={onExited}>
            <Box height="90vh">
              <img
                src="/images/variation1.jpg"
                style={{ height: '100%', borderRadius: 2, cursor: 'pointer' }}
                onLoad={onLoad}
                alt=""
                onClick={onExit}
              />
            </Box>
          </Slide>
        </Box>
      </Fade>
      {!loaded && (
        <Box position="fixed" top="calc(50% - 44px)" left="calc(50% - 44px)">
          <CircularProgress color="inherit" />
        </Box>
      )}
    </>
  );
};

export default Variation1i;
