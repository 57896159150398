import styled from 'styled-components';

const Button8 = styled.button`
  border: none;
  height: 97px;
  width: 132px;
  background-color: transparent;
  background-image: url("/images/8_button_${props => props.color}.png");
  cursor: pointer;
  outline: none;
  transform: scale(0.5);

  &:active {
    background-position-x: 100%;
  }
`;

Button8.defaultProps = {
  color: 'red',
};

export default Button8;
