import React, { useState, useEffect } from "react";

const randomize = str =>
  [...str].map(() => {
    const r = Math.random();
    const c = r.toString(36).slice(2, 3);
    return c;
  });

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const Matex = ({ value, wait = 0 }) => {
  const [letters, setLetters] = useState([...value]);

  useEffect(() => {
    let cancel = false;

    (async () => {
      for (let i = 0; i < 20 + wait; i++) {
        if (cancel) return;
        setLetters(randomize(value));
        await sleep(50);
      }

      // from end to start
      // for (let i = value.length - 1; i > -1; i--) {
      //   if (cancel) return;
      //   await sleep(50);
      //   setLetters(prev => {
      //     const next = [...prev];
      //     next.splice(0, i, ...randomize(next.slice(0, i)));
      //     next.splice(i, 1, value[i]);
      //     return next;
      //   });
      // }

      // from start to end
      for (let i = 0; i < value.length; i++) {
        if (cancel) return;
        setLetters(prev => {
          const next = [...prev];
          next.splice(i, prev.length, ...randomize(next.slice(i, prev.length)));
          next.splice(i, 1, value[i]);
          return next;
        });
        await sleep(50);
      }
    })();

    return () => (cancel = true);
  }, [value, wait]);

  return letters.map((l, i) => (
    <div
      key={l + i}
      style={{ width: "1ch", display: "inline-block", textAlign: "center" }}
    >
      {l}
    </div>
  ));
};


export default Matex;
