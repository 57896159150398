import React from 'react';

const Lemon = () => (
  <svg width="68" height="70" viewBox="0 0 68 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.8172 57.9376L11.2878 43.1983L9.97662 30.3678L18.3782 26.5927L25.471 23.895L25.7639 9.29532L42.9065 10.8887L52.9561 15.1797L57.3072 26.854L60.5594 33.151L53.7645 49.0646L50.8102 55.9836L36.412 53.926L22.8172 57.9376Z" fill="#FFE605" stroke="black" stroke-width="2"/>
  </svg>
);

export default Lemon;

