import React, { useRef, useEffect, useState } from 'react';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import styled, { css } from 'styled-components';
import Monospace from '../../Monospace';

const Text = styled(Monospace)`
  text-align: center;

  a {
    cursor: pointer;
  }

  ${props => props.exitDir === 'left' ? css`
    max-width: ${props.width}px;
  ` : css`
    min-width: ${props.width}px;
  `}
`;

const Page1Perception = ({ in: inProp, onPerceptionClick, exitDir }) => {
  const ref = useRef();
  const [width, setWidth] = useState(undefined);
  useEffect(() => {
    setTimeout(() => {
      setWidth(inProp ? ref.current.clientWidth : undefined);
    }, 1000);
  }, [inProp]);

  return (
    <Fade in={inProp} timeout={1000}>
      <Box maxWidth={648} paddingBottom={2}>
        <Text ref={ref} exitDir={exitDir} width={width}>
          Art does not exist without the viewer. There are always two equal positions on the stage with the possibility of evolving into each other: the artist and the spectator. Bringing and receiving. Receiving might turn into bringing in an endless loop where bringing could be susceptible or flexible enough to become receiving. The connection thread or a transition line that allows two of them willingly communicate is <Link onClick={onPerceptionClick}>perception</Link>.
        </Text>
      </Box>
    </Fade>
  );
};

export default Page1Perception;
