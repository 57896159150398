import React, { useState, useRef } from 'react';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import styled, { css, createGlobalStyle } from 'styled-components';
import { useHistory } from 'react-router-dom';
import anime from 'animejs';
import omit from '../../utils/omit';

const UnDevoured = createGlobalStyle`
  body {
    transition: ${props => props.theme.transitions.create(['background-color'], { duration: 5000 })};
    background-color: ${props => props.theme.palette.background.default} !important;
  }
`;

const Text = styled.span`
  font-size: ${props => props.fontSize && `${props.fontSize}em`};
  margin-right: 8px;
  ${props => omit(props, ['children', 'theme', 'fontSize'])}
`;

const Wrapper = styled.div`
  line-height: 1;
  hyphens: auto;
  font-size: calc(14px + (26 - 14) * ((100vw - 1024px) / (1920 - 1024)));
  text-align: justify;

  cursor: pointer;
  transition: ${props => props.theme.transitions.create(['color', 'letter-spacing'], { duration: 5000 })};
  -webkit-background-clip: text;
  background-clip: text;
  ${props => props.xy && css`
    color: transparent;
    letter-spacing: 10em;
  `}
`;

const Page1Quotes = ({ in: inProp, open, ...restProps }) => {
  const history = useHistory();
  const ref = useRef();
  const [out, setOut] = useState(false);
  const [xy, setXY] = useState();
  const onClick = event => {
    if (!open || out) {
      return;
    }
    const x = `${Math.round(event.clientX)}px`;
    const y = `${Math.round(event.clientY)}px`;
    setXY({ x, y });
    anime({
      targets: ref.current,
      duration: 3000,
      easing: 'linear',
      backgroundImage: [
        `radial-gradient(0px at ${x} ${y}, red, rgb(145, 152, 229))`,
        `radial-gradient(${window.innerWidth * 5}px at ${x} ${y}, red, rgb(145, 152, 229))`,
      ],
    });
    setOut(true);
    setTimeout(() => {
      history.push('/read/2');
    }, 5000);
  };

  return (
    <Fade in={inProp && !out} timeout={out ? 5000 : 1000}>
      <Box display="flex" alignItems="center" {...restProps}>
        {out && <UnDevoured />}
        <Wrapper ref={ref} onClick={onClick} xy={xy} style={{ backgroundImage: `radial-gradient(0px, red, rgb(145, 152, 229))` }}>
          <Text fontWeight="bold" flexShrink={0}>
            "Art is a conduit toward human needs and perception" John Maeda
          </Text>
          <Text fontFamily="monospace" fontSize={0.8} fontWeight="bold">
            “We don't see things as they are. We see them as we are” Anais Nin
          </Text>
          <br />
          <Text fontSize={3}>
            "The viewer brings something individual to the experience of any artwork" Olafur Eliasson
          </Text>
          <Text fontFamily="serif" fontSize={0.9}>
            “A work of art doesn't exist outside the perception of the audience” Abbas Kiarostami
          </Text>
          <Text fontFamily="fantasy">
            "I use visual perception as a way of bringing people into my space" Richard Artschwager
          </Text>
          <Text fontFamily="serif" fontSize={1.8} fontWeight="bold" flexShrink={0}>
            "Through the experience of art, the powers of perception and transformation can be awakened, in both those who create it and those who re-perceive it" John Paul Caponigro
          </Text>
          <Text fontFamily="serif" fontSize={1.1}>
            “Perception is a guess or estimate of what is 'out there' depending on how we read the clues; therefore it can never be absolute and often is unreliable” Earl Kelley
          </Text>
          <Text fontSize={1.2}>
            “Perception is strong and sight weak” Miyamoto Musashi
          </Text>
          <Text fontSize={0.7}>
            “A work of art doesn't exist outside the perception of the audience” Abbas Kiarostami
          </Text>
          <Text fontFamily="serif" fontSize={1.5} fontWeight="bold">
            “So much of what we do and see and think is through our private and individual filter of perception.” Mary Timme
          </Text>
          <Text fontFamily="fantasy" fontSize={0.8} fontWeight="bold">
            “There are things known and there are things unknown, and in between are the doors of perception.” Aldous Huxley
          </Text>
          <Text fontFamily="monospace" fontSize={1.1}>
            “The only way art lives is through the experience of the observer. The reality of art begins with the eyes of the beholder, through imagination, invention and confrontation” Keith Haring
          </Text>
          <Text fontFamily="serif" fontSize={0.93} fontWeight="bold">
            “Every person has a different view of another person's image. That's all perception. The character of a man, the integrity, that's who you are.” Steve Alford
          </Text>
          <Text fontSize={0.8} fontWeight="bold">
            “Perception is demonstrably an active rather than a passive process; it constructs rather than records 'reality’.” Michael Michalko
          </Text>
          <Text fontFamily="serif" fontSize={1.2}>
            "All perceiving is also thinking, all reasoning is also intuition, all observation is also invention" Rudolf Arnheim
          </Text>
          <Text fontFamily="serif" fontSize={1.7} fontWeight="bold">
            "Things which we see are not by themselves what we see ... It remains completely unknown to us what the objects may be by themselves and apart from the receptivity of our senses. We know nothing but our manner of perceiving them" Immanuel Kant
          </Text>
          <Text fontSize={1.6}>
            “The awareness of imagery is part of living... a life which derives its power from within itself will focus on the perception... of images” Oskar Kokoschka
          </Text>
          <Text>
            “Art is supposed to hold up a mirror to its audience and ask, 'What do you believe? What do you think? What do you feel?' and if you look at a painting and it makes you feel nothing, that's a feeling as well.” Chris Sullivan
          </Text>
          <Text fontSize={1.5} fontWeight="bold">
            “The evolution of art is not only driven by artists, but by a conversation between the artists and the audience” Ken Liu
          </Text>
          <Text fontSize={1.2} fontFamily="serif">
            “Art brings a message into a room. It should make us perceive in a new way - either through color, form or narrative content - something we had not perceived before... and perhaps reveal something to you about yourself” Gloria Vanderbilt
          </Text>
          <Text fontSize={1.1} fontWeight="bold">
            “Whenever you finish an artwork and the viewer comes and views it, at that moment you've given up control.” Jeff Koons
          </Text>
          <Text fontSize={1.8} fontWeight="bold">
            “Organized perception is what is art all about.. .It is a process. It has nothing to do with any external form the painting takes, it has to do with a way of building a unified pattern of seeing...” Roy Lichtenstein
          </Text>
          <Text fontWeight="bold">
            “The work itself has a complete circle of meaning and counterpoint. And without your involvement as a viewer, there is no story” Anish Kapoor
          </Text>
        </Wrapper>
      </Box>
    </Fade>
  );
};

export default Page1Quotes;
