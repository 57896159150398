import React, { useRef, useEffect } from 'react';
import Rellax from 'rellax';
import Box from '@material-ui/core/Box';
import EverythingLines from './EverythingLines';
import text7 from './text7.svg';
import text8 from './text8.svg';
import karina from './karina.png';
import text_date from './text_date.svg';
import text_want from './text_want.svg';

const Section7n8 = () => {
  const karinaRef = useRef();
  const dateRef = useRef();
  const wantRef = useRef();
  useEffect(() => {
    new Rellax(karinaRef.current, { speed:  0.8,  center: true });
    new Rellax(dateRef.current,   { speed: -0.5,  center: true });
    new Rellax(wantRef.current,   { speed: -0.3,    center: true });
  }, []);

  return (
    <Box position="relative" marginTop="1900px">
      <Box ref={dateRef} position="absolute" top={100}>
        <img src={text_date} />
      </Box>
      <Box ref={wantRef} position="absolute" top={0} left={516}>
        <img src={text_want} />
      </Box>
      <Box position="absolute" top={-75}>
        <EverythingLines />
      </Box>
      <Box position="absolute" top={0} left={28}>
        <img src={text7} />
      </Box>
      <Box position="absolute" top={152} left={28}>
        <img src={text8} />
      </Box>
      <Box ref={karinaRef} position="absolute" top={80} left={539}>
        <img src={karina} width={298} />
      </Box>
    </Box>
  )
};

export default Section7n8;
