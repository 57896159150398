import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

const Home = () => (
  <Box margin="auto">
    <Button variant="outlined" color="inherit" size="large" component={Link} to="/read">
      read the text
    </Button>
  </Box>
);

export default Home;
