import styled from 'styled-components';

export default styled.a`
  color: ${props => props.theme.palette.text.primary};
  text-decoration: none;
  position: relative;
  display: block;

  &::before {
    position: absolute;
    bottom: 100%;
    left: 0;
    background: black;
    color: white;
    padding-bottom: 2px;
  }

  &:hover {
    background: black;
    color: white;

    &::before {
      content: "Read More";
    }
  }
`;
