import React, { useRef, useEffect } from 'react';
import Rellax from 'rellax';
import Box from '@material-ui/core/Box';
import TopLine from './TopLine';
import Lemon from './Lemon';
import EverythingLines from './EverythingLines';
import Text from './Text';

const Section1 = () => {
  const dateRef = useRef();
  const lemonRef = useRef();
  const noteRef = useRef();
  const museumRef = useRef();
  const woodRef = useRef();
  const whiteStrokeRef = useRef();
  useEffect(() => {
    new Rellax(dateRef.current,       { speed: -0.5 });
    new Rellax(lemonRef.current,      { speed: -3 });
    new Rellax(noteRef.current,       { speed: -2 });
    new Rellax(museumRef.current,     { speed: -3 });
    new Rellax(woodRef.current,       { speed:  0.8 });
    new Rellax(whiteStrokeRef.current,{ speed:  3 });
  }, []);

  return (
    <Box position="relative" display="flex">
      <Box ref={museumRef} position="absolute" color="white" fontSize={48} lineHeight={1} textAlign="right" right={0}>
        VAN
        <br />
        ABBEMUSEUM
      </Box>
      <Box ref={noteRef} position="absolute" top={-83} left={-22}>
        <img src="/images/diary_s1_note.png" />
      </Box>
      <Box ref={lemonRef} position="absolute" top={51} left={142} zIndex={1}>
        <Lemon />
      </Box>
      <Box ref={whiteStrokeRef} position="absolute" left={200}>
        <TopLine />
      </Box>
      <Box ref={dateRef} position="absolute" top={220} left={233} fontSize={72} fontFamily="Candal" color="#DBD7D7">
        12/10/2019
      </Box>
      <Box ref={woodRef} position="absolute" right={0}>
        <img src="/images/diary_s1_wood.png" />
      </Box>
      <Box position="absolute" top={96} left={38}>
        <Text />
      </Box>
      <Box position="absolute" top={-4} left={-35} zIndex={1}>
        <EverythingLines />
      </Box>
    </Box>
  );
};

export default Section1;
