import React, { useContext, useEffect, useState, useRef, useCallback, useLayoutEffect } from 'react';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import styled from 'styled-components';
import SearchIcon from '@material-ui/icons/Search';
import { AvocadoPageContext } from '../AvocadoPageContext';
import { useHistory } from 'react-router-dom';

const InputWrapper = styled.div`
  background: #fff;
  display: flex;
  border: 1px solid #dfe1e5;
  box-shadow: none;
  border-radius: 24px;
  z-index: 3;
  height: 44px;
  margin: 0 auto;
  max-width: 582px;
  width: 100%;

  &:hover, &[data-focus="true"] {
    filter: drop-shadow(0 1px 6px rgba(32, 33, 36, 0.2));
    border-color: rgba(223, 225, 229, 0);
  }
`;

const InputWrapperInner = styled.div`
  flex: 1;
  display: flex;
  padding: 5px 16px 0 16px;
  padding-left: 14px;
`;

const Input = styled.input`
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.87);
  word-wrap: break-word;
  outline: none;
  display: flex;
  flex: 100%;
  height: 34px;
  font: 16px arial,sans-serif;
  line-height: 34px;
`;

const SearchIconWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 13px;
  margin-top: -5px;

  svg {
    fill: #9AA0A6;
  }
`;

const Button = styled.input`
  background-image: linear-gradient(top,#f5f5f5,#f1f1f1);
  background-color: #f2f2f2;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  color: #5F6368;
  font-family: arial,sans-serif;
  font-size: 14px;
  margin: 11px 6px;
  padding: 0 16px;
  line-height: 27px;
  height: 36px;
  min-width: 54px;
  text-align: center;
  cursor: pointer;
  user-select: none;

  &:hover {
    box-shadow: 0 1px 1px rgba(0,0,0,0.1);
    background-image: -webkit-linear-gradient(top,#f8f8f8,#f1f1f1);
    background-color: #f8f8f8;
    border: 1px solid #c6c6c6;
    color: #222;
  }

  &:focus {
    border: 1px solid #4d90fe;
    outline: none;
  }
`;

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

async function* generate(str, delay = 100) {
  for (const ch of str.split('')) {
    await sleep(delay);
    yield ch;
  }
}

async function* degenerate(str, delay = 25) {
  for (const ch of str.split('')) {
    await sleep(delay);
    yield ch;
  }
}

const script = async (cancel, setValue) => {
  await sleep(1000);
  for await (const ch of generate('why do people associate art with something')) {
    if (cancel.value) return;
    setValue(prev => prev + ch);
  }

  await sleep(500);
  for await (const _ of degenerate('why do people associate art with something')) {
    if (cancel.value) return;
    setValue(prev => prev.slice(0, -1));
  }

  await sleep(200);
  for await (const ch of generate('how not to be like frank stell')) {
    if (cancel.value) return;
    setValue(prev => prev + ch);
  }

  await sleep(1000);
  for await (const _ of degenerate('frank stell')) {
    if (cancel.value) return;
    setValue(prev => prev.slice(0, -1));
  }

  await sleep(100);
  for await (const ch of generate('roy lichtens')) {
    if (cancel.value) return;
    setValue(prev => prev + ch);
  }

  await sleep(1000);
  for await (const _ of degenerate('roy lichtens')) {
    if (cancel.value) return;
    setValue(prev => prev.slice(0, -1));
  }

  await sleep(100);
  for await (const ch of generate('kandinsky')) {
    if (cancel.value) return;
    setValue(prev => prev + ch);
  }

  await sleep(1000);
  for await (const _ of degenerate('how not to be like kandinsky')) {
    if (cancel.value) return;
    setValue(prev => prev.slice(0, -1));
  }

  await sleep(1000);
  for await (const ch of generate('how does art history influence my perception')) {
    if (cancel.value) return;
    setValue(prev => prev + ch);
  }

  await sleep(2000);
  for await (const _ of degenerate('how does art history influence my perception')) {
    if (cancel.value) return;
    setValue(prev => prev.slice(0, -1));
  }

  await sleep(1000);
  script(cancel, setValue);
};

const Google = () => {
  const pageCtx = useContext(AvocadoPageContext);
  useEffect(() => pageCtx.setBgColor('#fff'), []);

  const inputRef = useRef();
  const [value, setValue] = useState('');
  const [focus, setFocus] = useState(false);
  const onFocus = useCallback(() => {
    inputRef.current.focus();
    setFocus(true);
  }, []);
  const onBlur = useCallback(() => {
    inputRef.current.focus();
    setFocus(true);
  }, []);
  useLayoutEffect(() => {
    inputRef.current.focus();
    setFocus(true);

    let cancel = { value: false };
    script(cancel, setValue);
    return () => cancel.value = true;
  }, []);



  const [next, setNext] = useState();
  const onSearch = () => setNext('/read/avocado/untitled');
  const onImFeelingLucky = () => setNext('/read/potato/art-in-ukraine/blueberry/variation1/i');

  const history = useHistory();
  const onExited = () => history.push(next);

  return (
    <Fade in timeout={1000}>
      <Box
        width="100%"
        height="100%"
      >
        <Slide in={!next} direction="down" timeout={{ enter: 0, exit: 500 }} onExited={onExited}>
          <Box
            width="inherit"
            height="inherit"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            component="form"
            onSubmit={onSearch}
          >
            <Box marginBottom={4}>
              <img src="/images/google_logo.png" height={92} />
            </Box>
            <InputWrapper onClick={onFocus} data-focus={focus}>
              <InputWrapperInner>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <Input ref={inputRef} onBlur={onBlur} value={value} autoFocus />
              </InputWrapperInner>
            </InputWrapper>
            <Box marginTop={2}>
              <Button value="Google Search" type="button" onClick={onSearch} />
              <Button value="I'm Feeling Lucky" type="button" onClick={onImFeelingLucky} />
            </Box>
          </Box>
        </Slide>
      </Box>
    </Fade>
  );
};

export default Google;
