import React, { useState, useCallback, useLayoutEffect, useRef } from 'react';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import SpeechBubble from '../../../SpeechBubble';
import rand from '../../../utils/rand';
import { useEffect } from 'react';

const GlobalCss = createGlobalStyle`
  body {
    cursor: pointer;
  }
`;

const randCache = [];
const crand = (key, min, max) => {
  if (randCache[key] === undefined) {
    randCache[key] = rand(min, max);
  }
  return randCache[key];
};

const Quotes = ({ onCanLoadMore, in: inProp }) => (
  <Box marginY={4} margin="auto" display="flex" flexWrap="wrap" alignItems="center" justifyContent="center" paddingRight={8}>
    <SpeechBubble in={inProp} timeout={400} rtl={crand('dir1') > 0.5} marginTop={`${crand('mt', 8, 64)}px`} marginLeft={`${crand('ml', 8, 128)}px`} maxWidth={500}>
      You should look at the work of Jessica Stockholder. The way you assemble found materials makes me think of her art.
    </SpeechBubble>
    <SpeechBubble in={inProp} timeout={0} rtl={crand('dir2') > 0.5} marginTop={`${crand('mt', 8, 64)}px`} marginLeft={`${crand('ml', 8, 128)}px`} maxWidth={200}>
      These lines are like Keith Haring’s lines.
    </SpeechBubble>
    <SpeechBubble in={inProp} timeout={1200} rtl={crand('dir3') > 0.5} marginTop={`${crand('mt', 8, 64)}px`} marginLeft={`${crand('ml', 8, 128)}px`} maxWidth={400}>
      Are you using Mondriaan’s palette of colours?
    </SpeechBubble>
    <SpeechBubble in={inProp} timeout={3200} rtl={crand('dir4') > 0.5} marginTop={`${crand('mt', 8, 64)}px`} marginLeft={`${crand('ml', 8, 128)}px`} maxWidth={500}>
      You are painting one stick per day and then giving it away to people?
    </SpeechBubble>
    <SpeechBubble in={inProp} timeout={1600} rtl={crand('dir5') > 0.5} marginTop={`${crand('mt', 8, 64)}px`} marginLeft={`${crand('ml', 8, 128)}px`} maxWidth={300}>
      Ha, we went to the mountains last month and these really remind me of skiing.
    </SpeechBubble>
    <SpeechBubble in={inProp} timeout={800} rtl={crand('dir6') > 0.5} marginTop={`${crand('mt', 8, 64)}px`} marginLeft={`${crand('ml', 8, 128)}px`} maxWidth={600}>
      Wait a second. This makes me think of an artist. But I forgot his name. I will send it to you when I remember.
    </SpeechBubble>
    <SpeechBubble in={inProp} timeout={2400} rtl={crand('dir7') > 0.5} marginTop={`${crand('mt', 8, 64)}px`} marginLeft={`${crand('ml', 8, 128)}px`} maxWidth={400}>
      You know what I imagine these look like? Like a fence or a bunch of arrows stuck in the ground.
    </SpeechBubble>
    <Box>
      <SpeechBubble in={inProp} timeout={2800} rtl={crand('dir8') > 0.5} marginTop={`${crand('mt', 8, 64)}px`} marginLeft={`${crand('ml', 8, 128)}px`} maxWidth={300}>
        Andy Warhol also made versions.
      </SpeechBubble>
      <SpeechBubble in={inProp} timeout={4000} rtl={crand('dir9') > 0.5} marginTop={`${crand('mt', 8, 64)}px`} marginLeft={`${crand('ml', 8, 128)}px`} maxWidth={400}>
        I see Kandinsky’s influence in here. And a little bit of supremacism.
      </SpeechBubble>
    </Box>
    <SpeechBubble in={inProp} timeout={3600} rtl={crand('dir10') > 0.5} marginTop={`${crand('mt', 8, 64)}px`} marginLeft={`${crand('ml', 8, 128)}px`} maxWidth={400}>
      This shape reminds me of a painting by Philip Guston ‘Painting, Smoking, Eating’. The potato head. Do you know about it?
    </SpeechBubble>
    <SpeechBubble in={inProp} timeout={2000} rtl={crand('dir11') > 0.5} marginTop={`${crand('mt', 8, 64)}px`} marginLeft={`${crand('ml', 8, 128)}px`} maxWidth={500}>
      Have you ever seen this video of a drawing café somewhere in South Korea, I think? There is this café with a black and white interior that looks like an illusionary 2D drawing. Very weird.
    </SpeechBubble>
    <Box>
      <SpeechBubble in={inProp} timeout={5200} rtl={crand('dir12') > 0.5} marginTop={`${crand('mt', 8, 64)}px`} marginLeft={`${crand('ml', 8, 128)}px`} maxWidth={600}>
        You are commenting on Modernism, Minimalist art, Constructivism.
      </SpeechBubble>
      <SpeechBubble in={inProp} timeout={4800} rtl={crand('dir13') > 0.5} marginTop={`${crand('mt', 8, 64)}px`} marginLeft={`${crand('ml', 8, 128)}px`} maxWidth={500}>
        I see an octopus tentacle in here. Are those teeth? What does that NO mean? Are you referencing art history again?
      </SpeechBubble>
    </Box>
    <SpeechBubble in={inProp} timeout={4400} rtl={crand('dir14') > 0.5} marginTop={`${crand('mt', 8, 64)}px`} marginLeft={`${crand('ml', 8, 128)}px`} maxWidth={600}>
      This really looks as if you were making a comic out of art. A little bit of Roy Lichtenstein also.
    </SpeechBubble>
    <Box>
      <SpeechBubble in={inProp} timeout={8000} rtl={crand('dir15') > 0.5} marginTop={`${crand('mt', 8, 64)}px`} marginLeft={`${crand('ml', 8, 128)}px`} maxWidth={600}>
        Do you think you are the first one who put sticks leaning on the wall?
      </SpeechBubble>
      <SpeechBubble in={inProp} timeout={6800} rtl={crand('dir16') > 0.5} marginTop={`${crand('mt', 8, 64)}px`} marginLeft={`${crand('ml', 8, 128)}px`} maxWidth={600}>
        I recognize a reference to a painting in here. An outline makes a frame. Even one separate stick could be a painting.
      </SpeechBubble>
    </Box>
    <SpeechBubble in={inProp} timeout={6400} rtl={crand('dir17') > 0.5} marginTop={`${crand('mt', 8, 64)}px`} marginLeft={`${crand('ml', 8, 128)}px`} maxWidth={400}>
      So, how are you going to present these sticks? Just like this? I don’t believe you.
    </SpeechBubble>
    <SpeechBubble in={inProp} timeout={6000} rtl={crand('dir18') > 0.5} marginTop={`${crand('mt', 8, 64)}px`} marginLeft={`${crand('ml', 8, 128)}px`} maxWidth={600}>
      I saw this lamp on Instagram the other day. The colours and outlines reminded me of your work. I am going to send a picture of that lamp to you.
    </SpeechBubble>
    <SpeechBubble in={inProp} timeout={7200} rtl={crand('dir19') > 0.5} marginTop={`${crand('mt', 8, 64)}px`} marginLeft={`${crand('ml', 8, 128)}px`} maxWidth={400}>
      You are a weird artist.
    </SpeechBubble>
    <SpeechBubble in={inProp} timeout={7600} rtl={crand('dir20') > 0.5} marginTop={`${crand('mt', 8, 64)}px`} marginLeft={`${crand('ml', 8, 128)}px`} maxWidth={400}>
      Oooh, you know those album covers from the 70s? Sometimes they have weird cartoonish illustrations with the same vibe as your paintings.
    </SpeechBubble>
    <SpeechBubble in={inProp} timeout={5600} onEnter={onCanLoadMore} rtl={crand('dir21') > 0.5} marginTop={`${crand('mt', 8, 64)}px`} marginLeft={`${crand('ml', 8, 128)}px`} maxWidth={400}>
      Why are you painting this wood? Is there a backstory to it?
    </SpeechBubble>
  </Box>
);

// https://github.com/facebook/react/issues/14099#issuecomment-440013892
const useEventCallback = fn => {
  const ref = useRef();
  useLayoutEffect(() => {
    ref.current = fn;
  });
  return useCallback((...args) => ref.current.apply(null, args), []);
};

const useBottomScroll = (fn, threshold = 0) => {
  const onScroll = useEventCallback(() => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
    const scrolledToBottom = threshold + scrollTop + clientHeight >= scrollHeight;
    if (scrolledToBottom) fn();
  });

  useLayoutEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);
};

// avocado page
const Page5 = () => {
  const [canLoadMore, setCanLoadMore] = useState(false);
  const onCanLoadMore = useCallback(() => {
    setCanLoadMore(true);
  }, []);

  const [vc, setVc] = useState(1);
  const onScrolledToBottom = useCallback(() => {
    if (!canLoadMore) {
      return;
    }
    setVc(prev => prev + 1);
    setCanLoadMore(false);
  }, [canLoadMore]);
  useBottomScroll(onScrolledToBottom, 200);

  const history = useHistory();

  const [inState, setInState] = useState(true);
  useEffect(() => {
    let timeout;
    const onClick = () => {
      setInState(false);
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        history.push('/read/avocado/1');
      }, 1100);
    };
    window.addEventListener('click', onClick);
    return () => {
      window.removeEventListener('click', onClick);
      clearTimeout(timeout);
    };
  }, [history]);

  return (
    <>
      <GlobalCss />
      {Array(vc).fill().map((_, i) => <Quotes key={i} i={i} in={inState} onCanLoadMore={onCanLoadMore} />)}
    </>
  );
};

export default Page5;
