import React, { useState, useRef } from 'react';
import styled, { keyframes, css } from 'styled-components';
import * as d3 from 'd3';
import pathTween from '../utils/pathTween';
import rand from '../utils/rand';

const Lines = styled.g`
  path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
    animation-delay: 3s;
    animation-duration: 1s;
    animation-name: ${keyframes`
      0% {
        stroke-dashoffset: 1000;
      }
      100% {
        stroke-dashoffset: 0;
      }
    `};
  }
`;

const Shape = styled.path`
  stroke-linecap: round;
  stroke-dasharray: 1000;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-duration: 3s;
  animation-name: ${props => keyframes`
    0% {
      stroke-dashoffset: 1000;
      fill: transparent;
    }
    90% {
      stroke-dashoffset: 0;
      fill: transparent;
    }
    100% {
      fill: ${props.theme.palette.devoured};
    }
  `};
`;

const Butt = styled.div`
  cursor: pointer;
  transition: all 0.2s ease;

  svg {
    pointer-events: none;

    path {
      transition: stroke-width 200ms;
      stroke: #000;
      stroke-width: 4;
    }
  }

  ${props => props.devour ? css`
    pointer-events: none;
    transform: scale3d(64, 64, 1);
    transition-duration: 2s;

    svg {
      path {
        stroke-width: 0;
      }
    }
  ` : css`
    &:hover {
      transform: scale3d(1.25, 1.25, 1);

      svg {
        path {
          stroke-width: 3;
        }
      }
    }
  `}
`;

const Wrapper = styled.div`
  animation: ${keyframes`
    0% {
      transform: scale3d(1, 1, 1);
    }
    50% {
      transform: scale3d(1.15, 1.15, 1);
    }
    100% {
      transform: scale3d(1, 1, 1);
    }
  `} 4s infinite;
  animation-play-state: ${props => props.play ? 'running' : 'paused'};
`;

const path0 = `
  M77.6103 10.0736C77.5493 10.029 77.4858 9.98792 77.4201 9.95051C65.5933 3.2125 55.0292 1.2389 45.7628 2.82991C36.5042 4.41957 28.8015 9.52305 22.6057 16.4577C10.2762 30.2575 3.62626 51.608 2.12706 68.7847C1.07973 80.7842 6.63503 91.0211 13.5361 97.6981C16.9902 101.04 20.8456 103.557 24.5051 104.981C28.0957 106.378 31.8245 106.843 34.7877 105.548C37.6181 104.312 40.8731 104.315 45.3906 104.414C45.5068 104.416 45.6236 104.419 45.7411 104.421C50.0858 104.517 55.3046 104.631 61.5024 103.369C79.5422 99.6959 91.0694 86.1533 95.7576 70.2583C100.434 54.4035 98.3988 35.9603 89.1177 22.0332C86.1099 17.5198 82.3626 13.5472 77.6103 10.0736Z
`;

const path1 = `
  M47.1802 11.3853C47.1192 11.3407 47.0557 11.2996 46.99 11.2622C35.4001 4.65914 26.7908 1.84468 20.351 2.2782C17.0626 2.49958 14.3315 3.56867 12.1191 5.39405C9.93033 7.2 8.36466 9.65011 7.21996 12.4629C4.95694 18.0236 4.17863 25.4269 3.75126 33.3183C3.59283 36.2438 3.4825 39.2342 3.37082 42.261C3.17546 47.5557 2.976 52.9618 2.50779 58.3261C1.49299 69.953 4.05534 81.5309 8.07703 89.7568C10.082 93.8578 12.5187 97.2672 15.1907 99.4099C17.8743 101.562 21.1049 102.639 24.301 101.242C27.0982 100.02 30.7704 100.104 35.8094 100.305L36.0751 100.315C40.8929 100.508 46.6251 100.737 52.8993 99.4598C71.0636 95.7609 81.5861 81.2695 85.2642 64.5975C88.9339 47.9635 85.9137 28.7703 76.6645 14.8909C75.9465 13.8135 74.8014 13.2236 73.75 12.8717C72.6647 12.5083 71.3938 12.3044 70.0746 12.1866C67.4358 11.9508 64.2066 12.0298 61.0666 12.1324C60.6925 12.1447 60.3195 12.1572 59.9482 12.1697C57.1432 12.2643 54.4385 12.3554 52.1254 12.2698C50.8208 12.2215 49.7078 12.1185 48.8193 11.9459C47.8944 11.7663 47.3989 11.5452 47.1802 11.3853Z
`;

const path2 = `
  M51.1842 7.9865C51.1098 7.97365 51.0346 7.96502 50.9592 7.96067C36.8758 7.14792 26.3014 9.17533 18.618 13.4047C10.8791 17.6647 6.24345 24.0665 3.91919 31.6083C-0.666479 46.4879 3.7451 65.7712 10.2513 82.3898C14.8646 94.1737 24.3422 99.674 33.4754 101.141C38.022 101.872 42.5171 101.613 46.3501 100.63C50.1387 99.6579 53.4889 97.9215 55.5159 95.5082C57.7822 92.8099 60.7947 90.3546 64.6387 87.5132C65.8692 86.6036 67.1891 85.6517 68.5879 84.6428C71.4954 82.5457 74.7441 80.2025 78.2412 77.4822C93.1622 65.8756 98.9222 51.7414 96.8586 38.1369C94.8093 24.6266 85.1169 12.1321 70.1609 3.514L69.171 5.23196L70.1609 3.514C68.3349 2.46183 66.3509 2.58872 64.6219 3.04721C62.9095 3.50128 61.1369 4.36483 59.5251 5.17619C59.3134 5.28275 59.104 5.38867 58.8967 5.49353C57.4525 6.22398 56.1098 6.90315 54.8037 7.39105C53.3068 7.95025 52.1256 8.14904 51.1842 7.9865Z
`;

const path3 = `
  M57.1253 4.10035C57.0536 4.07671 56.9805 4.05716 56.9065 4.04179C43.5618 1.26994 32.9804 2.82261 24.7944 7.47819C16.6255 12.124 11.0957 19.7156 7.56186 28.5516C0.525595 46.1453 1.19183 69.165 5.05078 85.9481C7.80434 97.9238 16.8651 103.741 25.9659 105.669C30.5033 106.63 35.112 106.646 39.0962 105.986C43.0253 105.335 46.5832 103.984 48.8263 101.997C49.7118 101.212 50.8602 100.838 52.5039 100.753C53.9514 100.678 55.5583 100.831 57.476 101.014C57.8234 101.047 58.181 101.081 58.5497 101.115C63.2121 101.542 69.0467 101.863 75.0565 98.4701C91.1455 89.387 97.6473 72.1114 96.966 54.8173C96.2858 37.5515 88.4526 19.8386 75.3151 9.43225C72.8553 7.48377 69.3455 6.61301 66.1567 5.9916C65.188 5.80282 64.2439 5.63569 63.3308 5.47404C61.0337 5.06738 58.9321 4.69533 57.1253 4.10035Z
`;

const morph1 = (path, duration) => {
  d3.select(path)
  .transition()
  .duration(duration)
  .on("start", function() {
    d3.active(this)
      .attrTween("d", pathTween(path1, 4))
      .transition();
  });
};
const morph2 = (path, duration) => {
  d3.select(path)
  .transition()
  .duration(duration)
  .on("start", function() {
    d3.active(this)
      .attrTween("d", pathTween(path2, 4))
      .transition();
  });
};
const morph3 = (path, duration) => {
  d3.select(path)
  .transition()
  .duration(duration)
  .on("start", function() {
    d3.active(this)
      .attrTween("d", pathTween(path3, 4))
      .transition();
  });
};
const morph4 = (path, duration) => {
  d3.select(path)
  .transition()
  .duration(duration)
  .on("start", function() {
    d3.active(this)
      .attrTween("d", pathTween(path0, 4))
      .transition();
  });
};

const RedButt = React.forwardRef(function RedButt(props, ref) {
  const {
    onDevourStart,
    onDevourEnd,
  } = props;

  const [devour, setDevour] = useState(undefined);

  const onClick = event => {
    setDevour(prev => !prev);
    if (onDevourStart) onDevourStart(event);
  };

  const onTransitionEnd = event => {
    if (devour && event.nativeEvent.propertyName === 'transform') {
      if (onDevourEnd) onDevourEnd(event);
    }
  };

  const shapeRef = useRef(null);
  const shapeMorphTimeout = useRef(null);
  const shapeMorphVariant = useRef(1);
  const morph = () => {
    clearTimeout(shapeMorphTimeout.current);
    const timeout = Math.floor(rand(3000, 6000));
    shapeMorphTimeout.current = setTimeout(() => {
      if (shapeMorphVariant.current === 1) {
        morph2(shapeRef.current, timeout);
        shapeMorphVariant.current = 2;
      } else if (shapeMorphVariant.current === 2) {
        morph3(shapeRef.current, timeout);
        shapeMorphVariant.current = 3;
      } else if (shapeMorphVariant.current === 3) {
        morph4(shapeRef.current, timeout);
        shapeMorphVariant.current = 0;
      } else {
        morph1(shapeRef.current, timeout);
        shapeMorphVariant.current = Math.round(rand(0, 3));
      }
      morph();
    }, timeout);
  };

  const onAnimationEnd = event => {
    if (event.nativeEvent.target === shapeRef.current) morph();
  };

  return (
    <Wrapper play onClick={onClick}>
      <Butt
        devour={devour}
        onTransitionEnd={onTransitionEnd}
        onAnimationEnd={onAnimationEnd}
      >
        <svg
          ref={ref}
          viewBox="0 0 102 108"
          width={64}
          fill="none"
        >
          <defs>
            <clipPath id="redButtClip">
              <Shape
                id="redButt"
                d={path0}
                pathLength={1000}
                shapeRendering="geometricPrecision"
                ref={shapeRef}
              />
            </clipPath>
          </defs>
          <use xlinkHref="#redButt" />
          <Lines clipPath="url(#redButtClip)">
            <path
              d="M94.716 30.42c-3.354 1.746-6.454 5.52-6.454 5.52l-9.081 12.618s-4.343 8.675-8.687 16.955c-4.343 8.28-11.055 4.337-13.03 9.069-1.974 4.731 0 5.126 3.16 10.251 3.158 5.126 6.317 5.126 6.317 5.126l1.776 4.256 1.813 4.242M97.643 44.32c-2.121.565-10.86 18.313-10.86 18.313l-8.161 18.678 6.65 6.904"
              pathLength={1000}
            />
            <path
              d="M96.18 36.273c-4.184 2.174-11.32 14.07-12.864 18.421-1.545 4.35-4.247 13.922-4.247 13.922l-8.495 9.137v8.701l7.543 8.346"
              pathLength={1000}
            />
            <path
              d="M89.595 22.373C71.445 35.33 69.235 60.69 55.153 68.196c-14.083 7.506 11.54 31.927 8.837 34.651"
              pathLength={1000}
            />
          </Lines>
        </svg>
      </Butt>
    </Wrapper>
  );
})

export default RedButt;
