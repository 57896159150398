import React, { useState, useMemo, useContext, useEffect } from 'react';
import { ThemeContext } from 'styled-components';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import { Link } from 'react-router-dom';
import { QuotesWrapper } from './Pepper.styled';
import Sun from './Sun';
import Arrow from './Arrow';
import Blas from './Blas';
import No from './No';
import QuoteBox from './QuoteBox';
import * as quotes from './quotes';
import Soundtrack from './Soundtrack';
import Peppestuffoni from './Stuff';
import { PotatoPageContext } from '../../../../PotatoPageContext';

const Pepper = () => {
  const pageCtx = useContext(PotatoPageContext);
  const theme = useContext(ThemeContext);
  useEffect(() => {
    pageCtx.setBgColor(theme.palette.pepper);
  }, []);

  const [hover, setHover] = useState();
  const [didStuff, setDidStuff] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setDidStuff(true), 4444);
    return () => clearTimeout(timeout);
  }, [])

  const decorations = useMemo(() => (
    <>
      <Box position="absolute" top={-2} left={-2}>
        <Link to="/read/potato/art-in-ukraine/blueberry">
          <Sun />
        </Link>
      </Box>
      <Box position="absolute" bottom={8} left={8}>
        <Arrow />
      </Box>
      <Box position="absolute" top={8} right={8}>
        <Blas />
      </Box>
      <Box position="absolute" bottom={8} right={16}>
        <Link to="/read/potato/art-in-ukraine/pitaya">
          <No />
        </Link>
      </Box>
    </>
  ), []);

  return (
    <Fade in timeout={1000}>
      <Box position="relative" minWidth="100%" minHeight="100%" display="flex" padding={4}>
        {decorations}
        <Box zIndex={(didStuff && hover) ? 1 : 2}>
          <Peppestuffoni />
        </Box>
        <QuotesWrapper>
          <QuoteBox
            {...quotes.philMuseum}
            onMouseOver={() => setHover('philMuseum')}
            zIndex={hover === 'philMuseum' ? 1 : undefined}
            width={480}
          />
          <QuoteBox
            {...quotes.artMargins}
            onMouseOver={() => setHover('artMargins')}
            zIndex={hover === 'artMargins' ? 1 : undefined}
            width={720}
            top={250}
            left={-60}
          />
          <QuoteBox
            {...quotes.zolotoeSechenie}
            onMouseOver={() => setHover('zolotoeSechenie')}
            zIndex={hover === 'zolotoeSechenie' ? 1 : undefined}
            width={300}
            top={80}
            left={580}
          />
          <QuoteBox
            {...quotes.artNewsUkraine}
            onMouseOver={() => setHover('artNewsUkraine')}
            zIndex={hover === 'artNewsUkraine' ? 1 : undefined}
            width={700}
            top={150}
            left={100}
          />
          <QuoteBox
            {...quotes.pinchukArtCentre}
            onMouseOver={() => setHover('pinchukArtCentre')}
            zIndex={hover === 'pinchukArtCentre' ? 1 : undefined}
            width={300}
            left={760}
          />
          <Soundtrack
            top={-20}
            left={510}
          />
        </QuotesWrapper>
      </Box>
    </Fade>
  );
};

export default Pepper;
