import React, { useState, useLayoutEffect } from "react";
import styled, { css } from "styled-components";
import Box from '@material-ui/core/Box';
import Zoom from '@material-ui/core/Zoom';

const BUBBLE_BG = "#fff";
const BUBBLE_BORDER = "#333";
const BUBBLE_COLOR = "#333";

const rameCss = css`
  content: "";
  width: 5px;
  position: absolute;
  left: 15%;
`;

const rtlCss = css`
  left: 85%;
  transform: scaleX(-1);
`;

const Bubble = styled.div`
  z-index: 1;
  min-width: 150px;
  min-height: 40px;
  position: relative;
  box-shadow: -10px 0 ${BUBBLE_BORDER},
              0 -10px ${BUBBLE_BORDER},
              10px 0 ${BUBBLE_BORDER},
              0 10px ${BUBBLE_BORDER},
              -5px -5px ${BUBBLE_BORDER},
              -5px 5px ${BUBBLE_BORDER},
              5px 5px ${BUBBLE_BORDER},
              5px -5px ${BUBBLE_BORDER};

  &[data-rtl="true"] {
    &::before {
      ${rtlCss}
      margin-left: 5px;
    }

    &::after {
      ${rtlCss}
    }
  }

  &::before {
    ${rameCss}

    height: 5px;
    background: ${BUBBLE_BG};
    bottom: -30px;
    margin-left: -5px;
    box-shadow: -5px 0 ${BUBBLE_BORDER},
                0 5px ${BUBBLE_BORDER},
                -5px 5px ${BUBBLE_BORDER};
  }

  &::after {
    ${rameCss}

    height: 25px;
    background: ${BUBBLE_BG};
    bottom: -25px;
    box-shadow: 5px -5px ${BUBBLE_BG},
                0 -10px ${BUBBLE_BG},
                0 5px ${BUBBLE_BORDER},
                5px 0 ${BUBBLE_BORDER},
                10px -5px ${BUBBLE_BORDER},
                0 -10px ${BUBBLE_BORDER},
                -5px 0 ${BUBBLE_BORDER};
  }
`;

const Text = styled.h1`
  text-align: center;
  color: ${BUBBLE_COLOR};
  font-size: 32px;
  line-height: 1;
  font-family: "VT323", sans-serif;
  padding: 5px 15px;
  background: ${BUBBLE_BG};
  margin: 0;
  position: relative;
  perspective: 100px;
  z-index: 10;
  perspective-origin: 50% 50%;
  box-shadow: -5px 0 ${BUBBLE_BG},
              0 -5px ${BUBBLE_BG},
              5px 0 ${BUBBLE_BG},
              0 5px ${BUBBLE_BG};
`;

const growStyle = { transformOrigin: 'bottom left' };
const growStyleRtl = { transformOrigin: 'bottom right' };

const SpeechBubble = ({ children, rtl, timeout, onEnter, in: inProp = true, ...restProps }) => {
  const [render, setRender] = useState(!timeout);
  useLayoutEffect(() => {
    if (!timeout) {
      return;
    }
    const timeoutId = setTimeout(() => {
      setRender(true);
    }, timeout);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [timeout, onEnter]);

  const sb = (
    <Box padding="10px 10px 35px">
      <Bubble data-rtl={rtl}>
        <Text>{children}</Text>
      </Bubble>
    </Box>
  );

  return (
    <Box position="relative" {...restProps}>
      <Box visibility="hidden">
        {sb}
      </Box>
      <Box position="absolute" top={0} left={0}>
        <Zoom in={render && inProp} timeout={1000} onEnter={onEnter} style={rtl ? growStyleRtl : growStyle}>
          {sb}
        </Zoom>
      </Box>
    </Box>
  );
};

export default SpeechBubble;
