import React from 'react';

export default {
  1: (
    <>
      <p>
        Welcome to the new interactive virtual space. Before we start, let me tell you a little bit about the importance of this place. First of all, it is located in your head, which means that it is a completely personal experience which you will not be able to share, no matter how much you want. The other thing is that the result of entering this space might be different each time you hear or read this text, leading to a drastic creation of unique multi versions. The quality of the space depends on the time, physical circumstances and the actual environment the receiver is in.
      </p>
      <p>
        You may now enter the space.
      </p>
      <p>
        You are alone in a big room, surrounded by four white walls. As soon as your eyes get used to the bright light, you start to notice other parts of the space around you. If you look up, you see a crystal clear, blue sky. A weird, contrasting element, almost a stain in this sterile clean room. The deeper you go into the white, the more things slowly grow in front of you. Constructions of long geometrical strips made from an unknown material begin to intertwine in front of you. It is hard to say whether they remain to be flat or filled with volume. However, you notice that those strips have a simple pattern of lines on the surface and dominant black outlines that clearly separate these objects from the rest of the white space. It seems as if you were on a blank sheet of paper on which a drawing is slowly appearing. Constructions continue to grow, forcing you to bend over, go around them and look for free openings in order to keep going.
      </p>
      <p>
        Suddenly you see colours: pink, neon green and yellow. They are all framed in clumsy forms in various sizes that are limited by a thick black line. These forms hang, what it might seem like, in uncomfortable positions on random parts of the spatial construction. Some of them are about to fall and some are already laying on the floor. Similar to the stripes your brain cannot determine the nature of these obscure colourful shapes. If you look closer, you notice imperfect stitches and folds on the material. You want to touch and feel the texture of it, but you are not sure if this is allowed.
      </p>
      <p>
        The combinations cast eccentric shadows in the whole room due to the light coming from the open ceiling.
      </p>
      <p>
        You can hear echoing voices of people talking somewhere outside your area of view. You are traveling through an installation, a mystical forest or a segment of your imagination, stimulated by given instructions? The space doesn’t seem so empty anymore as it looked like before, now each corner of the room is perfectly balanced with colourful spots and geometric lines imitating something very familiar.
      </p>
      <p>
        Do not rush. Take your time to experience what you have just created.
      </p>
      <p>
        You may now leave the space.
      </p>
    </>
  ),

  2: (
    <>
      <p>
        Welcome back.
      </p>
      <p>
        Today, our space is not framed by the four walls of a white cube. Today we feel the sun’s touch on our face, a light breath of wind and freedom. The freedom of endless possibilities that this space can give us.
      </p>
      <p>
        We find ourselves in the middle of the city. There are many streets around you: wide and narrow, crowded and empty, long and short. You choose to walk along one of these streets. The sun is following you. Sometimes it hides behind the monumentality of the orange brick houses with big windows that, in their turn, are looking at you like a pair of curious eyes, reflecting the blue of the sky.
      </p>
      <p>
        Is it spring or beginning of the summer? Or maybe a warm snowless winter?
      </p>
      <p>
        When you look down under your feet, you see the paving stones laid in an intricate pattern. The more you look at them while walking, the stronger you get the feeling of them moving faster than you. At the moment you take your eyes away and decide to look forward you notice an ordinary building in front of you. Ordinary, at the first glance.
      </p>
      <p>
        An impressive amount of lines of different colours and sizes lean against this building. They grow out of the earth and try to reach the roof; some of the lines succeed, but some don’t because they are a little bigger than the fingers on your hands. When you come closer, you notice how wide the spectrum of the colours is. From bright yellow to the darker shades of blue with some simple patterns on them. Each of the lines is framed by a black border, and you can no longer distinguish reality from a fiction. You decide to walk along the perimeter of the building to see if there is a bald spot or fragment of the wall that is not touched by mysterious multi-coloured objects yet. You make a circle around this imperfect fence and end up at the same place. However, this little journey made you realize that the lines are neither flat nor volume, and it seems like they forcefully keep the building in its place like enormous roots. You try to count them, but you give up: the more the sun travels up in the sky, the more lines appear in sight.
      </p>
      <p>
        Such a monumental yet an out of place creation.
      </p>
      <p>
        Do you want to stay and observe what you have just reconstructed?
      </p>
      <p>
        You may now leave the space.
      </p>
    </>
  ),
};
