import React from 'react';

const Blueberry = props => {
  return (
    <svg width="71" height="78" viewBox="0 0 71 78" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1 30.7462C1 15.2462 12.4652 1.85432 17.5 1.24621C34.5678 -0.815286 44 10.7462 44 10.7462C49.7278 10.7462 59.111 10.0766 63 15.7462C66.6825 21.1148 70.1284 31.877 70 38.2462C69.7575 50.2778 65.5 71.1014 54 72.2462C42.5 73.3911 41.5 71.1014 41.5 71.1014C41.5 71.1014 40 75.5527 29.5 77.2462C15.5 79.5043 6.5 54.2462 8 46.7462C8 46.7462 1.00002 43.2462 1 30.7462Z"
        fill="#695EAE"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Blueberry;
