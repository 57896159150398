import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import { BrowserRouter, Switch, Route, useLocation } from 'react-router-dom';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { ThemeProvider } from 'styled-components';
import Box from '@material-ui/core/Box';
import HomePage from '../Home';
import FourOhFourPage from '../FourOhFour';
import ThesisPage from '../Thesis';
import { GlobalStyle } from './styled';

const systemFontFamily = [
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

const monospaceFontFamily = [
  'Courier New',
  'Courier',
  'monospace',
].join(',');

const muiTheme = createMuiTheme({
  typography: {
    fontFamily: systemFontFamily,
    monospaceFontFamily,
    fontSize: 12,
  },
  palette: {
    background: {
      default: '#fff',
    }
  },
});

const scTheme = {
  ...muiTheme,
  palette: {
    ...muiTheme.palette,
    devoured: '#E43232',
    avocado: '#00FF19',
    pinky: '#FF21B3',
    potato: '#ECC914',
    blueberry: '#695EAE',
    pepper: '#FF3D00',
    pitaya: '#DB6060',
  },
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const App = () => (
  <MuiThemeProvider theme={muiTheme}>
    <ThemeProvider theme={scTheme}>
      <BrowserRouter>
        <GlobalStyle />
        <ScrollToTop />
        <Box display="flex" flexDirection="column" flexGrow={1} flexShrink={0} alignItems="stretch">
          <Switch>
            <Route path="/" exact>
              <HomePage />
            </Route>
            <Route path="/read">
              <ThesisPage />
            </Route>
            <Route>
              <FourOhFourPage />
            </Route>
          </Switch>
        </Box>
      </BrowserRouter>
    </ThemeProvider>
  </MuiThemeProvider>
);

export default hot(App);
