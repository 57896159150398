import React, { useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import Zoom from '@material-ui/core/Zoom';
import Box from '@material-ui/core/Box';
import Moveable from "react-moveable";
import ExplorerIcon from '../../../../Win98/ExplorerIcon';
import Notepad from '../../../../Win98/Notepad';

const Interview = ({ index, name, children }) => {
  const [open, setOpen] = useState(false);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const wbRef = useRef();
  const translate = useRef([0, 0]);

  const id = `movable-notepad-${index}`;

  return (
    <>
      <ExplorerIcon
        icon="/images/win98_notepad.png"
        name={name}
        onDoubleClick={onOpen}
      />
      {ReactDOM.createPortal((
        <>
          <Box
            id={id}
            ref={wbRef}
            position="fixed"
            top={16 * (index + 1)}
            left={16 * (index + 1)}
            zIndex={open ? 2 : -1}
          >
            <Zoom in={open}>
              <Notepad
                title={name}
                onClose={onClose}
                width={800}
                maxWidth="90vw"
                height={600}
                maxHeight="80vh"
                overflow="auto"
              >
                {children}
              </Notepad>
            </Zoom>
          </Box>
          {open && (
            <Moveable
              target={document.querySelector(`#${id} .Window__heading`)}
              origin={false}
              edge={false}

              draggable
              throttleDrag={0}
              onDragStart={({ set }) => {
                set(translate.current);
              }}
              onDrag={({ beforeTranslate }) => {
                translate.current = beforeTranslate;
                wbRef.current.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px)`;
              }}
            />
          )}
        </>
      ), document.body)}
    </>
  );
};

export default Interview;
