import React, { useContext, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Zoom from '@material-ui/core/Zoom';
import Fade from '@material-ui/core/Fade';
import Grow from '@material-ui/core/Grow';
import { useHistory } from 'react-router-dom';
import { AvocadoPageContext } from '../AvocadoPageContext';
import Monospace from '../../../Monospace';
import styled from 'styled-components';
import { useState } from 'react';
import { useCallback } from 'react';

const PAGE_COLOR = '#EFFC9D';

const SvgExclude = styled.svg`
  position: absolute;
  transition: ${({ theme, transitionDuration = 500 }) => theme.transitions.create(['transform'], { duration: transitionDuration })};
`;

const Next = styled.svg`
  cursor: pointer;

  path {
    transition: ${props => props.theme.transitions.create(['stroke-width'])};
  }

  &:hover {
    path {
      stroke-width: 4;
    }
  }
`;

const Feldman = () => {
  const pageCtx = useContext(AvocadoPageContext);
  useEffect(() => pageCtx.setBgColor(PAGE_COLOR), []);

  const [hover, setHover] = useState(0);
  const onMouseEnter = useCallback(e => setHover(+e.target.dataset.i), []);
  const onMouseLeave = useCallback(() => setHover(0), []);

  const [ready, setReady] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setReady(true), 1500);
    return () => clearTimeout(timeout);
  }, []);

  const [enter, setEnter] = useState(false);
  const onEntered = useCallback(() => setEnter(true), []);

  const [exit, setExit] = useState(false);
  const onExit = useCallback(() => setExit(true), []);
  const history = useHistory();
  const onExited = useCallback(() => history.push('/read/avocado/something'), [history]);

  if (!ready) {
    return null;
  }

  return (
    <Box width={1200} height="100vh" position="relative" margin="auto">
      <Zoom in={!exit} timeout={1000} onExited={onExited}>
        <Box position="absolute" top={40} left={40} zIndex={1}>
          <Fade in={hover !== 2} timeout={{ enter: 1000, exit: 500 }}>
            <Box width={480} height={300} position="relative" overflow="hidden">
              <SvgExclude
                width={480}
                height={300}
                viewBox="0 0 480 300"
                xmlns="http://www.w3.org/2000/svg"
                transform={hover === 1 ? 'scale(4)' : 'scale(1)'}
              >
                <path
                  d="M281.827 70.7558C329.68 91.769 349.819 147.022 326.808 194.167C322.159 203.693 316.103 212.16 309.005 219.432C300.351 228.298 294.647 221.949 279.052 240.464C197.542 151.739 224.895 253.073 198.5 241.483C163.554 166.588 154.142 207.884 147.201 182.565C141.559 161.983 143.201 139.212 153.519 118.071C165.597 93.3249 191.921 108.469 211.849 67.6385C222.947 44.9001 259.093 60.7725 281.827 70.7558Z"
                  fill="transparent"
                  data-i={1}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M480 0H0V300H480V0ZM326.808 194.167C349.819 147.022 329.68 91.769 281.827 70.7558C259.093 60.7725 222.947 44.9001 211.849 67.6385C200.644 90.5959 187.417 95.8576 175.838 100.464C166.823 104.05 158.806 107.239 153.519 118.071C143.201 139.212 141.559 161.983 147.201 182.565C149.767 191.926 152.671 192.181 157.078 192.569C164.591 193.231 176.474 194.277 198.5 241.483C207.611 245.484 210.318 236.028 213.309 225.58C218.983 205.76 225.679 182.367 279.052 240.464C288.41 229.354 294.207 227.197 299.216 225.332C302.555 224.09 305.544 222.978 309.005 219.432C316.103 212.16 322.159 203.693 326.808 194.167Z" fill={PAGE_COLOR} />
              </SvgExclude>
              <Monospace>
                I noticed that in a lot of cases my perception of art works accordingly to the Feldman’s Model of Art Criticism (available in many of his books from the late 1960’s and early 70’s). I start with asking myself questions like “What do I see?” and finish with evaluating the work of art based on the gathered information. Because of my diary I realized that before studying in the art school my perception worked differently; it didn’t have any order or analysis in it and was based purely on my personal preferences. Our discussion classes sharpened my knowledge and built a more careful and intellectual approach to looking at art.
              </Monospace>
              <br />
              <Monospace>
                Studying art makes you look at art differently, and in many cases if you look at art differently your perception of it will also be different.
              </Monospace>
            </Box>
          </Fade>
        </Box>
      </Zoom>

      <Zoom in={!exit} timeout={2000} onEntered={onEntered}>
        <Box position="absolute" bottom={40} right={40}>
          <Grow in={enter}>
            <Box position="absolute" right={100} bottom="100%">
              <Next width="80" height="40" viewBox="0 0 80 40" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onExit}>
                <path d="M1.91752 19.0603C22.6069 19.1742 43.3195 19.0435 63.9993 19.4802C68.6721 19.5789 68.5986 18.9924 66.4763 21.719C64.837 23.8252 54.9513 38.4753 54.7934 37.1781C53.8448 29.3833 60.1652 25.5057 62.6178 20.0565C65.0386 14.6778 54.1947 8.40258 52.3219 7.41398C51.1779 6.81006 34.0457 -0.106413 41.4463 5.42037C52.4912 13.6687 65.5404 16.9294 77.9985 20.0892" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              </Next>
            </Box>
          </Grow>
          <Fade in>
            <Box width={800} height={425} position="relative" overflow="hidden">
              <SvgExclude
                width={800}
                height={425}
                viewBox="0 0 800 425"
                xmlns="http://www.w3.org/2000/svg"
                transitionDuration={1000}
                transform={hover === 2 ? 'scale(8)' : 'scale(1)'}
              >
                <path
                  d="M255.095 154.366L213 174.479V189.803L223.524 208V230.986L235.667 240.563L281.81 235.774L315 230.986L335.238 235.774C337.127 241.521 342.038 255.121 346.571 263.549C351.105 271.977 359.254 273.446 362.762 273.126L377.333 257.802L414.571 235.774C421.048 246.948 434 270.062 434 273.126C434 276.957 441.286 309.521 442.905 318.14C444.2 325.036 461.794 344.638 470.429 353.577C473.667 353.896 483.867 354.343 498.762 353.577C513.657 352.811 535.73 338.572 544.905 331.549C548.413 321.652 555.429 300.135 555.429 293.239V257.802L571.619 200.338C575.505 186.546 583.492 171.605 587 165.859V139.042C581.603 125.314 570 96.5183 566.762 91.1549C562.714 84.4507 536 84.4507 527.095 84.4507C518.19 84.4507 492.286 99.7746 486.619 106.479C480.952 113.183 471.238 131.38 465.571 144.789C459.905 158.197 444.524 165.859 434 165.859H414.571C408.905 159.474 396.438 145.172 391.905 139.042C386.238 131.38 392.714 113.183 391.905 106.479C391.257 101.115 372.206 81.2582 362.762 72H315C305.016 85.7277 284.4 114.524 281.81 119.887C279.219 125.251 262.921 145.108 255.095 154.366Z"
                  fill="transparent"
                  data-i={2}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M800 0H0V425H800V0ZM213 174.479L255.095 154.366C262.921 145.108 279.219 125.251 281.81 119.887C284.4 114.524 305.016 85.7277 315 72H362.762C372.206 81.2582 391.257 101.115 391.905 106.479C392.182 108.777 391.604 112.426 390.951 116.539C389.701 124.422 388.181 134.007 391.905 139.042C396.438 145.172 408.905 159.474 414.571 165.859H434C444.524 165.859 459.905 158.197 465.571 144.789C471.238 131.38 480.952 113.183 486.619 106.479C492.286 99.7746 518.19 84.4507 527.095 84.4507C536 84.4507 562.714 84.4507 566.762 91.1549C570 96.5183 581.603 125.314 587 139.042V165.859C583.492 171.605 575.505 186.546 571.619 200.338L555.429 257.802V293.239C555.429 300.135 548.413 321.652 544.905 331.549C535.73 338.572 513.657 352.811 498.762 353.577C483.867 354.343 473.667 353.896 470.429 353.577C461.794 344.638 444.2 325.036 442.905 318.14C442.365 315.267 441.196 309.734 439.877 303.491C437.238 291.005 434 275.68 434 273.126C434 270.062 421.048 246.948 414.571 235.774L377.333 257.802L362.762 273.126C359.254 273.446 351.105 271.977 346.571 263.549C342.038 255.121 337.127 241.521 335.238 235.774L315 230.986L281.81 235.774L235.667 240.563L223.524 230.986V208L213 189.803V174.479Z" fill={PAGE_COLOR} />
              </SvgExclude>

              <Monospace>
                Feldman’s Model of Art Criticism
              </Monospace><br />
              <Monospace>
                The first step is description. The viewer makes observations about what they see without connecting it to the personal experiences or preferences. Objective statement of facts that includes description of the depicted elements in the work of art and the subject matter. What is the title of the work? What do you see?
              </Monospace><br />
              <Monospace>
                Analysis. At this step the viewer expresses their ideas about the message of the artwork. Analysis pretty much relies on the knowledge of the individual. What kind of artistic tools were used to construct this work (lines, movements, etc.), what are the focal points and similarities, who are the depicted characters and so on, are described at this stage.
              </Monospace><br />
              <Monospace>
                The third step of the critiquing process is interpretation. What does this work of art mean to the particular viewer? What kind of feelings or emotions does it arise? This is the time when the personal relation and experiences are dominant.
              </Monospace><br />
              <Monospace>
                Judgment is the last step in the art critique process according to Feldman (1994). Collecting all the data from the previous steps, the observer makes their opinion about the artwork clear. What qualities of the work make you feel it is a success or failure? What criteria can you list to help others judge this work? How original is the work? Why do you feel this work is original or not original? (Jones, 2008)
              </Monospace><br />
            </Box>
          </Fade>
        </Box>
      </Zoom>
    </Box>
  );
};

export default Feldman;
