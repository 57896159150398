import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import SpeechBubble from '../../../SpeechBubble/SpeechBubble';

const SpeechBubbleBox = styled.div`
  cursor: pointer;
  margin-top: auto;
  margin-right: ${props => props.theme.spacing(4)}px;
  margin-bottom: ${props => props.theme.spacing(4)}px;
  margin-left: auto;
`;

const SubAvocado1 = () => {
  const history = useHistory();

  const [inState, setInState] = useState(true);
  const onNext = () => {
    setInState(false);
    setTimeout(() => {
      history.push('/read/avocado/2');
    }, 1100);
  };

  return (
    <SpeechBubbleBox onClick={onNext}>
      <SpeechBubble in={inState} rtl maxWidth={600} timeout={1000}>
        “I have always wanted to become a cartoonist. Instead, I ended up making comics with art”
      </SpeechBubble>
    </SpeechBubbleBox>
  );
};

export default SubAvocado1;
