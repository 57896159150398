import React from 'react';
import Carousel, { ModalGateway, Modal } from 'react-images';

const defs = {
  1: [{ src: '/images/grid/1.jpg' },
      { src: '/images/grid/1_1.jpg' },
      { src: '/images/grid/1_2.jpg' }]
      .map(arr => ({ ...arr, caption: 'The Starting Point, 2017, 42 x 60 cm, paper and wood assemblage' })),
  2: [{ src: '/images/grid/2.jpg', caption: 'Untitled, 2018, various sizes, acrylic on fabric' }],
  3: [{ src: '/images/grid/3.jpg', caption: 'Untitled, 2018, 30 x 30 cm, wood, fabric and metal assemblage' }],
  4: [{ src: '/images/grid/4.jpg', caption: 'Untitled, 2018, 30 x 30 cm, wood, fabric and metal assemblage' }],
  5: [{ src: '/images/grid/5.jpg', },
      { src: '/images/grid/5_1.jpg' },
      { src: '/images/grid/5_2.jpg' }]
      .map(arr => ({ ...arr, caption: 'Untitled, 2018, 40 x 100 cm, wood and fabric construction' })),
  6: [{ src: '/images/grid/6.jpg', caption: 'Foldable Painting, 2018, 130 x 200 cm, acrylic on fabric' }],
  7: [{ src: '/images/grid/7.jpg' },
      { src: '/images/grid/7_1.jpg' }]
      .map(arr => ({ ...arr, caption: 'Simple And Complex, 2018, 30 x 40 cm, acrylic on fabric, charmeuse' })),
};

const GridCarousel = ({ id, onClose }) => (
  <ModalGateway>
    {id && (
      <Modal onClose={onClose}>
        <Carousel views={defs[id]} />
      </Modal>
    )}
  </ModalGateway>
);

export default GridCarousel;
