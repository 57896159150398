import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Home from './Subpages/Home';
import Desktop from './Subpages/Desktop';
import ArtInUkraine from './Subpages/ArtInUkraine';
import { PotatoPageProvider } from './PotatoPageContext';

const Page6Index = () => {
  const match = useRouteMatch();
  return (
    <PotatoPageProvider>
      <Switch>
        <Route path={`${match.url}/desktop`}>
          <Desktop />
        </Route>
        <Route path={`${match.url}/art-in-ukraine`}>
          <ArtInUkraine />
        </Route>
        <Route>
          <Home />
        </Route>
      </Switch>
    </PotatoPageProvider>
  );
};

export default Page6Index;
