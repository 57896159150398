import React, { useState, useRef, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import Fade from '@material-ui/core/Fade';
import styled, { keyframes } from 'styled-components';
import { useHistory, Link } from 'react-router-dom';
import Monospace from '../../../../Monospace';

const draw = keyframes`
  0% {
    stroke-dashoffset: -1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
`;

const DrawingPath = styled.path`
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: ${draw} 4s ease-in-out forwards;
`;

const NextWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  left: 100%;
  margin-left: 60px;

  svg {
    transition: transform 0.2s ease;

    &:hover {
      transform: scale3d(1.2, 1.2, 1);
    }
  }
`;

const Parsons = () => {
  const [everythingIn, setEverythingIn] = useState(false);
  const everythingInTimeout = useRef();
  const onEverythingIn = () => {
    everythingInTimeout.current = setTimeout(() => setEverythingIn(true), 3000);
  };
  useEffect(() => () => clearTimeout(everythingInTimeout.current), []);

  const [out, setOut] = useState(false);
  const onOut = () => setOut(true);
  const history = useHistory();
  const onOuted = () => history.push('/read/pinky/grid');

  return (
    <Grow in={!out} timeout={1000} on onExited={onOuted}>
      <Box margin="auto">
        <Box margin={2} position="relative" width={1050} display="flex" alignItems="center" justifyContent="center">
          <Box style={{ transform: 'scale3d(1.5, 1, 1) translateY(10px)' }}>
            <svg width="763" height="561" viewBox="0 0 763 561" fill="none" xmlns="http://www.w3.org/2000/svg">
              <DrawingPath pathLength={1000} d="M24 33C33.6746 23.4306 41.0811 17.5555 54.3889 14.1111C67.1843 10.7994 80.4205 10.9153 93.4444 9.00001C136.266 2.70272 178.749 1.00001 222 1.00001C258.916 1.00001 295.964 0.482072 332.778 3.66668C374.943 7.31417 416.549 9.00001 458.889 9.00001C500.61 9.00001 542.293 8.90595 584 8.22223C600.813 7.94661 617.63 8.00001 634.444 8.00001C649.487 8.00001 664.94 7.06998 679.944 8.55557C708.82 11.4145 743.03 15.6723 756.778 44.5556C762.793 57.1924 762.665 74.7713 761.833 88.5C761.059 101.269 756.683 113.496 755.444 126.222C753.265 148.622 755.191 171.404 753.444 193.889C751.07 224.463 753 255.888 753 286.556C753 318.222 753 349.889 753 381.556C753 408.547 754.17 434.983 749.222 461.556C746.408 476.668 743.926 493.61 733.222 505.556C715.283 525.575 687.914 530.012 663 533.222C643.345 535.754 623.611 540.132 603.889 541.722C593.904 542.527 583.672 542 573.667 542C547.148 542 520.63 542 494.111 542C453.985 542 413.865 546.798 373.889 549.778C338.338 552.428 302.88 552.36 267.389 556C228.64 559.974 188.376 562.665 149.722 556.611C132.537 553.919 115.376 552.021 98.3333 548.389C84.4264 545.425 72.3172 536.68 58.5 533.056C39.4602 528.061 16.0724 519.882 8.94443 499.389C-2.64154 466.079 1.99999 427.345 1.99999 392.389C1.99999 358.333 1.99999 324.278 1.99999 290.222C1.99999 247.519 1.99999 204.815 1.99999 162.111C1.99999 134.63 0.826852 107.451 6.55554 80.4445C7.82873 74.4423 8.59227 68.4828 9.99999 62.5C10.8773 58.7715 11.9035 53.3041 13.5555 50C16.3411 44.4288 18.6055 37.3945 23 33" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </Box>
          <Fade in timeout={4000} onEntered={onEverythingIn}>
            <Box position="absolute">
              <Monospace>
                It is clear that Michael Parsons sets up a hierarchical tower of perception of an artwork and aesthetic judgement in his “How We Understand Art: A Cognitive Developmental Account of Aesthetic Experience”. The author himself claims that the stages are ordered in terms of adequacy, where the first two are connected to the age of the observer and the later ones are more or less dependent on the experience with art in a Western culture. Sadly, what I lack in this research is the presence of the numerical or statistical proof of the statement. In my eyes, Parsons says the point but does not provide enough argumentation. Also, it was not clear why he chose the paintings that he chose, except for the nowhere explained “reasonably varied” set. It is not clear whether or not a person can skip the stage, or if other factors such as cultural background and the influence of the art market, for instance, can change the way a person perceives an artwork. Parsons, however, mentions that individuals in China and Russia would not talk about art in these ways he describes because their tradition does not teach them to talk in these ways. Such an exclusion on the global stage makes his research very one sided, focused only on the Western society. In this cases I prefer McFee (1978), who has questioned whether aesthetics can be universal, and Feldman with his “Developmental psychology and art education: Two fields at the crossroads”(1987), where he has developed the distinction between universal and cultural domains and has argued that our understanding of the ats is purely based on the cultural principles.
                <br /><br />
                The other thing that bothered me was the importance of the verbal aspect in Parson’s research. Simply because I am not sure about the capability of the language to describe all kinds of sides to your perception. In
                &nbsp;
                <Link to="/read/avocado/diary">my diary</Link>
                &nbsp;
                I was trying to record my thinking process in order to understand perception, but I realized that there are a lot of things that I cannot grasp by just verbalizing it and turning it into words. In my opinion, this is another gap in Parson’s research, because a person who might perceive an artwork on a lower stage might be able to talk about it in a stage 5 manner without true understanding, and vice versa.
                <br /><br />
                I also would have liked if “How We Understand Art: A Cognitive Developmental Account of Aesthetic Experience” had raised questions like ‘does this apply to all aesthetic objects, or maybe only to the visual arts, or maybe only to the paintings?’. As of my general impression, the title of the book would have made more sense to me if it was called “How Westerners Understand Paintings”.
              </Monospace>
            </Box>
          </Fade>
          <Fade in={everythingIn} timeout={4000}>
            <NextWrapper onClick={onOut}>
              {everythingIn && (
                <svg width="48" height="35" viewBox="0 0 48 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <DrawingPath pathLength={1000} d="M1.24465 2.11163C1.72933 7.28071 2.46307 12.4101 3.15002 17.5382C3.6171 21.025 4.45132 27.1775 4.99457 31.0113C5.08526 31.6513 5.21641 32.8575 5.42024 33.6744C5.69617 34.7802 7.85367 32.7294 8.10262 32.5531C15.6234 27.2247 23.5032 22.8001 31.3792 18.4493C35.9566 15.9206 40.5456 13.4234 45.0681 10.7316C45.4 10.534 46.307 10.0037 46.7505 9.68914C46.8993 9.58353 46.9441 9.59631 46.9667 9.4351C47.2298 7.55884 45.8847 7.23551 44.7167 6.96474C42.5193 6.45531 40.2489 6.73765 38.0277 6.64938C35.2977 6.54089 32.5639 6.4576 29.8386 6.21137C24.5304 5.73177 19.2364 4.41991 13.9674 3.46945C10.2512 2.79911 6.54209 2.0492 2.83246 1.32322C2.40989 1.24052 2.10186 1.00785 1.67032 1.00785C1.18212 1.00785 0.980914 0.885854 1.00142 1.60354C1.01772 2.1743 1.24465 2.60037 1.24465 3.21541" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              )}
              </NextWrapper>
          </Fade>
        </Box>
      </Box>
    </Grow>
  );
};

export default Parsons;
