import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import Zoom from '@material-ui/core/Zoom';
import styled, { keyframes, css, createGlobalStyle } from 'styled-components';
import { useHistory } from 'react-router-dom';
import Avocado from './Avocado';
import Potato from './Potato';
import Pinky from './Pinky';

const DEVOUR_DURATION = 1000;

const Devoured = createGlobalStyle`
  body {
    transition: ${props => props.theme.transitions.create(['background-color'], { duration: DEVOUR_DURATION })};
    background-color: ${props => props.theme.palette[props.color]} !important;
  }
`;

const pulse = keyframes`
  0% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
`;

const buttonCss = css`
  pointer-events: none;
`;

const StyledAvocado = styled(Avocado)`
  ${buttonCss}
  animation: ${pulse} 1s infinite;
`;

const StyledPotato = styled(Potato)`
  ${buttonCss}
  animation: ${pulse} 2s infinite;
`;

const StyledPinky = styled(Pinky)`
  ${buttonCss}
  animation: ${pulse} 1.5s infinite;
`;

const ButtonWrapper = styled.div`
  transition: transform 0.2s ease;

  &:hover {
    cursor: pointer;
    transform: scale3d(1.2, 1.2, 1);
  }

  &[data-devoured="true"] {
    transition: transform ${DEVOUR_DURATION}ms ease;
    transform: scale3d(100, 100, 1);
    z-index: 10;
    position: relative;

    svg path {
      stroke: none;
    }
  }
`;

const BASE_DELAY = 2000;

const Buttons = props => {
  const history = useHistory();

  const [avocadoIn, setAvocadoIn] = useState(false);
  const [potatoIn, setPotatoIn] = useState(false);
  const [pinkyIn, setPinkyIn] = useState(false);

  useEffect(() => {
    const avocadoTimeout = setTimeout(() => {
      setAvocadoIn(true);
    }, BASE_DELAY + 250);
    const potatoTimeout = setTimeout(() => {
      setPotatoIn(true);
    }, BASE_DELAY + 0);
    const pinkyTimeout = setTimeout(() => {
      setPinkyIn(true);
    }, BASE_DELAY + 500);
    return () => {
      clearTimeout(avocadoTimeout);
      clearTimeout(potatoTimeout);
      clearTimeout(pinkyTimeout);
    };
  }, []);

  const [devour, setDevour] = useState('');
  const onDevour = event => {
    setDevour(event.target.dataset.devour);
  };
  useEffect(() => {
    if (!devour) {
      return undefined;
    }
    const timeout = setTimeout(() => {
      history.push(`/read/${devour}`);
    }, DEVOUR_DURATION);
    return () => {
      clearTimeout(timeout);
    };
  }, [devour, history]);

  return (
    <Box display="flex" {...props}>
      <Devoured color={devour} />
      <Grow in={avocadoIn}>
        <Box>
          <ButtonWrapper
            data-devour="avocado"
            data-devoured={devour === 'avocado'}
            onClick={onDevour}
          >
            <StyledAvocado />
          </ButtonWrapper>
        </Box>
      </Grow>
      <Zoom in={potatoIn}>
        <Box marginTop={5} marginLeft={6}>
          <ButtonWrapper
            data-devour="potato"
            data-devoured={devour === 'potato'}
            onClick={onDevour}
          >
            <StyledPotato />
          </ButtonWrapper>
        </Box>
      </Zoom>
      <Grow in={pinkyIn}>
        <Box marginTop={-3} marginLeft={2}>
          <ButtonWrapper
            data-devour="pinky"
            data-devoured={devour === 'pinky'}
            onClick={onDevour}
          >
            <StyledPinky />
          </ButtonWrapper>
        </Box>
      </Grow>
    </Box>
  );
};

export default Buttons;
