import React from 'react';
import Box from '@material-ui/core/Box';
import text from './text.svg';
import lines from './lines.svg';
import text_date from './text_date.svg';
import black from './black.png';
import pm from './pm.png';

const Section10 = () => {
  return (
    <Box position="relative" marginTop="2928px">
      <Box className="rlx" position="absolute" left={169} top={310}>
        <img src={text_date} />
      </Box>
      <Box position="absolute" left={-32} top={-36}>
        <img src={lines} />
      </Box>
      <Box className="rlx" position="absolute" left={580} top={40}>
        <img src={pm} width={210} />
      </Box>
      <Box className="rlx" data-rellax-speed={0.5} position="absolute" left={568} top={0}>
        <img src={black} width={266} />
      </Box>
      <Box position="absolute" left={32}>
        <img src={text} />
      </Box>
    </Box>
  );
};

export default Section10;
