import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const Potato = props => {
  const theme = useContext(ThemeContext);
  const potatoColor = theme.palette.potato;
  return (
    <svg width="42" height="62" viewBox="0 0 42 62" {...props}>
      <path shapeRendering="geometricPrecision" d="M1.5 25.5C1.9 8.3 17 2.33333 24.5 1.5C24.5 1.5 31 -1.19209e-07 38 9.5C45 19 36.5 38 36.5 38C36 41 36.5 61 16.5 61C3 61 1 47 1.5 25.5Z" fill={potatoColor} stroke="black" />
    </svg>
  );
};

export default Potato;
