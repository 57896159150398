import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ListenDownIcon = props => (
  <SvgIcon {...props}>
    <path d="M3 9V15H7L12 20V4L7 9H3ZM16.5 12C16.5 10.23 15.48 8.71 14 7.97V16.02C15.48 15.29 16.5 13.77 16.5 12Z" />
  </SvgIcon>
);

export default ListenDownIcon;
