import React, { useContext, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Grow from '@material-ui/core/Grow';
import { ThemeContext } from 'styled-components';
import Collapse from './Collapse';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Monospace from '../../../../../../Monospace';
import { PotatoPageContext } from '../../../../PotatoPageContext';

const Wrapper = styled.div`
  width: 640px;
  background: #F4B4FE;
  border: 2px solid #000000;
  margin-right: 100px;
  border-radius: 2px;

  > * {
    opacity: ${props => props.visible ? 1 : 0};
  }
`;

const Hat = styled.img`
  float: right;
  clip-path: url(/images/blueberry_hat.svg#hat);
  shape-outside: url(/images/blueberry_hat.svg#hat);
  cursor: pointer;
  transition: ${props => props.theme.transitions.create(['filter'])};

  &:hover {
    filter: brightness(1.2);
  }
`;

const VerText = styled.div`
  position: absolute;
  font-size: 48px;
  right: 58px;
  top: 220px;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  color: rgba(255, 255, 255, 0.8);
  height: 700px;
`;

const Blueberry = () => {
  const pageCtx = useContext(PotatoPageContext);
  const theme = useContext(ThemeContext);
  useEffect(() => pageCtx.setBgColor(theme.palette.blueberry), []);

  const [wrapperCollapsed, setWrapperCollapsed] = useState(false);
  const onWrapperCollapsed = () => setWrapperCollapsed(true);
  const MaybeWrapperCollapse = wrapperCollapsed ? React.Fragment : Collapse;
  const maybeWrapperCollapseProps = wrapperCollapsed ? undefined : { in: true, onEnter: onWrapperCollapsed, timeout: 2000 };

  const [verTextCollapsed, setVerTextCollapsed] = useState(false);
  const onVerTextCollapsed = () => setVerTextCollapsed(true);

  const [exit, setExit] = useState(false);
  const onExit = () => setExit(true);
  const history = useHistory();
  const onExited = () => history.push('/read/potato/art-in-ukraine/blueberry/boomer');

  return (
    <Grow in={!exit} timeout={{ enter: 0, exit: 1000 }} onExited={onExited}>
      <Box marginX="auto" marginTop="100px" marginBottom="50px" position="relative">
        {wrapperCollapsed && (
          <Collapse in timeout={1000} onEnter={onVerTextCollapsed}>
            <VerText>
              <Box height="800px">
                DO YOU REALLY SEE WHAT I SEE?
              </Box>
            </VerText>
          </Collapse>
        )}
        <MaybeWrapperCollapse {...maybeWrapperCollapseProps}>
          <Wrapper>
            <Fade appear in={verTextCollapsed} timeout={1000}>
              <Box paddingX={3} paddingY={4}>
                <Monospace>
                  <Box marginTop="-103px" marginRight="-64px" component="span" display="block">
                    <Hat src="/images/blueberry_hat.svg" onClick={onExit} />
                  </Box>
                  <Box height="103px" component="span" display="block" />
                  The cultural background is one of the crucial factors that
                  influences human psychological processes and, therefore,
                  perception. It is not only about the way of judging the works of
                  art but also handling the visual information based on the
                  cognitive processes. For instance, according to the previous
                  research led by Kyoto University (published in Cognitive Science
                  journal), East Asians and Westerners perceive and think about
                  the world in very different ways. It is visible in their
                  philosophies, style of living, and is dependent on various
                  differences in cognitive domains such as attention,
                  categorization, casual attribution (Choi, Koo, & Choi, 2007), or perception of colours, depth, susceptibility to visual illusions
                  and perception of faces (Berry, Poortinga, Breugelmans, Chasiotis
                  & Sam, 2012). Bearing in mind these criteria, Westerners tend
                  to pay attention to some focal object (salient object that is big
                  in size, colourful or fast moving) focusing on its characteristics and attributes. This is known as an analytical processing. While East Asians are more likely to look at a broader perceptual field, noticing relationship and changes as a whole, which is known as a ‘holistic’ processing.
                </Monospace>
                <br />
                <Monospace>
                  This is an example of one of the differences in psychological receiving of the information on a global scale. If people perceive things differently, do they really see them in the same way?
                </Monospace>
                <br />
                <Monospace>
                  As I explained earlier, the distinction between visual perception (to be precise, art appreciation) in Ukraine and Netherlands was very clear to me. Although, I support Kant’s subjectivist view of aesthetics that says that the beauty lies in the eyes of a beholder rather than in the actual object, I do think that this “subjective” opinion comes from a culturally and historically influenced umbrella.
                </Monospace>
                <br />
                <Monospace>
                  Through a whole history Ukraine was always teared apart and divided under control of different countries. Mongolia, Poland, Lithuania, Russian Empire. People were trying to survive under the domination, which led to a very slow progression of art. Writers and painters who wanted to bring novelty were banned and executed. In 1921 Ukraine became a part of the Soviet Union. Everything was collectivized, following communism philosophy, a person as an individual didn’t exist as it became a part of a collective society. As a result of quick establishing of communism in Ukraine Karl Marx’s slogan “From each according to his ability, to each according to his needs” was transformed into a “He, who does not work, neither shall he eat”. With that, art was considered to be a sign of human vices and idleness. The natural desire of a soul to create was viewed as a parasitical symptom. The government, however, learned how to use art as a weapon to sublime established regime. Everything that was produced outside of the communist principles was prohibited.
                </Monospace>
                <br />
                <Monospace>
                  People in Soviet Union were forced into living their usual life, wearing the same clothes as everyone and following the society norms of existing. They were never, never allowed to stand out. The same counted for the art scene. Nevertheless, parallel to the big ideological USSR art the неофициальное искусство СССР was growing. They were underground art movements that due to the political and ideological censorship were squeezed out of public artist life by official authorities. Kazimir Malevich had to escape to Berlin, where he left a kind of a time capsule with all his manuscripts and documents with a note “In case of my death or hopeless imprisonment…”, referencing what might’ve awaited for him in the Soviet Union.
                </Monospace>
                <br />
                <Monospace>
                  As a result of history, Ukrainian contemporary art scene is awakening with a big post-traumatic syndrome from the times of the communist power. Witnesses, the older generations are the rulers of today’s art progression. They teach students how to appreciate the academic ways of making, strongly disliking possible freshness and innovation that younger generation can bring. Other civilians simply follow established opinions about art, devaluing it as something not important because there are more necessary things in your life that you should pay attention to such as working and gaining money to survive in order to live a normal and usual life. The government that doesn’t invest in art and culture doesn’t help either.
                </Monospace>

                <Box display="flex" alignItems="flex-end" justifyContent="space-evenly" marginY={2}>
                  <Box textAlign="center">
                    <img src="/images/blueberry_most.png" />
                    <Box height={4} />
                    <Monospace>
                      The Ukraine's Most Wanted:
                      <br />
                      (television size)
                    </Monospace>
                  </Box>
                  <Box textAlign="center">
                    <img src="/images/blueberry_least.png" />
                    <Box height={4} />
                    <Monospace>
                      The Ukraine's Least Wanted:
                      <br />
                      (refrigerator size)
                    </Monospace>
                  </Box>
                </Box>

                <Monospace>
                  Russian artists Vitaly Komar & Alex Melamid created a project in which they surveyed people from different countries about their preferences in art. The result was reflected in The Most Wanted and The Least Wanted paintings.
                </Monospace>
                <br />
                <Monospace>
                  “In a way it was a traditional idea, because a faith in numbers is fundamental to people, starting with Plato's idea of a world which is based on numbers. In ancient Greece, when sculptors wanted to create an ideal human body they measured the most beautiful men and women and then made an average measurement, and that's how they described the ideal of beauty and how the most beautiful sculpture was created. In a way, this is the same thing; in principle, it's nothing new. It's interesting: we believe in numbers, and numbers never lie. Numbers are innocent. It's absolutely true data. It doesn't say anything about personalities, but it says something more about ideals, and about how this world functions. That's really the truth, as much as we can get to the truth. Truth is a number”
                  <br />
                  <Box textAlign="right" component="span" display="block">-	Alex Melamid</Box>
                </Monospace>
                <br />
                <Monospace>
                  The data was collected in 1995 for an exhibition at the National Museum of Art in Kiev. Nowadays, I see the tendency of the masses still preferring the skillfully made landscape images to some abstract shapes and forms on canvases. However, the generations change and there is still hope that new faces who will guide the Ukrainian art scene in the future will bring improvement and important reconsidering of the novelty in perception of art.
                </Monospace>
              </Box>
            </Fade>
          </Wrapper>
        </MaybeWrapperCollapse>
      </Box>
    </Grow>
  );
};

export default Blueberry;
