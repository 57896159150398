import React, { useRef, useState } from 'react';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import styled, { createGlobalStyle } from 'styled-components';
import Moveable from "react-moveable";
import Monospace from '../Monospace';
import { useHistory } from 'react-router-dom';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #FAF8F8 !important;
  }

  .moveable-control-box {
    display: none !important;
  }
`;

const StyledImg = styled.img`
  position: ${props => props.position || 'absolute'};
  top: ${props => props.top}px;
  left: ${props => props.left}px;
  cursor: grab;
  transition: ${props => props.theme.transitions.create(['filter'])};

  &:hover {
    filter: brightness(1.4);
  }
`;

const MoveableImg = props => {
  const [ref, setRef] = useState();
  const translate = useRef([0, 0]);

  return (
    <>
      {ref && (
        <Moveable
          target={ref}
          origin={false}
          edge={false}
          draggable
          throttleDrag={0}
          onDragStart={({ set }) => {
            set(translate.current);
          }}
          onDrag={({ beforeTranslate }) => {
            translate.current = beforeTranslate;
            ref.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px)`;
          }}
        />
      )}
      <StyledImg ref={setRef} {...props} />
    </>
  );
};

export default function Exercise() {
  const [exit, setExit] = useState(false);
  const onExit = () => setExit(true);
  const history = useHistory();
  const onExited = () => history.push('/read/teotr');

  return (
    <Fade in={!exit} timeout={1000} onExited={onExited}>
      <Box width="100vw" height="100vh" overflow="hidden" display="flex" alignItems="center" justifyContent="center">
        <Box width={900} height={600} position="relative" padding={2} margin="auto">
          <GlobalStyle />
          <Box width={300} marginTop={1} marginLeft={-2}>
            <Monospace fontSize={12}>
              One of the exercises I did in Minerva was to let people deconstruct my work and play with it by creating different combinations of the objects. It was a good way of opening myself to the new solutions and possibilities: what the audience considered to be a fun game, for me was a tool to view my work from various angles and unfamiliar perspectives.
            </Monospace>
            <Box marginTop={3} borderRadius={4} overflow="hidden" display="flex">
              <video autoPlay muted loop width={300}>
                <source src="/video/exercise.mp4" type="video/mp4" />
              </video>
            </Box>
          </Box>
          <Box position="absolute" left={262} top={374} display="flex" alignItems="center" justifyContent="center">
            <Box zIndex={1}>
              <MoveableImg src="/images/exercise/8.png" alt="" width={120} position="initial" />
            </Box>
            <Box position="absolute" width={2} height={2} marginTop={2}>
              <Box height="100vh" width={2} bgcolor="black" position="absolute" bottom={0} />
              <Box
                width="100vw"
                height={2}
                bgcolor="black"
                position="absolute"
                left={0}
                style={{ transformOrigin: 'left', transform: 'rotate(1deg)' }}
              />
              <Box
                width="100vw"
                height={2}
                bgcolor="black"
                position="absolute"
                right={0}
                style={{ transformOrigin: 'right', transform: 'rotate(-24.8deg)' }}
              />
            </Box>
          </Box>
          <MoveableImg src="/images/exercise/14.png" width={114} left={170} top={320} />
          <MoveableImg src="/images/exercise/9.png" width={108} left={104} top={459} />
          <MoveableImg src="/images/exercise/20.png" width={48} left={65} top={477} />
          <MoveableImg src="/images/exercise/4.png" width={37} left={47} top={370} />
          <MoveableImg src="/images/exercise/11.png" width={34} left={245} top={355} />
          <MoveableImg src="/images/exercise/13.png" width={117} left={486} top={463} />
          <MoveableImg src="/images/exercise/3.png" width={103} left={736} top={466} />
          <MoveableImg src="/images/exercise/1.png" width={170} left={590} top={215} />
          <MoveableImg src="/images/exercise/5.png" width={56} left={731} top={465} />
          <MoveableImg src="/images/exercise/16.png" width={130} left={403} top={449} />
          <MoveableImg src="/images/exercise/17.png" width={126} left={351} top={523} />
          <MoveableImg src="/images/exercise/18.png" width={70} left={603} top={342} />
          <MoveableImg src="/images/exercise/23.png" width={32} left={597} top={77} />
          <MoveableImg src="/images/exercise/22.png" width={22} left={816} top={112} />
          <MoveableImg src="/images/exercise/15.png" width={80} left={789} top={306} />
          <MoveableImg src="/images/exercise/5-1.png" width={70} left={770} top={350} />
          <MoveableImg src="/images/exercise/7.png" width={60} left={636} top={241} />
          <MoveableImg src="/images/exercise/19.png" width={110} left={653} top={90} />
          <MoveableImg src="/images/exercise/12.png" width={56} left={520} top={304} />
          <MoveableImg src="/images/exercise/21.png" width={60} left={382} top={294} />
          <MoveableImg src="/images/exercise/2.png" width={219} left={360} top={50} />
          <MoveableImg src="/images/exercise/10.png" width={97} left={453} top={88} />
          <StyledImg src="/images/exercise/next.png" width={24} left={851} top={20} onClick={onExit} />
        </Box>
      </Box>
    </Fade>
  );
}
