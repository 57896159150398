import React from 'react';
import Box from '@material-ui/core/Box';
import Window, { View } from './Window';

const Notepad = ({ title = 'Notepad', icon = '/images/win98_notepad.png', children, ...restProps }) => {
  return (
    <Window title={title} icon={icon} {...restProps}>
      <View>
        <Box padding={1}>
          {children}
        </Box>
      </View>
    </Window>
  );
};

export default Notepad;
