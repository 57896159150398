import React from 'react';

export const philMuseum = {
  content: `Contemporary art is a site that no one needs. It is not a part of scientific research, there are no art history classes in Ukraine that could explain the connection between the Renaissance and contemporary artists. This is the reason of the lack of museums of modern and contemporary art. The government is not interested in investing in art, it does not purchase new artworks in museums and so on.`,
  source: `Elena Chervonyk, the curator at the department of contemporary art in Philadelphia Museum of Art.`,
  sourceHref: 'https://lb.ua/culture/2017/10/11/379013_sovremennoe_iskusstvo_ukraine.html',
};

export const artMargins = {
  content: `Contemporary art arrived in Ukraine as an imported concept. Young Ukrainian artists generally receive very traditional training that does not incorporate current art world trends. Most of the Ukrainian public has little experience viewing contemporary art and lacks the background knowledge needed to contextualize certain pieces. Art-related education and communication have become invaluable practices both for artists trying to broaden their knowledge and for cultivating the potential audience for new artwork, as well as a way to invigorate local art practices still heavily influenced by formal academicism. By acquainting local art practitioners and viewers with the history, international tendencies, theories and practices of contemporary art, members of the Ukrainian arts community are building up the local context for contemporary art.`,
  source: `ARTMargins, article by Larisa Babij`,
  sourceHref: 'https://artmargins.com/a-short-guide-to-contemporary-art-in-ukraine/',
};

export const artNewsUkraine = {
  content: (
    <>
      5 main problems of Ukrainian contemporary art-market:
      <div style={{ height: 8 }} />
      1.	The lack of understanding of contemporary art by Ukrainian public is one of the reasons of the stagnation of the domestic art market. The lack of innovation and bold approaches in Ukrainian art scene is partly the result of conservative methods to art based on “old” school.
      <br />
      2.	Art education. Due to the development of the global art scene, one can observe the stagnation of the Ukrainian, in which the old principles of creativity are dominant, limiting the artistic imagination and new ideas.
      <br />
      3.	Lack of open, public institutions of criticism. Art criticism, as well as the profession, does not exist in Ukraine. This is partly due to the cultural policy of the state because there is practically no support for artists in Ukraine.
      <br />
      4.	The insufficient use of new media technologies to promote artists and their work in the Ukrainian art market. Therefore, the insufficient level of virtualization of museums, galleries and auctions.
      <br />
      5.	The state. The country does not invest in the growth of the art market, Ukraine needs to legalize it and make it a public phenomenon by using all kinds of forms of new media.”
    </>
  ),
  source: `Art News Ukraine. Under the guidance of Sotheby's.`,
  sourceHref: 'https://art-news.com.ua/5-glavnyx-problem-ukrainskogo-art-rynka',
};

export const zolotoeSechenie = {
  content: `The art market in Ukraine is at the stage of formation, if the market of classical artworks is approximately 15-20 years old, the contemporary art market is less than 7 years old. There are only about one hundred collectors in the whole Ukraine. Such a small number that it is funny. Ukrainian classical and Soviet art has the greatest potential for investments today.`,
  source: `Alexei Vasylenko, co-founder of the auction house “Zolotoe sechenie”.`,
  sourceHref: 'https://bit.ua/2014/07/art-and-money/',
};

export const pinchukArtCentre = {
  content: (
    <>
      - What is, in your opinion, the peculiarity of the Ukrainian art scene?
      <div style={{ height: 8 }} />
      The fact that it does not exist. Ukrainian art market is absolutely not developed. There are no galleries that invest in the career and development of the artist. This is the first thing to mention when you talk about the art scene. The existence of such galleries develops the art collector, they grow together. You need to understand that this is not just a work that has to be done on a national level but also work related to the involvement in the international art market. This is what is missing in Ukraine
    </>
  ),
  source: `Bjorn Geldhof, deputy art director of the PinchukArtCentre.`,
  sourceHref: 'http://be-inart.com/post/view/921',
};
