import React, { useState } from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';
import Fade from '@material-ui/core/Fade';
import Grow from '@material-ui/core/Grow';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import SpeechBubble from '../../../SpeechBubble';
import Turn from '../../../Turn';

const GlobalStyle = createGlobalStyle`
  body {
    transition: ${props => props.theme.transitions.create(['background-color'], { duration: 1000 })};
    background-color: ${props => props.bgColor || '#ffa7b6'} !important;
  }
`;

const envelope = [
  [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],
  [1,0,0,1,0,0,0,0,0,0,0,0,0,1,0,0,1],
  [1,0,0,0,1,0,0,0,0,0,0,0,1,0,0,0,1],
  [1,0,0,0,0,1,0,0,0,0,0,1,0,0,0,0,1],
  [1,0,0,0,0,0,1,0,0,0,1,0,0,0,0,0,1],
  [1,0,0,0,0,2,0,1,0,1,0,2,0,0,0,0,1],
  [1,0,0,0,2,0,0,0,1,0,0,0,2,0,0,0,1],
  [1,0,0,2,0,0,0,0,0,0,0,0,0,2,0,0,1],
  [1,0,2,0,0,0,0,0,0,0,0,0,0,0,2,0,1],
  [1,2,0,0,0,0,0,0,0,0,0,0,0,0,0,2,1],
  [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],
];

const arrow = [
  [9,9,9,9,9,9,9,9,9,1,1,9,9,9,9],
  [9,9,9,9,9,9,9,9,9,1,0,1,9,9,9],
  [1,1,1,1,1,1,1,1,1,1,0,0,1,9,9],
  [1,0,0,0,0,0,0,0,0,0,0,0,0,1,9],
  [1,0,0,0,0,0,0,0,0,0,0,0,0,0,1],
  [1,0,0,0,0,0,0,0,0,0,0,0,0,1,9],
  [1,1,1,1,1,1,1,1,1,1,0,0,1,9,9],
  [9,9,9,9,9,9,9,9,9,1,0,1,9,9,9],
  [9,9,9,9,9,9,9,9,9,1,1,9,9,9,9],
];

const colors = ['#fff', '#333', 'rgba(255, 255, 255, 0.9)'];
const pixelize = (pixels, pixelSize, colors) => {
  let result = '';
  for (let y = 0; y < pixels.length; y++) {
    for (let x = 0; x < pixels[y].length; x++) {
      let sep = ',';
      if (x === 0 && y === 0) sep = '';
      const xpx = x * pixelSize;
      const ypx = y * pixelSize;
      result += `${sep} ${xpx}px ${ypx}px ${colors[pixels[y][x]] || 'transparent'}`;
    }
  }
  return result;
};

const Pixelize = styled.div`
  ${props => css`
    width: ${props.pixels[0].length * props.pixelSize}px;
    height: ${props.pixels.length * props.pixelSize}px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: ${props.pixelSize}px;
      height: ${props.pixelSize}px;
      box-shadow: ${pixelize(props.pixels, props.pixelSize, props.colors)};
      background-color: ${props.firstPixelFill};
    }
  `}
`;

const pages = [
  '/images/internet_art/01.jpg',
  '/images/internet_art/02.jpg',
  '/images/internet_art/03.jpg',
  '/images/internet_art/04.jpg',
  '/images/internet_art/05.jpg',
  '/images/internet_art/06.jpg',
  '/images/internet_art/07.jpg',
  '/images/internet_art/08.jpg',
  '/images/internet_art/09.jpg',
  '/images/internet_art/10.jpg',
  '/images/internet_art/11.jpg',
  '/images/internet_art/12.jpg',
  '/images/internet_art/13.jpg',
  '/images/internet_art/14.jpg',
];

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Pitaya1 = () => {
  const [exit, setExit] = useState(false);
  const onExit = () => setExit(true);
  const history = useHistory();
  const onExited = () => history.push('/read/pinky/memory');

  const [open, setOpen] = useState(false);
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  return (
    <Fade in={!exit} timeout={1000} onExited={onExited}>
      <Box margin="auto">
        <GlobalStyle bgColor={exit ? '#BABFBF' : undefined} />
        <Box position="relative">
          <Pixelize
            pixels={envelope}
            pixelSize={8}
            colors={colors}
            firstPixelFill={colors[1]}
            style={{ cursor: 'pointer' }}
            onClick={onOpen}
          />
          <Box position="absolute" bottom="100%" left="100%">
            <SpeechBubble>
              letter on internet art
            </SpeechBubble>
          </Box>
        </Box>
        <Box position="absolute" right={50} bottom={50} style={{ cursor: 'pointer' }} onClick={onExit}>
          <Pixelize pixels={arrow} pixelSize={5} colors={colors} />
        </Box>

        <StyledModal open={open} onClose={onClose}>
          <Grow in={open} timeout={500}>
            <Box width={800} height={600} style={{ outline: 'none' }}>
              <Turn className="magazine">
                {pages.map((page, index) => (
                  <div key={index} className="page">
                    <img src={page} alt="" />
                  </div>
                ))}
              </Turn>
            </Box>
          </Grow>
        </StyledModal>
      </Box>
    </Fade>
  );
};

export default Pitaya1;
