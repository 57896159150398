import styled, { keyframes, css } from 'styled-components';
import Box from '@material-ui/core/Box';
import Pepper from './Pepper';
import Pitaya from './Pitaya';
import Blueberry from './Blueberry';

const pulse = keyframes`
  0% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
`;

const buttonCss = css`
  pointer-events: none;
`;

export const PulsatingPepper = styled(Pepper)`
  ${buttonCss}
  animation: ${pulse} 1s infinite;
`;

export const PulsatingPitaya = styled(Pitaya)`
  ${buttonCss}
  animation: ${pulse} 2s infinite;
`;

export const PulsatingBlueberry = styled(Blueberry)`
  ${buttonCss}
  animation: ${pulse} 1.5s infinite;
`;

export const ButtonWrapper = styled(Box)`
  transition: transform 0.2s ease;

  &:hover {
    cursor: pointer;
    transform: scale3d(1.2, 1.2, 1);
  }

  &[data-devoured="true"] {
    transition: transform ${props => props.transitionDuration}ms ease;
    transform: scale3d(100, 100, 1);
    z-index: 10;
    position: relative;

    svg path {
      stroke: none;
    }
  }
`;
