import React, { useContext, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import { useHistory } from 'react-router-dom';
import Magnifier from 'react-magnifier';
import { PotatoPageContext } from '../../../../PotatoPageContext';
import Description from './Description';
import text_variation from './text_variation.png';
import next from './next.svg';
import Monospace from '../../../../../../Monospace';

const Variation1 = () => {
  const pageCtx = useContext(PotatoPageContext);
  useEffect(() => pageCtx.setBgColor('#d7d5d6'), []);

  const [exit, setExit] = React.useState(false);
  const onExit = () => setExit(true);
  const history = useHistory();
  const onExited = () => history.push('/read/potato/art-in-ukraine/drawings');

  return (
    <Fade in={!exit} timeout={1000} onExited={onExited}>
      <Box
        position="relative"
        width={1200}
        marginX="auto"
        marginY={6}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        overflow="hidden"
      >
        <Description position="absolute" right={8} top={8} />
        <img alt="" src={text_variation} width={600} />
        <Box marginLeft="auto" zIndex={1} marginTop={-10}>
          <Magnifier alt="" src="/images/variation1/1.jpg" width={900} />
        </Box>
        <Box zIndex={1} marginTop={-20}>
          <Magnifier alt="" src="/images/variation1/2.jpg" width={260} />
        </Box>
        <Box
          zIndex={1}
          bgcolor="#F9A9A9"
          padding={2}
          textAlign="center"
          border={1}
          width={640}
          marginTop={-8}
          marginLeft="320px"
          position="relative"
        >
          <Monospace>
            <b>
              This work is a gesture of possibilities. This is one of the hundreds, millions of the variations that could be done with the presented set of tools. Perception here plays a role of a transmitter through which an observer is trying to discover the nature of the objects by questioning the relationship between reality and illusion. Are these painted sticks or imaginary lines? Is this a painting or a sculpture, or something in between?
            </b>
          </Monospace>
          <Box position="absolute" left="100%" bottom="-30%" marginLeft={4}>
            <video autoPlay muted loop width={160}>
              <source src="/video/variation1_1.mp4" type="video/mp4" />
            </video>
          </Box>
        </Box>
        <Box zIndex={1} marginTop={6} marginLeft={-16}>
          <Magnifier alt="" src="/images/variation1/3.jpg" width={700} />
        </Box>
        <Box zIndex={1} marginTop="-950px" marginLeft="740px">
          <Magnifier alt="" src="/images/variation1/4.jpg" width={380} />
        </Box>
        <Box height={360} />
        <Box marginLeft="780px" position="relative">
          <img alt="" src={next} width={100} style={{ cursor: 'pointer' }} onClick={onExit} />
          <Box position="absolute" left={-140} bottom="100%" marginBottom={6}>
            <video autoPlay muted loop width={200}>
              <source src="/video/variation1_2.mp4" type="video/mp4" />
            </video>
          </Box>
        </Box>
      </Box>
    </Fade>
  );
};

export default Variation1;
