import React, { useState, useEffect, useMemo }  from 'react';
import Box from '@material-ui/core/Box';
import Typist from 'react-typist';
import 'react-typist/dist/Typist.css';
import styled from 'styled-components';

const cursor = {
  show: true,
  blink: true,
  element: '|',
  hideWhenDone: true,
  hideWhenDoneDelay: 300,
};

const Wrapper = styled(Box)`
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LineWrapper = styled.div`
  position: relative;
`;

const LineText = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

const LineOccupant = styled.div`
  visibility: hidden;

  &::after {
    content: "${cursor.element}";
  }
`;

const MultilineTypist = ({
  children: childrenProp,
  textComponent,
  onTypingDone,
  disableCursor,
  ...restProps
}) => {
  const [i, setI] = useState(0);

  const xCursor = useMemo(() => {
    if (disableCursor) {
      return { ...cursor, show: false };
    }
    return cursor;
  }, [disableCursor]);

  const children = React.Children.map(childrenProp, (line, j) => {
    return (
      <LineWrapper>
        <LineOccupant as={textComponent}>{line}</LineOccupant>
        <LineText as={textComponent}>
          {j === i && <Typist onTypingDone={() => setI(j + 1)} cursor={xCursor}>{line}</Typist>}
          {j < i && line}
        </LineText>
      </LineWrapper>
    );
  });

  useEffect(() => {
    if (onTypingDone && i === childrenProp.length) {
      onTypingDone();
    }
  }, [onTypingDone, i, childrenProp]);

  return <Wrapper {...restProps}>{children}</Wrapper>;
};

export default MultilineTypist;
