import React, { useState, useCallback } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import Box from '@material-ui/core/Box';
import Zoom from '@material-ui/core/Zoom';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import Button8 from './Button8';
import rand from '../../../../utils/rand';
import MultilineTypist from '../../../../MultilineTypist';
import Tooltip from '../../../../Tooltip';
import { monospaceCss } from '../../../../Monospace';
import CodeModal from './CodeModal';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Alfa+Slab+One|Dancing+Script|Gloria+Hallelujah|Great+Vibes|Homemade+Apple|Monoton|VT323&display=swap');

  body {
    transition: ${props => props.theme.transitions.create(['background-color', 'opacity'], { duration: props.exit ? 2000 : 200 })};
    background-color: ${props => props.bg || props.theme.palette.pinky} !important;
    opacity: 1;

    ${props => props.exit && css`
      opacity: 0;
    `}
  }
`;

const TextWrapper = styled.div`
  perspective: 400px;
  filter: invert(${props => props.invert});
  transition: ${props => props.theme.transitions.create(['filter', 'font-size'])};
  ${props => !props.fontFamily && monospaceCss};
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize || 16}px !important;
`;

const TextWrapperInner = styled.div`
  transition: ${props => props.theme.transitions.create(['transform'], { duration: 1000 })};
  transform-origin: bottom;
  transform: rotateX(${props => props.rotateX}deg);
`;

const StyledButton = styled(Button)`
  font-size: 14px !important;
  filter: invert(${props => props.invert});
`;

const langs = {
  en: [
    <span key="f">
      You might have noticed the word ‘
      <Tooltip color="black" content="vulnerably delicate, easily influenced">
        fragile
      </Tooltip>
      ’ I just used to describe perception.
    </span>,
    "I say this, because in the world of art creators can manipulate the way you perceive",
    "their work. It is up to them to decide whether or not they want to have a broad",
    "field of interpretations or just a tiny route, If they want you to understand",
    "their work or not be able to get it at all.",
    <div key="gap" style={{ height: 32 }} />,
    "click to change your perception"
  ],
  ru: [
    <span key="f">
      Вы могли заметить, что в предыдущем тексте я использовала слово “
      <Tooltip color="black" content=" уязвимый, подверженный влиянию">
        хрупкий
      </Tooltip>
      ”, чтобы описать восприятие.
    </span>,
    'Я говорю так, потому что в мире искусства создатели могут манипулировать тем,',
    'как вы будете воспринимать их работу. Они сами решают, хотят ли они',
    'или нет иметь большое поле для интерпретаций или же маленькую дорожку;',
    'чтобы их работу поняли или же не разобрались в ней совсем.',
    <div key="gap" style={{ height: 32 }} />,
    'нажмите, чтобы изменить восприятие',
  ],
  nl: [
    <span key="f">
      Jij hebt misschien gemerkt dat ik het woord ‘
      <Tooltip color="black" content="delicaat, makkelijk beïnvloedbaar">
        kwetsbaar
      </Tooltip>
      ’ heb gebruikt om perceptie te beschrijven.
    </span>,
    'Ik zeg dit omdat in de wereld van de kunst makers je manier van het',
    'kunstwerk observeren kunnen beïnvloeden. Het is aan hen om te beslissen',
    'of ze een groot veld vaninterpretatie willen hebben of slechts een kleine weg,',
    'of ze willen dat je hun werk begrijpt of totaal niet.',
    <div key="gap" style={{ height: 32 }} />,
    'Klik om perceptie te veranderen',
  ],
};

const randHSL = () => {
  const hsl = {
    h: Math.round(rand(0, 360)),
    s: rand(),
    l: rand(),
  };
  hsl.toString = () => `hsl(${hsl.h}, ${hsl.s * 100}%, ${hsl.l * 100}%)`;
  return hsl;
};

const wingdingsFontFamily = "Wingdings, 'Zapf Dingbats'";
const greatVibesFontFamily = "'Great Vibes', cursive";
const dancingScriptFontFamily = "'Dancing Script', cursive";
const monospaceFontFamily = "'Courier New', Courier, monospace";
const fontFamilies = [
  "'Courier New', Courier, monospace",
  "'Dancing Script', cursive",
  "'Alfa Slab One', cursive",
  "'Gloria Hallelujah', cursive",
  "'Monoton', cursive",
  "'Homemade Apple', cursive",
  "'VT323', monospace",
  greatVibesFontFamily,
  wingdingsFontFamily,
  dancingScriptFontFamily,
  monospaceFontFamily,
];

let prevRandFontFamily = monospaceFontFamily;
const randFontFamily = () => {
  while (1) {
    const fontFamily = fontFamilies[Math.floor(rand(0, fontFamilies.length))];
    if (prevRandFontFamily === fontFamily) {
      continue
    }
    if (prevRandFontFamily.endsWith('cursive') && fontFamily.endsWith('cursive')) {
      continue;
    }
    if (prevRandFontFamily.endsWith('monospace') && fontFamily.endsWith('monospace')) {
      continue
    }
    prevRandFontFamily = fontFamily;
    return fontFamily;
  }
};

let prevRandFontSize = 0;
const randFontSize = () => {
  while (1) {
    const fontSize = Math.round(rand(14, 24));
    if (Math.abs(prevRandFontSize - fontSize) > 5) {
      prevRandFontSize = fontSize;
      return fontSize;
    }
  }
};

const Page7Pinky = () => {
  const [isTypingDone, setIsTypingDone] = useState(false);
  const onTypingDone = useCallback(() => {
    setIsTypingDone(true);
  }, []);

  const [bgColor, setBgColor] = useState();
  const onRandBgColor = () => {
    setBgColor(randHSL);
  };

  const [lang, setLang] = useState('en');
  const onChangeLang = useCallback(() => {
    setLang(prev => {
      if (prev === 'en') {
        return 'ru';
      }
      if (prev === 'ru') {
        return 'nl';
      }
      return 'en';
    });
  }, []);

  const [font, setFont] = useState({});
  const onRandFont = () => {
    setFont({
      family: randFontFamily(),
      size: randFontSize(),
    });
  };

  const [codeOpen, setCodeOpen] = useState(false);
  const onCodeOpen = useCallback(() => {
    setCodeOpen(true);
  }, []);
  const onCodeClose = useCallback(() => {
    setCodeOpen(false);
  }, []);

  const history = useHistory();
  const [shouldExit, setShouldExit] = useState(false);
  const onExit = useCallback(() => {
    setShouldExit(true);
    setTimeout(() => {
      history.push('/read/pinky/stages');
    }, 2000);
  }, [history]);

  const invert= bgColor && bgColor.l < 0.5 ? 1 : 0;

  return (
    <Box margin="auto" display="flex" flexDirection="column" alignItems="center" height="100%" justifyContent="center">
      <GlobalStyle bg={shouldExit ? '#fff' : bgColor && bgColor.toString()} exit={shouldExit} />
      <Box flexGrow={1} />

      <TextWrapper
        invert={invert}
        fontFamily={font.family}
        fontSize={
          font.family === wingdingsFontFamily
            ? Math.min(14, font.size)
              : font.family === greatVibesFontFamily
              ? 24
                : font.family === dancingScriptFontFamily
                ? 28
            : font.size
        }
      >
        <TextWrapperInner rotateX={isTypingDone ? 10 : 0}>
          <MultilineTypist onTypingDone={onTypingDone}>
            {langs[lang]}
          </MultilineTypist>
        </TextWrapperInner>
      </TextWrapper>

      <Box width={640} display="flex" justifyContent="space-evenly" marginTop={4}>
        <Zoom in={isTypingDone} timeout={1000}>
          <div>
            <Button8 color="red" onClick={onChangeLang} />
          </div>
        </Zoom>
        <Zoom in={isTypingDone} timeout={1000}>
          <div>
            <Button8 color="green" onClick={onRandBgColor} />
          </div>
        </Zoom>
        <Zoom in={isTypingDone} timeout={1000}>
          <div>
            <Button8 color="obsidian" onClick={onRandFont} />
          </div>
        </Zoom>
        <Zoom in={isTypingDone} timeout={1000}>
          <div>
            <Button8 color="yellow" onClick={onCodeOpen} />
          </div>
        </Zoom>
      </Box>

      <CodeModal open={codeOpen} onClose={onCodeClose} />

      <Box flexGrow={1} />
      <Fade in={isTypingDone} timeout={5000}>
        <Box margin={2}>
          <Zoom in={isTypingDone} timeout={2000}>
            <StyledButton onClick={onExit} invert={invert} size="large">
              Next
            </StyledButton>
          </Zoom>
        </Box>
      </Fade>
    </Box>
  );
};

export default Page7Pinky;
