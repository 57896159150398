import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import styled, { createGlobalStyle } from 'styled-components';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #BABFBF !important;
  }
`;

const Video = styled.video`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
`;

const Image = styled.img`
  max-width: 90vw;
  width: 100%;
  max-height: 90vh;
  height: 100%;
  object-fit: contain;
`;

export default function Memory() {
  const [exit, setExit] = useState(false);
  const onExit = () => setExit(true);
  const history = useHistory();
  const onExited = () => history.push('/read/teotr');

  return (
    <Fade in={!exit} timeout={2000} onExited={onExited}>
      <Box margin="auto" position="relative">
        <GlobalStyle />
        <Video autoPlay loop>
          <source src="/video/memory.mp4" type="video/mp4" />
        </Video>
        <Image src="/images/memory.jpg" />
        <Box display="flex" justifyContent="flex-end">
          <Button variant="contained" endIcon={<ChevronRightIcon />} onClick={onExit}>
            Next
          </Button>
        </Box>
      </Box>
    </Fade>
  );
}
