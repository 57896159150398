import React from 'react';
import Monospace from '../../../../Monospace';
import Interview from './Interview';

export default [
  <Interview key={1} index={1} name="1.txt">
    <Monospace fontSize={14} component="article">
      <div>Occupation: making ceramic tableware and organising ceramic workshops</div>
      <div>Age: 20</div>
      <div>Country: Ukraine</div>
      <br />
      <div><b>What is your definition of art?</b></div>
      <br />
      <div>Art is a stream of thoughts and feelings that goes through the person’s kind of activity.</div>
      <br />
      <div><b>Do you have specific criteria when you look at art? What is a “good artwork” for you?</b></div>
      <br />
      <div>Good art is something that is touching for you. It doesn’t matter if it is a sculpture, painting or an installation. Art should be interesting in the sense of colour, light and idea. Idea, in my opinion, is the most important criterion. I have to feel something when I look at the work. Maybe even bad feelings. If I don’t feel anything then the work of art has no sense to me. Moreover, criteria of judging art might be different from the ones who review it. Art critics or just beholders. My personal criteria are really vague. The work of art should have at least a simple composition, then it looks professional. And, of course, the character. When, for instance, you look at the works of one artist you start to understand that there is an invisible red line going through all of his or her creations. An idea. An idea could be in the narrative of the work or in the specific technique. But when an idea is present, it is cool.</div>
      <br />
      <div><b>And how about the definition of “beauty”? Does the work of art have to be beautiful to be good?</b></div>
      <br />
      <div>Then we need to specify what the criteria of beauty are. Beauty is a subjective thing.</div>
      <br />
      <div><b>Could you tell me what “beauty” is for you then? And does it affect the way you perceive the artwork?</b></div>
      <br />
      <div>It is hard. Beauty is mainly balance and harmony between all kinds of elements. If I take my definition of beauty into account, then beauty takes a big role in the judging of an artwork. An artwork should have harmony.</div>
      <br />
      <div><b>That is clear. What about the works of art in which the main concept is to be disharmonious and chaotic?</b></div>
      <br />
      <div>Every work has to be balanced. Especially the ones that have a big semantic load. They have to have a character, professionalism. I have a friend who makes paintings with a very heavy narrative, meanwhile his technique and skills are genuinely absent. And these kind of works I don’t consider to be art. Good art, to be precise.</div>
      <br />
      <div><b>Does it mean that skills and craftmanship are important?</b></div>
      <br />
      <div>Yes, pretty much. It is a base for every artwork, which everyone should learn. It is like a language. No matter what kind of great idea and thoughts you have, no one will understand you if you can’t speak.</div>
      <br />
      <div><b>What do you think about artists who don’t have art education behind their back?</b></div>
      <br />
      <div>I think they are cool. I love people who create. There is a big difference between having knowledge and having an art diploma.</div>
      <br />
      <div><b>Alright. Let’s talk about something else now. How often do you go to museums or art exhibitions?</b></div>
      <br />
      <div>I live in a village. So very rarely. Only if there is something super exciting going on. Last time I went it was autumn. The work that got stuck in my mind from that exhibition was an oil painting of our local landscape with a castle. Very lively, contrasted and deep. I loved the foreground with very well painted roof tiles.</div>
      <br />
      <div><b>That must be a very beautiful figurative painting. What do you think about contemporary art, by the way?</b></div>
      <br />
      <div>Contemporary art is a power! It’s a progression that needs to be boosted. We need to promote it and explain to people that progress is normal. Abstraction, cubism and supremacism are innovative. That is why people tend to think weird about it.</div>
      <br />
      <div><b>What do you mean by “think weird about it”?</b></div>
      <br />
      <div>They don’t think it is art. People need to learn about contemporary art and art history in general. They don’t understand it, even though behind every work there is a concept, idea, history, process of creating. But for the casual beholder it is not visible. That is why they think that new art movements are weird.</div>
      <br />
      <div><b>Do you think this kind of idea about art is also dependent on the country?</b></div>
      <br />
      <div>Definitely, yes! The level of education, economy and mentality. All these things affect society and therefore their connection to art.</div>
      <br />
      <div><b>Keeping in mind all these criteria, what do people think about art in your country?</b></div>
      <br />
      <div>Depends in which groups. I think for the majority art is something mediocre, something not important and something which you can easily live without. But that is not true, of course.</div>
      <br />
      <div><b>And how about people around you?</b></div>
      <br />
      <div>Well, my friends and family make art or are very interested in it. So it is good. That is why I call my surroundings very flexible and comfortable.</div>
    </Monospace>
  </Interview>,
  <Interview key={2} index={2} name="2.txt">
    <Monospace fontSize={14} component="article">
      <div>Occupation: designer and photographer, student</div>
      <div>Age: 19</div>
      <div>Country: Russia</div>
      <br />
      <div><b>What is your definition of art?</b></div>
      <br />
      <div>I have been asking this question to myself for a long time. I was thinking and came to the conclusion that art is an expression of a thought. Not a communication. In other words, an artist could just explode his ideas, something he is thinking about a lot, and it is not important if he wanted to make everything clear, so that the viewer understands him. Art is a picture of a cigarette, a 10 second melody, planks painted in different colours; if there is a thought, idea or observation behind it. I myself stick to this definition. </div>
      <br />
      <div><b>Do you have specific criteria when you look at art? What is a “good artwork” for you?</b></div>
      <br />
      <div>The factors could be different. For example, the thought put in the artwork could be close to a lot of people, in that case the realization or the way artwork looks is not important. Then the work of art becomes a success. A failure is when a work doesn’t touch anyone, the eyes and ears don’t stick to it. However, I think that good and bad art don’t exist. It is all purely subjective. </div>
      <br />
      <div><b>What about art that was considered to be better than others? And those artworks that were clearly put on a pedestal?</b></div>
      <br />
      <div>It means that one idea was closer to a certain amount of people than the other. Or maybe it is a matter of upbringing, things you grew up with. Very often generations grow up on academic art with the idea that the works of Aivazovksy are better than Jeff Koons’ because the first one pays attention to the rules of composition and is considered to be a classic painter. In other words, if an artist is closer to the academic school it is more valuable. This is how things work right now but it was also a similar situation years and years ago. For instance, there are a lot of famous artists from art history whom critics back then proclaimed talentless.</div>
      <br />
      <div><b>Let’s talk about this way of upbringing. Do you think that the idea that academic art is better than modern is very common in Russia and Ukraine?</b></div>
      <br />
      <div>Yes, for sure. The core of this lies in the idea that academic art is more difficult in the sense of  craftmanship while in contemporary art you can just tape a banana to the wall. Because of this, the misconception of modern art being worse than classical, academic art was born. </div>
      <br />
      <div><b>In the beginning you mentioned that you are a first-year student. Is that an art education?</b></div>
      <br />
      <div>I am an engineer. My area of working is printmaking, roughly speaking: packaging production, book printing and this even includes some printing of circuit boards for mobile phones. But there is also enough creative work for which I have been drawn all my life. For instance, in the first semester I developed a concept for pasta branding including designing their packages. I was always interested in art. I really wanted to apply to a design academy and I spent a lot of time preparing myself for the exams including reading books and all sorts of articles to better understand art as well as watching movies. I tried to do a lot of practical exercises to get a taste for everything and to form my own opinion. In a lot of cases I noticed that academic drawings were more valuable for people than modern art solutions, which, as I mentioned before, is the result of ignorance of art history.</div>
      <br />
      <div><b>Regarding that, do you notice any shift in this mindset among young people, compared with the older generation?</b></div>
      <br />
      <div>Absolutely, yes. There are a lot of young people in my environment who are interested in art, especially modernism and contemporary, because for many it is mysterious and weird (in terms of “what did the author want to say?”). That makes them want to learn more. Furthermore, I see a tendency of more creative people appearing, I think, largely due to the development of social networks, because you can share your thoughts literally for a minimum of resources (Internet and electricity, roughly speaking).</div>
      <br />
      <div><b>It is nice to realize that art is expanding its, let's say, boundaries of impact. However, quite often I come across phrases like "Art is not a pile of sticks and crap, which the 90% of contemporary works consist of". What do you think about it?</b></div>
      <br />
      <div>I think that if the author wants to convey the idea with the help of sticks and crap, then this is also art. For me, another idea is laid down in the definition of art: the creator can call his work art, because they know better than anyone if they wanted to say something or just lay out this very stack of sticks and ... well, you understand.</div>
      <div>Your way of thinking is quite progressive, and I like to think that the younger generation is changing its attitude towards art. Especially in Russia and Ukraine, the countries of the former CIS, in fact.</div>
      <div>Sooner or later, this will definitely happen, I'm sure.</div>
      <br />
      <div><b>Have you ever encountered a lack of understanding in the direction of your art, your work?</b></div>
      <br />
      <div>Lack of understanding of my idea - I think, yes, it was noticeable by the reaction of the observer, but they were not particularly interested in what I wanted to say with my work (I'm talking mainly about unfamiliar people), because for them, this is primarily a beautiful image. But basically, to be honest, everyone in my work primarily admires the visual part. Only people I am close to ask about the meaning. I suspect that this is due to the fact that I am mainly engaged in photography.</div>
      <div>What do you think can be done to change the viewer’s perception? This applies to your work, and art in general.</div>
      <div>Probably talking more about art. About the thoughts inherent in the work. Personally, I try to leave at least small notes under my photographs when I present them, which I generally thought about when I made this or that work.</div>
      <br />
      <div><b>This means that the written language is one of the factors that guide viewers' associations to something more specific. But it can be both a friend and foe of a work. Let me ask you about how often you go to museums or exhibitions?</b></div>
      <br />
      <div>Yes, of course, this is both a friend and an enemy at the same time, however, in this way you can tell the person that your work was done not just for fun, but it has a certain idea. Then the observer begins to develop a thought process.</div>
      <div>I try to go to museums as often as possible. Unfortunately, study and work often overload me very much, but hopefully next week I am going to go to some interesting places filled with art.</div>
      <br />
      <div><b>Do you think that art exhibitions are developing well in your country? And what is the opinion of people about them?</b></div>
      <br />
      <div>I think the good progression can be said mainly about Moscow and St. Petersburg, in other cities everything is not so promising. People around me are mostly interested and positive about art exhibitions.</div>
      <br />
      <div><b>And what is happening in other cities?</b></div>
      <br />
      <div>It seems to me, there is no particular development in terms of exhibitions, or the way people look at art. I don’t know if this is connected to our mentality or simply to our Russian economy. There is a very strong economic decline in the country, so that many simply do not care about art, all they think about is how to scrape up money for food.</div>
      <br />
      <div><b>This is very unfortunate. I hope that in the future such problems of the economic plan will be resolved, which will give impetus to the development of culture and art. May I ask a question about your family? What do your relatives think about contemporary art?</b></div>
      <br />
      <div>I hope so too. My father is very positive about contemporary art, he believes that this is a breath of fresh air. But my mother does not understand it, especially abstract art, and is always joking about it. "Fooling around", she says. But in fact she has nothing against it and believes that self-expression can take any form.</div>
    </Monospace>
  </Interview>,
  <Interview key={3} index={3} name="3.txt">
    <Monospace fontSize={14} component="article">
      <div>Occupation: exchange trading</div>
      <div>Age: 21</div>
      <div>Country: Russia</div>
      <br />
      <div><b>What is your definition of art?</b></div>
      <br />
      <div>Personally, I have so far come to the conclusion that art is primarily a way of satisfying a human need for expressing themselves and their conclusions; this is an inevitable process of reflection on others, an attempt to interpret/understand what is happening around; very subjective process.</div>
      <br />
      <div><b>Thank you for your answer. Do you have the concept of "good art"? When an art object is considered good, what are the evaluation criteria?</b></div>
      <br />
      <div>A very difficult question; even a complete, sufficient set of subjective criteria is difficult to compile, but I think that these are mainly important for me:</div>
      <div>1. the presence of a certain concept, it can lie in the category of rational, or it can be attached simply to emotions, but it should exist. Even the absurd or denial of anything is conceptual in itself.</div>
      <div>2. expressiveness. I want to feel very sharply what the artist did.</div>
      <div>Other criteria seem to be too narrow for me for such a broad and diverse field.</div>
      <br />
      <div><b>Well, then let's move on to a more specific question: what do you think of contemporary art?</b></div>
      <br />
      <div>In my opinion, one of the keywords for contemporary art is overconsumption. I love the new, I love progress in general - it is very interesting, fascinating and fulfills life. On the other hand, I notice that a contemporary artist often experiences acute financial dependence on institutions and consumers, even in such a globalized world they are tied to a comfort zone. When the artist’s art directly supports their well-being, it tends to turn into conformal content for consumption, and the image of the artist becomes toothless and too glamorous. I see a lack of strong personalities in art and actions that correspond to modern problems and threats, such as climate change, the emergence of economically/politically effective totalitarian regimes that can successfully compete with more democratic systems, the invention of Deepfakes and other advanced methods of manipulation, the development of AI and various upgrades for the brain, which are able to change human nature, well, that's all. So far, I have not found anything really worthwhile on these topics, and it worries me a lot.</div>
      <br />
      <div><b>In other words, contemporary art must somehow solve problems, offer development options or create ideas that will lead to the solution of these problems?</b></div>
      <br />
      <div>No, that is not necessary. It can be anything, even if it focuses solely on form and does not really have content - I will consider it legitimate, although I have slightly different preferences now. But at the same time, I expect that in the world of art there will be such works that address urgent problems and will sharply interpret the current state of things. It is not so important whether these works offer something in return or not.</div>
      <br />
      <div><b>Alright. I figured out your opinion about contemporary art. What do you think about academism? And the popular belief that traditional art (realism, oil painting, figurative art) is better than modern?</b></div>
      <br />
      <div>In academism, I can be attracted to the set of skills and the historical context. It seems to me that nowadays the creation of academic works is a niche, but not relevant and not so interesting. And that opinion about academism being better than contemporary art, in my experience, arises as a result of insufficient education in the field of art.</div>
      <br />
      <div><b>How big of a problem do you think the lack of education in the field of art in Russia and Ukraine is? And is it relevant?</b></div>
      <br />
      <div>I think that of course the problem is huge, this is due historically and practically to the century of the Soviet Union, with its obsession with classicism and a known attitude to modernity (especially post-modernism). In my opinion, this led to a very strong lag in thought in general and perception of the environment. And the system that has developed since the collapse of the Soviet Union does not contribute much to reducing this gap (here, I think, there is a certain difference between Russia and Ukraine). Accordingly, the lack of an educated viewership restricts the outflow of talented artists and painters. In the West, they get more attention, money, different experiences in a more friendly environment, so to speak. And this is a catastrophe for the Russian Federation in my opinion - this is the disregarding of previous mistakes, the lack of necessary self-reflection on a national scale, the lack of progress.</div>
      <br />
      <div><b>A very interesting connection between the current perception of art and the history of the country. You mentioned artists who, because of the lack of education of the viewer, are forced to look for their audience in other countries. Can you name similar situations in which the difference between the way people perceive art in Europe and Russia is visible?</b></div>
      <br />
      <div>Architecture, for example. In Russia nowadays, there are catastrophically few examples of new buildings that simply look decent. Moreover, architecture has generally receded into the background, they are building what is profitable, without regard to aesthetics. Architecture does not hang in the gallery - it creates an environment from which there is no escape, people have been forced to live in it for decades, this greatly affects their psychological state. High-rise buildings are depressive, isolate people, promote asocial behavior, and so on. They also experimented with them in Europe - there the experience was taken into account, they are no longer being built. But, of course, some things like modern blocks are still left in France. In Europe that became a part of history and they quickly went on to another solutions. Nothing like that has happened in Russia.</div>
      <br />
      <div><b>I think this is largely due to the system and the regime in which the country is located. What about people, let’s say, ordinary inhabitants? Do they want to change, adopt a new art, learn contemporary methods?</b></div>
      <br />
      <div>If we talk about youth, I noticed a certain interest in art. They are going to galleries and are ready to learn something new. But very often it is limited to domestic experience and modern art. Plus, often not too much can be offered to them - there is very little actual art happening in Russia, there is nowhere to look at it, nowhere to find out about it, and interest usually fades. I think the young generation has some liveliness, potential. It’s hard to say something like this about the older generations, it seemed to me that they basically have apathy in general, they are rarely interested in things that go beyond everyday life.</div>
      <br />
      <div><b>Tell me more about exhibitions in Russia. How often do you go to museums? You mentioned that they are limited to domestic experience and modern art. Can you elaborate on that?</b></div>
      <br />
      <div>When I lived in Samara, the only thing I could go to was Samara Museum of Art and the private Victoria Gallery, which does not have a permanent exhibition. The Samara Museum of Art is mainly filled with Russian classics, diluted with European on the third floor and Russian avant-garde on the second. Some events there happen infrequently, nothing relevant there happened at all in my memory. "Victoria" - I remember a lecture on avant-garde artists of the early twentieth century, organized by Arzamas. The Embassy of Sweden once organized an exhibition of a photographer there, whose work was a bit of "dirty realism", black and white. These are exceptions. Everything that happened was mostly either a brawl for a local art party (toxic and inappropriately arrogant, I will say), or not very interesting expositions like “the art of the future” - this is such a set of meaningless posters with a uniform design, otherwise you could not name it, and long description explaining what the author meant with his work. For the most part, everything was pretty trivial. My experience comes down to something like this. I think that the Internet has recently become the main source of information in Russia, the aforementioned Arzamas project, for example, they talk popularly, you can get much more useful information, but basically, again, about the domestic.</div>
      <div>And if you want to know more about something, you need to learn English because the information given in English sources is way better and more progressive.</div>
      <br />
      <div><b>Are things different in St. Petersburg and Moscow?</b></div>
      <br />
      <div>I got that impression, but I don’t really know in detail. Judging by the announcements of various lectures, yes, there are more events there, they speak of a more relevant information, but I do not think that the difference is fundamental.</div>
      <br />
      <div><b>Do you think it is appropriate to call such an attitude to art "stereotypical thinking"</b>?</div>
      <div>Yes, it seems to me that outdated stereotypes play a large role in the Russian perception of art, along with the fear of encountering something new, inexplicable. In this case, for some reason this causes a lot of discomfort, an acute desire to accept some firm point of view.</div>
      <br />
      <div><b>We are approaching the end of my questionnaire. Tell me, how does your close environment feel about art?</b></div>
      <br />
      <div>I am actively interested in art myself. My friends usually show interest in art documentaries about famous artists. In my family my father is the only one who likes art, mainly music.</div>
    </Monospace>
  </Interview>,
  <Interview key={4} index={4} name="4.txt">
    <Monospace fontSize={14} component="article">
      <div>Occupation: barista, graduated from a lyceum at the Academy of Arts</div>
      <div>Age: 19</div>
      <div>Country: Ukraine</div>
      <br />
      <div><b>How do you identify art? What is it?</b></div>
      <br />
      <div>I think that this is a difficult question, because for each person art is different. But for me, art is something that evokes deep feelings, emotions and thoughts in me that I cannot get in everyday life. I also believe that what art is for one person may not be art for another.</div>
      <br />
      <div><b>And what kind of emotions should art evoke? Could you tell me more about that?</b></div>
      <br />
      <div>First of all, I don’t think that art should only be about positive emotions. Definitely not. I believe that art is a very powerful tool to make a person pay attention to something and it should not always be a positive subject. As an example, I recall Plastov’s painting “The Fascist flew”, which made an incredible impression on me when I was in the new Tretyakov Gallery. At a first glance, it is just an ordinary landscape but after a couple of seconds you understand the narrative of the painting. This contrast between the first impression and the reality of the painting makes you tremble. Those kinds of emotions are very important because they are moving you.</div>
      <br />
      <div><b>You mentioned a figurative painting. Do you think that abstractions are also capable of evoking the same strong emotions?</b></div>
      <br />
      <div>I think so, but it very much depends on the person and their perceptual characteristics. Abstract art is more difficult to understand. But it certainly can cause vivid emotions, possibly on a different level.</div>
      <br />
      <div><b>Do people in your country feel sympathetic towards academic art or contemporary art with all its movements?</b></div>
      <br />
      <div>Academic art definitely feels closer to our folks because it is easier to understand. To understand, for example, abstraction we need a deeper knowledge which, unfortunately, most people in my country do not have. It seems that there are not a lot of people who have an art education and are aware of art history. On the other hand, art is not only visual paintings and sculptures, but is also cinema, music and theater. I hope that I am just too critical and the situation in real is better than I think.</div>
      <br />
      <div><b>That is true. Art can take many different forms. But let’s continue about Fine Arts. How often do you go to art exhibitions?</b></div>
      <br />
      <div>I love to go to exhibitions. When I was studying in lyceum, I often went to a lot of art exhibitions and museums. I could visit any art museum for free because of my school. We also had an assignment from an art history teacher to visit at least one permanent and one temporary exhibition each block and write a report about it. Now, to my great regret, I don’t do that so often anymore but I try not to miss an opportunity to go to interesting new exhibitions. </div>
      <br />
      <div><b>Sounds good. Could you tell me more about the art school you were studying in?</b></div>
      <br />
      <div>In the art lyceum I was studying at we had general classes like maths and science and art classes. The education was split into four departments: oil painting, sculpture, graphic design (people there were mainly working with aquarelle) and architecture. In every department except for the architecture one academism was highly encouraged. The teachers were quite stuck with it. However, there was one painting tutor who allowed his students to depart from classical norms. They often made strange works and that was not appreciated by the teaching staff of our school and Academy of Arts. Because of that his students often got low grades. In the department of architecture there was more freedom in choosing a style but the drawing assignments were still strictly connected to classical drawing.</div>
      <div>The teachers who teach in the lyceum were all artists themselves but some of them stopped with their artistic work. I think those who are still active as artists teach better.</div>
      <br />
      <div><b>What do you think are the pros and cons of the fact that the educational institution is intensely focused on only one side of art (in your case, academism)</b>?</div>
      <div>In my opinion, there are mostly cons. It is bad to study only one side of art because it is important to let the students know that art is a broad, multifaceted field and that they have many ways to express themselves and their thoughts in different forms and different artistic languages.</div>
      <div>However, the kind of institutions like mine don’t let classical art disappear in the shadows of contemporary art movements. </div>
      <br />
      <div><b>Are there any art academies in your country that support both sides, academism and modern (contemporary) art?</b></div>
      <br />
      <div>I highly doubt that. There are very few art institutions that educate art at a professional level. Perhaps some universities are more loyal to experiments, but basically, as far as I know, most still follow the path of classical art.</div>
      <br />
      <div><b>And how do people who do not connect to art at all think about and perceive it?</b></div>
      <br />
      <div>People who are not related to art take it quite superficially. If they go to see large art museums they rather do it for a ‘bucket-list’ and not out of their own interest. To visit an art exhibition you have to have a certain awareness, either take an audio guide or use another services to gain knowledge about the exhibition, otherwise most of it you will not understand. Most people in my country, unfortunately, have very little time to study art and very hard work that leaves no strength for something else. </div>
      <div>I see. I hope that the economy in your country will allow people to develop culturally in the future.</div>
      <div>Yes, I hope so too.</div>
    </Monospace>
  </Interview>,
  <Interview key={5} index={5} name="5.txt">
    <Monospace fontSize={14} component="article">
      <div>Occupation: graphic designer</div>
      <div>Age: 21</div>
      <div>Country: Ukraine</div>
      <br />
      <div><b>What is your definition of art?</b></div>
      <br />
      <div>Art is a reflection of the soul of an "artist." This is a dialogue between the creator and the viewer. Also, I would like to mention that art is not only specifically drawing, films or music. Anything can be called Art if it was created in order to share a piece of yourself, in order to talk. When I look at an object of art, I can hear the author, I can see what I have not noticed before. Feel what he felt. Thus. I think art is to share your vision, your emotions.</div>
      <br />
      <div><b>Sounds wonderful. What is “good” art for you? Are there any criteria for evaluating an art object?</b></div>
      <br />
      <div>Any vision of a man is his little masterpiece. I don’t think that art can be bad or disgusting. I believe that everyone has their own inner sense of beauty. And if someone sees the beautiful in death - this is the place to be.</div>
      <div>I have an employee who, when she saw my collage with a watermelon dog, said that it reminds her of a cut stomach. I was embarrassed then, but I understand that it is impossible to please everyone at once. Someone sees a cut stomach, and I see a sweetheart watermelon dog. Therefore, no. Art is never bad. Each creation can delight its audience.</div>
      <div>Personally, I really love gloomy things. I’m just the kind of person who feels something mysterious and deep in the negative. I love deep paintings that carry a secret or heavy emotions. Good art for me personally is one that makes me want to stop and peer. If the artist managed to stop my time and start the thought process - I will be delighted. It is cool when you want to think about why the creator did just that. What is he telling you? Questions, questions, questions and emotions should be evoked by art. But again, things that don't catch me can hook someone else. This is the beauty of creation. I believe art does not connect to the word "bad."</div>
      <br />
      <div><b>This way of thinking has its own place. Do you think that a set of skills or craftmanship is an important factor in creating quality work?</b></div>
      <br />
      <div>I would say that I do not believe that in the very first work the creator can manage to fully convey all that he feels and wants to show. I am sure there is such a genius in the world, whose works have shot and become a masterpiece without the help of all these skills and craftmanship. But the reality is that no matter what art you do, you still have to learn to transmit everything that you want to. You need to have knowledge, you need experience, you need to develop skills. This is undoubtedly important and correct. More experience is better.</div>
      <br />
      <div><b>I agree with you. Let's move on to another part of the interview. How often do you go to museums or exhibitions?</b></div>
      <br />
      <div>Unfortunately, less than I would like to. There was more time during my studying and I often went to the exhibitions with my class. Now everything has become a little more complicated. Work, you need to improve your health, I rarely go out, but I actively follow the news of the art world. If something mega cool happens, I definitely go.</div>
      <br />
      <div><b>Do you think the average person in Ukraine is quite interested in art or is it a completely distant environment for them?</b></div>
      <br />
      <div>I do not observe a high interest in art among people of my age. Sometimes this is because they are absolutely far from this and consider it a waste of time. Sometimes you come across people like my boyfriend. He loves art, he loves going to exhibitions and museums. But there is simply nothing to look at. I know a couple of cool places in Kiev, where there are very cool exhibitions, but often the theme is simply not intriguing. Museums have already been revised, the exhibitions openly do not catch, and even such an amateur as my young man simply stays at home. I will not say that our people are not enlightened or very busy. But I would say that this sphere is not developed in our country. I would like more art to surround us. If we see art only in the museum building, this does not really inspire people to be interested in it. Perhaps I speak as a person who works in the field of advertising, but if there was a way to advertise art more effectively among people, it would be great. So let's say. I would like to see more art.</div>
      <div>But in general, the situation is not a complete stalemate, I still see interested people, I still observe progress in art.</div>
      <br />
      <div><b>Perhaps art in Ukraine is not considered to be something important that should be promoted. What is the situation with contemporary art? How easy is it for an artist to exist and work in Ukraine?</b></div>
      <br />
      <div>I can only judge by my personal experience and the experience of my friends. It’s easier to work with overseas customers. It is more profitable. There are a bunch of resources where you can find a customer as a freelancer. There are, of course, Russian-speaking resources. But unfortunately, they often encounter the narrow-mindedness of customers in this area. Very often they devalue the work, say that “it’s as easy as peeling pears”, their critiques deserve a separate discussion. I myself encounter bosses at my work, who can shrug their shoulders and say "I don’t know if this poster is good or not." And I work for mere pennies. Starting a career as an artist / designer is very, very difficult. I try to make something new at my work, but they forbid me to do it.</div>
      <div>I would say that artists, as workers, are not always appreciated here. I came across the opinions that it is easy and everyone will cope. Of course, there are directed companies that allow new talents to discover themselves and show themselves to the world. But breaking into it is very difficult but worth the effort.</div>
      <div>To summarize. Here you are either devalued as a specialist, because "they themselves know the best." Or you earn fame and respect with your work and independent promotion in social networks. Unfortunately, the "successful contemporary artist" I associate only with someone who works abroad.</div>
      <div>But I just can’t understand why there is such a significant difference in the artist’s perception of the profession in Ukraine and abroad. This applies not only to respect in terms of a job, but also to the depreciation of objects of contemporary art.</div>
      <div>I don’t know. I can only assume that such a depreciation has been going on since the days of the USSR. Then people were taught to be the same, not to believe in all kinds of nonsense, to work, not to bother oneself with all sorts of bullshit. The older generation, which is often our bosses or customers, can not understand why we need all this extra husk. My boss even believes that SMM as a profession should not exist, because Internet promotion and advertising is not very smart and everyone can handle it.</div>
      <div>This is the way of thinking of Soviet Union children. How often do we hear from older people that the Internet is a trash bin? Now, of course, a little less. People get used to it and begin to understand the benefits.</div>
      <div>But very often you can see the position "we did not live well and there is no point in starting." An artist, a musician - does not bring such obvious benefits as a locksmith and a doctor. We bring moral benefit, art is for the soul. And nowadays we have forgotten how to appreciate the soul. We have always valued a more significant profession.</div>
      <div>For example, psychologists are not particularly popular amongst Ukrainian people because of the opinion that we ourselves have come up with everything and you don’t have any depression. Mental and moral things have been depreciated. But I see the progress, I see that we are slowly moving towards the correct perception of ourselves and art. The older generation is being replaced by the younger. And with the young the progress comes. There are older people who also appreciate and respect art. And this is awesome. But unfortunately or fortunately, it is young people who will rule new companies that will promote art to the masses.</div>
      <br />
      <div><b>That is all very interesting. Young people bring hope to the future of art in the world. By the way, how often have you come across a misunderstanding of your art?</b></div>
      <br />
      <div>Ohhh, I said above that I love dark art. And my work has always been that way. If you take things that I create for myself, and not for making money, then there are often terrible things that people look at in fear. I love to cause disgust and fear. This, you see, is not liked by many. I often hear only "very well made, but the story..."</div>
      <div>This does not bother me. I realize that my art is not for everyone.</div>
      <div>If we talk about my job, it’s very ironic, because I work in the center of health, meditation and yoga. Every day I make posters for spiritual, bright people who carry love and goodness. I make hundreds of positive collages for articles about health and happiness. And yes, sometimes I myself do not notice how in my seemingly good collages people see all sorts of terrible things and say that I should work as a pathologist. So, I often encounter a lack of understanding in the direction of my work. But it even amuses me. Perhaps this is the meaning of my work.</div>
      <br />
      <div><b>Very interesting contrast between what interests you and what you have to do for your job. Did you study in an art institution before?</b></div>
      <br />
      <div>Yes. Since childhood, I have been close to art. But I was involved in music, dancing and theater scenes. Nobody taught me drawing. My career as an artist began with drawing anime characters at home. I just wanted to be able to draw like they do in manga. This pushed me to a deeper study of art, and after school I entered the graphic designer department, studied there for four years, and here I am.</div>
      <br />
      <div><b>My last request will be for you to tell me a little about the educational system at the Ukrainian university where you studied. What things were especially encouraged there? What was missing?</b></div>
      <br />
      <div>Oh, to be honest, I have to try very hard to find the advantages of my college. I do not want to say the name of my educational institution, but I will say that this is a popular college in Ukraine from the budget options.</div>
      <div>A graphic designer is a progressive profession. To get a job in the real labour market, we need to know a very large number of programs that concern even video editing. What were they teaching us in the first two courses? Excel. Seriously, we studied Word and Excel for two years. For the first two years, they stretched the basics as best as they could. Indeed, only a couple of teachers taught us anything. I can be grateful to them for the fact that they really do their job with high quality and do not hammer on students. The majority of tutors decided everything for money and they absolutely did not care how you study.</div>
      <div>To sit down and take a course on the Internet myself was three times more effective than spending four years on programs and knowledge that I have never really needed at work. I would have liked to have had more subjects that would teach the realities of the modern world. They mentioned everything super quickly, but did not teach anything. After graduating from college, I had to independently study everything that they had to teach.</div>
      <div>We had no practical classes, they turned a blind eye and the majority of the students simply paid for the grades. Corruption. We were not taken to exhibitions. We were not given complicated homework that would be interesting. I did not see interest in the eyes of my classmates. I did not see the opportunities or the desire to teach us. It was very frustrating and discouraged any desire to go there.</div>
      <div>So, I saw not the most positive side of training for a creative profession. The only thing I would like to note is that in our country there are quite a lot of teachers who are just as frankly sad for what is happening in such institutions. I saw many professionals who were disappointed and left college because they simply had their hands tied and were not given freedom to teach their students. Although their methods worked! I hope this changes. I really hope for it.</div>
      <div>But for now, taking a course on the Internet turns out to be cheaper, less energy consuming and more effective.</div>
    </Monospace>
  </Interview>,
];
