import React, { useState } from 'react';
import styled from 'styled-components';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { fontFamily } from './CD';

const Icon = styled.img`
  user-select: none;
`;

const Name = styled.span`
  ${fontFamily};
  display: block;
  margin-top: 4px;
  line-height: 1;
  padding: 3px 2px 2px 4px;
  user-select: none;
`;

const Wrapper = styled.div`
  width: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &[data-selected="true"] {
    ${Icon} {
      filter: hue-rotate(180deg) contrast(0.5) saturate(2);
    }

    ${Name} {
      background-color: #0000a2;
      color: #fff;
      outline: 1px dotted #ffffff;
      outline-offset: -1px;
    }
  }
`;

const ExplorerIcon = ({ icon = '/images/win98_folder_open.png', name, ...restProps }) => {
  const [selected, setSelected] = useState(false);

  const onClick = () => {
    setSelected(true);
  };

  const onClickAway = () => {
    setSelected(false);
  };

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div {...restProps}>
        <Wrapper data-selected={selected} onClick={onClick}>
          <Icon src={icon} />
          <Name>
            {name}
          </Name>
        </Wrapper>
      </div>
    </ClickAwayListener>
  );
};

export default ExplorerIcon;
