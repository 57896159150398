import React from 'react';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import Monospace from '../../../../../../Monospace';
import ReadMore from '../../../../../../ReadMore';

export const StyledBox = styled(Box)`
  background: #C4C4C4;
  border: 2px solid #000000;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: ${props => props.theme.spacing(1)}px;
  position: absolute;
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${Monospace} {
    line-height: 1.33;
    letter-spacing: -0.05em;
  }
`;

const Source = styled(Monospace)`
  font-weight: 600 !important;
  margin-top: 32px !important;
`;

const TextBox = ({ content, source, sourceHref, ...restProps }) => {
  return (
    <Grow in timeout={1000}>
      <StyledBox {...restProps}>
        <Monospace>
          {content}
        </Monospace>
        <Source>
          <ReadMore href={sourceHref} target="_blank" rel="noreferrer noopener">
            {source}
          </ReadMore>
        </Source>
      </StyledBox>
    </Grow>
  );
};

export default TextBox;
