import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import styled, { keyframes, createGlobalStyle } from 'styled-components';
import Rellax from 'rellax';
import { useHistory } from 'react-router-dom';
import GridCarousel from './GridCarousel';
import GridImage from './GridImage';

const stroke = keyframes`
  0% {
    stroke-dashoffset: -1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
`;

const Svg = styled.svg`
  display: block;

  path {
    stroke: black;
    stroke-width: ${props => props.in ? 2 : 0};
    transition: ${props => props.theme.transitions.create(['stroke-width'], { duration: 1000 })};
  }
`;

const GridSegments = styled.g`
  path {
    stroke-dasharray: 1000;
    stroke-dashoffset: -1000;
    animation: ${stroke} 3s ease-out forwards;
  }
`;

const Pulsatiles = styled.g`
  path {
    transform-origin: center center;
    cursor: pointer;
  }
`;

const Pink = styled.path`
  animation: ${keyframes`
    0% {
      transform: scale3d(1, 1, 1) translate(0%, 0%);
    }
    50% {
      transform: scale3d(1.1, 1.1, 1) translate(-2%, 4%);
    }
    100% {
      transform: scale3d(1, 1, 1) translate(0%, 0%);
    }
  `} 3s infinite;
`;

const Yellow = styled.path`
  animation: ${keyframes`
    0% {
      transform: scale3d(1, 1, 1) translate(0%, 0%);
    }
    50% {
      transform: scale3d(1.1, 1.1, 1) translate(2%, 1%);
    }
    100% {
      transform: scale3d(1, 1, 1) translate(0%, 0%);
    }
  `} 3s infinite;
`;

const Green = styled.path`
  animation: ${keyframes`
    0% {
      transform: scale3d(1, 1, 1) translate(0%, 0%);
    }
    50% {
      transform: scale3d(1.1, 1.1, 1) translate(-2%, -1%);
    }
    100% {
      transform: scale3d(1, 1, 1) translate(0%, 0%);
    }
  `} 3s infinite;
`;

const Lime = styled.path`
  animation: ${keyframes`
    0% {
      transform: scale3d(1, 1, 1) translate(0%, 0%);
    }
    50% {
      transform: scale3d(1.1, 1.1, 1) translate(0%, -4%);
    }
    100% {
      transform: scale3d(1, 1, 1) translate(0%, 0%);
    }
  `} 3s infinite;
`;

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${props => props.bgColor || 'transparent'} !important;
    transition: ${props => props.theme.transitions.create(['background-color'], { duration: 1000 })};
  }

  .react-images__view {
    img {
      max-height: 90vh;
    }
  }
`;

const Images = styled.g`
  image {
    cursor: pointer;
  }
`;

const Grids = () => {
  const history = useHistory();
  const [devour, setDevour] = useState();
  const pushTimeout = useRef();
  const onDevour = useCallback(event => {
    setDevour(event.target.getAttribute('fill'));
    const { to } = event.target.dataset;
    if (to) setTimeout(() => history.push(to), 2100);
  }, [history]);
  useEffect(() => () => clearTimeout(pushTimeout.current), []);

  const [drawingDone, setDrawingDone] = useState(false);
  const onDrawingDone = useCallback(() => setDrawingDone(true), []);

  const [imgOpen, setImgOpen] = useState();
  const onCloseImg = useCallback(() => setImgOpen(null), []);
  const onOpenImg = useCallback(event => setImgOpen(event.target.dataset.id), []);

  useEffect(() => {
    new Rellax('.reimg', { speed: 0.5, center: true });
  }, []);

  const gridSegments = useMemo(() => (
    <GridSegments>
      <path pathLength={1000} d="M762 1065.5L381.5 1277M772 1078L413.5 1311M787 1091.5L496.5 1308M797 1105.5L588 1308" />
      <path pathLength={1000} d="M549 879.5H584M530.5 907.5L606.5 903M508.5 940.5L630.5 924.5M485 975L652 949.5M462.5 1010.5L671.5 970M426.5 1066.5L690 991M390 1123.5L713.5 1014M349 1185L732 1031.5M299.5 1256.5L746.5 1051.5" />
      <path pathLength={1000} d="M550 879.5L640.5 1338.5M531 908L614.5 1338.5M509.5 940.5L591 1341.5M485.5 974.5L564.5 1338.5M462.5 1011L541.5 1338.5M426.5 1066.5L493 1338.5M390.5 1121.5L442.5 1338.5M349.5 1185.5L394 1338.5" />
      <path pathLength={1000} d="M-103 -3L873 1161M1 -3L899 1161M141 -3L923 1161M275 -3L291.165 25M947 1161L403.742 220M403 -3L971 1161M533 -3L995 1161M1015 1161L761.845 284M-115 145L-74.9843 187M853 1161L138.433 411M-74.9843 187L291.165 25M-74.9843 187L-28.2992 236M291.165 25L699.206 67M291.165 25L315.412 67M699.206 67L679 -3L253 -47L-166 94M699.206 67L715.948 125M-28.2992 236L315.412 67M-28.2992 236L25.0551 292M315.412 67L715.948 125M315.412 67L339.082 108M715.948 125L733.845 187M25.0551 292L339.082 108M25.0551 292L63.1653 332M339.082 108L733.845 187M339.082 108L360.443 145M733.845 187L747.99 236M63.1653 332L360.443 145M63.1653 332L98.4173 369M360.443 145L747.99 236M360.443 145L381.227 181M747.99 236L761.845 284M98.4173 369L381.227 181M98.4173 369L138.433 411M381.227 181L761.845 284M381.227 181L403.742 220M138.433 411L403.742 220" />
      <path pathLength={1000} d="M403 221L773 320M176 451L422 255L783 354M201 477L439 280L792 391M224 502L454 309L805 437M247 524L472 341L817 477M269 549L490 368L831 524M291 572L502 391L841 558M319 600L515 415L850 585M347 629L530 437L856 611M367 652L544 463L870 652M396 680L556 485L879 691M421 709L573 512L894 739M445 734L593 549L905 778M471 761L612 580L915 818M500 789L631 611L927 855M520 812L652 652L944 915M544 837L672 691L961 972M564 860L691 720L704.427 734M972 1013L704.427 734M585 879L704.427 734M704.427 734L980 1047M607 906L715 761L990 1084M631 925L733 789M652 950L744 812L1007 1130M672 972L759 837M691 990L774 860M715 1013L785 879M733 1031L797 899M751 1047L805 915L1016 1160M759 1068L819 936M774 1076L828 956M785 1094L838 972L1007 1160M797 1106L847 990M810 1119L856 1004L990 1160M824 1133L870 1019M838 1141L879 1031M847 1153L884 1055L980 1160M865 1160L894 1068M884 1160L905 1084M905 1160L915 1106M915 1106L966 1160M915 1106L918.5 1133M936 1169L927 1130L922 1160L918.5 1133M918.5 1133L956 1160" />
      <path pathLength={1000} d="M511.5 803.5L104.5 1233.5M469.5 761.5L21.5 1223.5M434.5 723.5L-102 1233.5M403 688.5L-252 1233.5M376.5 660L-347 1203.5M343 626.5L-357 1085M311.5 593.5L288 606.032M-357 950L288 606.032M539.5 831.5L216.5 1233.5M563 858.5L548.955 880M311.5 1243.5L548.955 880M288 606.032L548.955 880" />
      <path pathLength={1000} d="M256.5 625L531.5 908.5M208 648.5L508 940M168 670L484.5 975M133 690L461.5 1010M79.5 716.5L428 1068.5M31.5 743.5L391.5 1123.5M-40.5 778.5L349.5 1185M-130.5 828.5L296.5 1255M-210.5 871.5L219.5 1288.5M-312 925L121.5 1321.5M-392 966.5L16.5 1346.5" />
      <path pathLength={1000} d="M288.5 607L293 571.5M269 548L257.5 624.5M209 648.5L245 525M223.5 501.5L169 670.5M133.5 690.5L200 477M82 717L176.5 451M32.5 743L138 411M-38 781L97.5 368M-130.5 829L63 332M24.5 291.5L-209 871M-29.5 236.5L-310.5 925.5" />
      <path pathLength={1000} d="M311 594L-351 873M311 594L-346 781M311 594L-339 689M311 594L-356 579M311 594L-346 447M311 594L-260 362M311 594L-186 243" />
      <path pathLength={1000} d="M699 67L1242 -30M715 124L1258 19M732 188L1318 67M747 236L1343 112M761 285L1367 159M771 319L1387 194M782 355L1422 229M791 392L1442 259M804 437L1456 293M815 477L1492 333M829 524L1530 369M842 557L1559 392M850 585L1590 429M855 611L1603 453M865 649L1609 485M878 691L1623 524M892 737L1633 557M903 778L1638 593M915 818L1662 628M923 853L1677 649M942 914L1711 685M959 968L1731 728M972 1012L1731 766M981 1047L1731 800M989 1083L1731 825M1004 1128L1725 853M1015 1160L1731 886M771 -30L1040 1171M878 -49L1072 1171M989 -67L1116 1183M1092 -85L1154 1183M1204 -101V1183M1318 -30L1258 1183L1367 57L1300 1171M1456 81L1343 1183M1538 105L1406 1183M1590 259L1456 1171M1638 369L1510 1183" />
      <path pathLength={1000} d="M837.5 1170.5L1062 1241.5M813 1170.5L1062 1261M1062 1261L1056.5 1281.5M1062 1261L1056.5 1216.5L1019.56 1206.5M791.5 1179L1056.5 1281.5M1056.5 1281.5V1507.5M769 1206.5L1056.5 1316.5M740.5 1210.5L1062 1362M718 1235.5L1062 1408.5M696 1263.5L1059.5 1466.5M665 1278.5L1056.5 1507.5M1056.5 1507.5L1021 1581.5L1003.5 1570.32M633.5 1299L842.5 1151M667.5 1355.58L640 1338L851.5 1161L866 1164.93M667.5 1355.58L866 1164.93M667.5 1355.58L696 1373.79M866 1164.93L878.5 1168.31M696 1373.79L878.5 1168.31M696 1373.79L718 1387.85M878.5 1168.31L892.5 1172.1M718 1387.85L892.5 1172.1M718 1387.85L740.5 1402.23M892.5 1172.1L904.5 1175.35M740.5 1402.23L904.5 1175.35M740.5 1402.23L794.903 1437M904.5 1175.35L923.5 1180.49M794.903 1437L923.5 1180.49M794.903 1437L850 1472.21M923.5 1180.49L949 1187.4M850 1472.21L949 1187.4M850 1472.21L919 1516.31M949 1187.4L975.5 1194.57M919 1516.31L975.5 1194.57M919 1516.31L1003.5 1570.32M975.5 1194.57L1019.56 1206.5M1003.5 1570.32L1019.56 1206.5" />
      <path pathLength={1000} d="M1005.5 1159.5L1056 1216.5M1056 1216.5L1014 1159.5M1056 1216.5L1040 1170" />
      <path pathLength={1000} d="M601 1689L608.229 1709M884 2472L864.483 2418M661 1673L977 2472M711 1660L1048 2472M756 1648L1125 2466M793 1639L1202 2466M831 1630L1300 2466M863 1622L1388 2466M893 1613L1492 2466M926 1605L1452 2269M948 1600L1502 2249M970 1593L1570 2237M991 1586L1614 2215M1019 1580L1032.63 1593M1685 2215L1394 1937.55M608.229 1709L1032.63 1593M608.229 1709L618.71 1738M1032.63 1593L1045.22 1605M618.71 1738L1045.22 1605M618.71 1738L634.613 1782M1045.22 1605L1063.05 1622M634.613 1782L1063.05 1622M634.613 1782L656.299 1842M1063.05 1622L1086 1643.88M656.299 1842L1086 1643.88M656.299 1842L673.648 1890M1086 1643.88L1110 1666.76M673.648 1890L1110 1666.76M673.648 1890L700.393 1964M1110 1666.76L1133.32 1689M700.393 1964L1133.32 1689M700.393 1964L733.284 2055M1133.32 1689L1168 1722.06M733.284 2055L1168 1722.06M733.284 2055L771.234 2160M1168 1722.06L1210 1762.11M771.234 2160L1210 1762.11M771.234 2160L813.16 2276M1210 1762.11L1260 1809.78M813.16 2276L1260 1809.78M813.16 2276L864.483 2418M1260 1809.78L1323 1869.85M864.483 2418L1323 1869.85M1323 1869.85L1394 1937.55M901 2522L1394 1937.55" />
      <path pathLength={1000} d="M546.5 1701L609.5 1708M484.5 1718L619.5 1736.5M423.5 1732.5L634.5 1782M355 1750L656 1842M294.5 1765.5L674.5 1889.5M227.5 1784.5L700.5 1962.5M139.5 1805.5L732.5 2054.5M44.5 1830L772 2159.5M814 2275L601 2170.72M-50 1852L55 1903.41M582 1694L55 1903.41M55 1903.41L182 1965.58M601 1688L182 1965.58M601 1688L341 2043.43M601 1688V2170.72M182 1965.58L341 2043.43M341 2043.43L601 2170.72" />
      <path pathLength={1000} d="M584 879.5L665 1279.5M607.5 905L696 1264.5M629.5 926L718.5 1236.5M651.5 949.5L740.5 1212M673 971L769.5 1208M690.5 990L791.5 1181M714 1014.5L812.5 1171M746.5 1050.5L837.5 1171" />
      <path pathLength={1000} d="M188.5 1445L394.5 1339.5L295.5 1254.5M443 1339.5L194.5 1469.5M492.5 1339.5L209.5 1488M541.5 1339.5L214.5 1517M565.5 1339.5L239 1540.5M592 1339.5L284 1547M615 1339.5L345 1555.5M641 1339.5L399 1570.5" />
      <path pathLength={1000} d="M383 1276.5L341.5 1294M341.5 1294L311.5 1242M341.5 1294L295 1390.5M414.5 1311L378 1324M378 1324L220 1288.5M378 1324V1346.5M220 1288.5L216.5 1233.5L271 1248.6M220 1288.5L79.5 1428.5L120 1321.5L392.5 1338.5L90 1401.5M365.5 1315L357 1358.5M350.5 1303L319.5 1379M327 1282.5L259.5 1408.5M315 1271L224 1425.5M184 1447L299.5 1256.5L271 1248.6M271 1248.6L126 1447" />
      <path pathLength={1000} d="M378 1348L621.5 1357.5M357 1357.5L607 1372M322 1376L586 1392.5M295.5 1389L564 1412.5M261.5 1408L538.5 1437.5M224 1425.5L507 1467.5M186.5 1445L475 1497M442.5 1529.5L179 1472M418 1553L166 1499.5" />
      <path pathLength={1000} d="M863.5 1164V1159M874 1166.5L871 1159M881 1169L883.5 1157M897 1159L903.042 1166.5M911.5 1177L903.042 1166.5M904.5 1159L903.042 1166.5M920.5 1159L935.5 1184L934.5 1167M946 1161L963 1190.5M954.5 1159L1000 1200.5M964.5 1159L1016 1205L968 1159M978.5 1159L1030 1208.5M988.5 1159L995.856 1166.5M1039.5 1211L995.856 1166.5M993.5 1159L995.856 1166.5" />
      <path pathLength={1000} d="M622 1358L668.5 1355M605.5 1372.5H697M584.5 1393.5L718 1387.5M563.5 1412.5L740 1402M537 1438H795M506 1467.5L851 1472.5M475 1498L919.5 1515.5M441 1529.5L1002.5 1569.5M417 1553.5L498.5 1557.21M1021.5 1581L821.5 1571.9M641.5 1340L498.5 1557.21M641.5 1340L579.5 1560.89M641.5 1340L668.5 1564.94M641.5 1340L821.5 1571.9M498.5 1557.21L579.5 1560.89M579.5 1560.89L668.5 1564.94M668.5 1564.94L821.5 1571.9" />
      <path pathLength={1000} d="M1020 1581L54 1625M1020 1581L21 1674M1020 1581L-9 1751M1020 1581L972 1593.29M-50 1855L45 1830.67M972 1593.29L963 1581M972 1593.29L949 1599.18M949 1599.18L927 1576M949 1599.18L927 1604.81M927 1604.81L894 1576M927 1604.81L894 1613.27M894 1613.27L858 1576M894 1613.27L864 1620.95M864 1620.95L812 1571M864 1620.95L831 1629.4M831 1629.4L776 1571M831 1629.4L792 1639.39M792 1639.39L732 1571M792 1639.39L755 1648.86M755 1648.86L677 1565M755 1648.86L712 1659.87M712 1659.87L628 1565M712 1659.87L662 1672.67M662 1672.67L568 1560M662 1672.67L608 1686.5M608 1686.5L509 1560M608 1686.5L548 1701.87M548 1701.87L438 1554M548 1701.87L485 1718M485 1718L398 1571M485 1718L424 1733.62M424 1733.62L346 1554M424 1733.62L355 1751.29M355 1751.29L283 1547M355 1751.29L295 1766.65M295 1766.65L240 1539M295 1766.65L228 1783.81M228 1783.81L126 1446M228 1783.81L140 1806.35M140 1806.35L83 1426M140 1806.35L45 1830.67M45 1830.67L15 1345" />
    </GridSegments>
  ), []);

  const pulsatiles = useMemo(() => (
    <Pulsatiles>
      <Pink onClick={onDevour} data-to="/read/pinky/space" d="M764.904 163.677L767.792 160.671L770.742 163.505C773.44 163.726 777.96 162.08 774.454 153.734C768.162 153.47 771.73 151.118 767.19 146.757C762.65 142.397 766.485 143.857 763.411 129.791C759.33 126.316 758.886 126.778 754.38 125.562C749.875 124.347 744.732 126.52 740.927 127.755C737.122 128.991 734.487 134.461 732.71 136.311C730.933 138.161 730.282 140.202 727.656 146.571C725.03 152.94 729.062 151.922 742.943 168.367C745.198 169.199 749.467 169.299 751.036 169.028C752.606 168.757 757.763 167.931 764.904 163.677Z" fill="#F29CEA" />
      <Yellow onClick={onDevour} data-to="/read/potato" d="M267.021 789.99L279.465 792.923C282.178 793.32 290.008 794.001 299.629 793.548C302.636 797.972 300.968 802.234 302.229 806.446C303.49 810.658 301.686 811.656 297.439 826.969C294.041 839.219 290.178 835.962 288.671 832.803C284.591 830.413 276.628 825.17 277.42 823.318C278.409 821.003 279.631 813.845 278.312 810.844C277.256 808.443 270.455 809.889 267.187 810.912L259.311 804.273L267.021 789.99Z" fill="#FFE712" />
      <Green onClick={onDevour} data-to="/read/pinky/green" d="M706.123 1243.57C704.582 1246.27 705.481 1246.95 706.123 1246.95L703.671 1251.84C704.488 1250.21 704.792 1248.31 699.467 1253.72C698.416 1255.6 699.467 1256.54 699.467 1259.93C699.467 1263.31 700.343 1261.25 703.671 1262.56C706.999 1263.88 707.524 1262 713.655 1259.93C719.785 1257.86 715.406 1257.48 716.282 1255.04C717.158 1252.59 716.983 1251.84 718.209 1249.4C719.435 1246.95 717.858 1247.89 721.011 1246.01C724.164 1244.13 725.74 1248.08 728.193 1251.84C730.645 1255.6 728.368 1253.72 729.944 1257.48C731.52 1261.25 732.922 1257.48 737.125 1257.48C741.329 1257.48 739.928 1256.92 743.606 1253.72C747.284 1250.52 744.482 1251.65 745.533 1246.95C746.584 1242.25 745.533 1243.57 745.533 1238.49C745.533 1233.41 743.256 1234.16 741.68 1230.21C740.103 1226.26 740.453 1229.08 734.148 1225.7C727.842 1222.31 731.871 1225.13 725.74 1224.57C719.61 1224.01 722.412 1225.51 718.209 1227.95C714.005 1230.4 713.83 1231.34 711.027 1234.16C708.225 1236.98 708.05 1240.18 706.123 1243.57Z" fill="#52FF00" />
      <Lime onClick={onDevour} data-to="/read/pinky/lime" d="M574.77 1845.85C572.072 1844.47 566.756 1842.69 565.401 1842.15C564.338 1842.3 562.17 1842.72 562.004 1843.28C561.797 1843.97 563.899 1846.8 564.643 1847.67C565.386 1848.53 565.426 1849.03 566.549 1850.88C567.673 1852.73 568.99 1854.21 573.69 1860.93C576.692 1865.19 573.7 1866.68 574.147 1868.99C574.594 1871.29 574.149 1870.42 566.098 1875.7C558.047 1880.97 562.966 1871.69 563.675 1867.43C564.383 1863.17 564.254 1863.42 563.749 1858.06C563.245 1852.69 561.853 1853.09 558.653 1847.78C555.453 1842.46 556.11 1845.51 550.911 1843.8C545.712 1842.08 547.452 1846.48 546.154 1849.01C544.857 1851.53 543.933 1852.17 541.612 1857.84C539.292 1863.51 540.799 1861.73 540.689 1865.98C540.58 1870.23 540.724 1871.11 541.381 1874.16C542.037 1877.21 542.843 1876.5 544.945 1879.34C547.046 1882.18 549 1882.7 553.223 1884.87C557.446 1887.04 555.177 1885.4 560.518 1886.55C565.859 1887.7 563.266 1886.69 567.771 1886.29C572.276 1885.89 570.164 1884.81 576.773 1881.19C583.381 1877.56 580.264 1874.68 582.028 1870.96C583.793 1867.24 583.191 1865.82 582.817 1860.2C582.442 1854.58 581.793 1855.85 580.431 1851C579.068 1846.15 577.468 1847.24 574.77 1845.85Z" fill="#20B881" />
    </Pulsatiles>
  ), [onDevour]);

  const images = useMemo(() => (
    <Images>
      <GridImage id={1} onClick={onOpenImg} />
      <GridImage id={2} onClick={onOpenImg} />
      <GridImage id={3} onClick={onOpenImg} />
      <GridImage id={4} onClick={onOpenImg} />
      <GridImage id={5} onClick={onOpenImg} />
      <GridImage id={6} onClick={onOpenImg} />
      <GridImage id={7} onClick={onOpenImg} />
    </Images>
  ), [onOpenImg]);

  const onExited = () => console.log('todo: history.push');

  return (
    <>
      <GlobalStyle bgColor={devour} />
      <GridCarousel id={imgOpen} onClose={onCloseImg} />
      <Fade in={!devour} timeout={2000} onExited={onExited}>
        <Box position="relative">
          <Svg in={!devour} onAnimationEnd={onDrawingDone} width="100vw" viewBox="0 0 1054 2013" fill="none" xmlns="http://www.w3.org/2000/svg">
            {gridSegments}
            <Fade in={drawingDone} timeout={3000}>
              {pulsatiles}
            </Fade>
            <Fade in={drawingDone} timeout={1000}>
              {images}
            </Fade>
          </Svg>
        </Box>
      </Fade>
    </>
  );
};

export default Grids;
