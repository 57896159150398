import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Collapse from '@material-ui/core/Collapse';
import styled from 'styled-components';
import Listen from './Listen';
import Synonyms from './Synonyms';

const DomainLabel = styled(Typography).attrs({ variant: 'caption' })`
  display: inline-block;
  background-color: rgba(128, 128, 128, 0.2);
  padding: 0 6px;
  text-transform: uppercase;
`;

const SourceLabel = styled(Typography).attrs({ color: 'textSecondary', variant: 'body2' })`
  text-decoration: none;
`;

const Footer = styled(Box)`
  cursor: pointer;
`;

const Definition = React.forwardRef(function Definition(props, ref) {
  const [showMore, setShowMore] = useState(false);
  const onClick = () => setShowMore(prev => !prev);

  return (
    <Box ref={ref} {...props}>
      <Paper variant="outlined">
        <Box padding={2} maxWidth={680}>
          <Box display="flex" alignItems="center">
            <Listen />
            <Box marginLeft={2}>
              <Typography variant="h4">
                perception
              </Typography>
              <Typography color="textSecondary">
                /pəˈsɛpʃ(ə)n/
              </Typography>
            </Box>
          </Box>
          <Typography component="i">
            noun
          </Typography>
          <Collapse in={showMore}>
            <Typography color="textSecondary">
              noun: <b>perception</b>; plural noun: <b>perceptions</b>
            </Typography>
          </Collapse>
          <ol>
            <li>
              <Box>
                <Typography>
                  the ability to see, hear, or become aware of something through the senses.
                </Typography>
                <Typography color="textSecondary">
                  "the normal limits to human perception"
                </Typography>
                <Collapse in={showMore}>
                  <ul>
                    <li>
                      <Box marginTop={0.5}>
                        <Typography>
                          awareness of something through the senses.
                        </Typography>
                        <Typography color="textSecondary">
                          "the perception of pain"
                        </Typography>
                      </Box>
                      <Synonyms
                        words={[
                          'discernment',
                          'appreciation',
                          'recognition',
                          'realization',
                          'cognizance',
                          'awareness',
                          'consciousness',
                          'knowledge',
                          'acknowledgement',
                          'grasp',
                          'understanding',
                          'comprehension',
                          'interpretation',
                          'apprehension',
                          'impression',
                          'sense',
                          'sensation',
                          'feeling',
                          'observation',
                          'picture',
                          'notion',
                          'thought',
                          'belief',
                          'conception',
                          'idea',
                          'judgement',
                          'estimation',
                        ]}
                      />
                    </li>
                    <li>
                      <Box marginTop={0.5}>
                        <DomainLabel>
                          Psychology • Zoology
                        </DomainLabel>
                        <Typography>
                          the neurophysiological processes, including memory, by which an organism becomes aware of and interprets external stimuli.
                        </Typography>
                      </Box>
                    </li>
                  </ul>
                </Collapse>
              </Box>
            </li>
            <li>
              <Box paddingTop={1}>
                <Typography>
                  the way in which something is regarded, understood, or interpreted.
                </Typography>
                <Typography color="textSecondary">
                  "Hollywood's perception of the tastes of the American public"
                </Typography>
                <Collapse in={showMore}>
                  <ul>
                    <li>
                      <Box marginTop={0.5}>
                        <Typography>
                          intuitive understanding and insight.
                        </Typography>
                        <Typography color="textSecondary">
                          "‘He wouldn't have accepted,’ said my mother with unusual perception"
                        </Typography>
                      </Box>
                      <Synonyms
                        words={[
                          'insight',
                          'perceptiveness',
                          'percipience',
                          'perspicacity',
                          'perspicuity',
                          'understanding',
                          'keenness',
                          'sharpness',
                          'sharp-wittedness',
                          'quick-wittedness',
                          'intelligence',
                          'intuition',
                          'cleverness',
                          'incisiveness',
                          'trenchancy',
                          'wit',
                          'astuteness',
                          'shrewdness',
                          'acuteness',
                          'acuity',
                          'subtlety',
                          'clarity',
                          'discrimination',
                          'discernment',
                          'sensitivity',
                          'penetration',
                          'thoughtfulness',
                          'profundity',
                        ]}
                      />
                    </li>
                  </ul>
                </Collapse>
              </Box>
            </li>
          </ol>
          <Collapse in={showMore}>
            <Typography color="textSecondary" variant="h6">
              Origin
            </Typography>
            <Box marginY={1}>
              <img src="/images/perception-origin.png" alt="" />
            </Box>
            <Typography>
              late Middle English: from Latin <i>perceptio(n- )</i>, from the verb <i>percipere</i> ‘seize, understand’
              (see <a href="https://www.google.com/search?q=define+discernment" target="_blank" rel="noreferrer noopener">perceive</a>).
            </Typography>
          </Collapse>
        </Box>
        <Footer
          borderTop={1}
          borderColor="divider"
          paddingX={2}
          paddingY={1}
          display="flex"
          alignItems="center"
          color="text.secondary"
          onClick={onClick}
        >
          <Box marginRight={1} display="inline-flex">
            {showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Box>
          Show {showMore ? 'Less' : 'More'}
        </Footer>
      </Paper>
      <Box marginX={2} marginY={1}>
        <SourceLabel
          component="a"
          href="http://english.oxforddictionaries.com/perception"
          target="_blank"
          rel="noreferrer noopener"
        >
          From Oxford
        </SourceLabel>
      </Box>
    </Box>
  );
});

export default Definition;
