import React, { useRef, useEffect } from 'react';
import Rellax from 'rellax';
import Box from '@material-ui/core/Box';
import text4 from './text4.svg';
import text5 from './text5.svg';
import img_donut from './img_donut.png';
import img_drawing from './img_drawing.png';
import img_museum from './img_museum.png';
import text_dan_walsh from './text_dan_walsh.svg';
import text_date from './text_date.svg';
import text_museum from './text_museum.svg';
import text_what from './text_what.svg';
import white_stroke from './white_stroke.svg';
import white_stroke2 from './white_stroke2.svg';

const Section4n5 = () => {
  const dateRef = useRef();
  const museumRef = useRef();
  const museumTextRef = useRef();
  const drawingRef = useRef();
  const danWelshRef = useRef();
  const donutRef = useRef();
  const whiteStrokeRef = useRef();
  const textWhatRef = useRef();
  const whiteStroke2Ref = useRef();
  useEffect(() => {
    new Rellax(dateRef.current,         { speed: -0.5, center: true });
    new Rellax(museumRef.current,       { speed: -0.6, center: true });
    new Rellax(museumTextRef.current,   { speed: -0.8, center: true });
    new Rellax(drawingRef.current,      { speed:  0.3, center: true });
    new Rellax(danWelshRef.current,     { speed: -0.3, center: true });
    new Rellax(donutRef.current,        { speed: -1.2, center: true });
    new Rellax(whiteStrokeRef.current,  { speed:  0.2 });
    new Rellax(textWhatRef.current,     { speed: -0.8, center: true });
    new Rellax(whiteStroke2Ref.current, { speed: -0.2 });
  }, []);

  return (
    <Box position="relative" marginTop="1100px">
      <Box ref={dateRef} position="absolute" top={80} left={417}>
        <img src={text_date} />
      </Box>
      <Box ref={whiteStrokeRef} position="absolute" top={-25} left={118}>
        <img src={white_stroke} />
      </Box>
      <Box ref={whiteStroke2Ref} position="absolute" top={200} left={395}>
        <img src={white_stroke2} />
      </Box>
      <Box ref={textWhatRef} position="absolute" top={-40}>
        <img src={text_what} />
      </Box>
      <Box ref={museumTextRef} position="absolute" top={60} left={422}>
        <img src={text_museum} />
      </Box>
      <Box ref={museumRef} position="absolute" top={20} left={583}>
        <img src={img_museum} width={204} />
      </Box>
      <Box ref={drawingRef} position="absolute" top={170} left={513}>
        <img src={img_drawing} width={161} />
      </Box>
      <Box ref={danWelshRef} position="absolute" top={281} left={680}>
        <img src={text_dan_walsh} />
      </Box>
      <Box position="absolute" left={28}>
        <img src={text4} />
      </Box>
      <Box position="absolute" top={261} left={100}>
        <img src={text5} />
      </Box>
      <Box ref={donutRef} position="absolute" top={100} left={520}>
        <img src={img_donut} width={58} />
      </Box>
    </Box>
  );
};

export default Section4n5;
