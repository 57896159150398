import React, { lazy, Suspense } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Home from './Subpages/Home';
import Pepper from './Subpages/Pepper';
import Blueberry from './Subpages/Blueberry';
import Pitaya1 from './Subpages/Pitaya1';
import BlueberryBoomer from './Subpages/BlueberryBoomer';
import Variation1i from './Subpages/Variation1i';
import Variation1 from './Subpages/Variation1';
import Drawings from './Subpages/Drawings';

const Pitaya = lazy(() => import('./Subpages/Pitaya'));

const ArtInUkraine = () => {
  const match = useRouteMatch();
  return (
    <Suspense fallback="loading..">
      <Switch>
        <Route path={`${match.url}/pepper`}>
          <Pepper />
        </Route>
        <Route exact path={`${match.url}/blueberry`}>
          <Blueberry />
        </Route>
        <Route path={`${match.url}/blueberry/boomer`}>
          <BlueberryBoomer />
        </Route>
        <Route exact path={`${match.url}/blueberry/variation1`}>
          <Variation1 />
        </Route>
        <Route path={`${match.url}/blueberry/variation1/i`}>
          <Variation1i />
        </Route>
        <Route path={`${match.url}/pitaya1`}>
          <Pitaya1 />
        </Route>
        <Route path={`${match.url}/pitaya`}>
          <Pitaya />
        </Route>
        <Route path={`${match.url}/drawings`}>
          <Drawings />
        </Route>
        <Route>
          <Home />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default ArtInUkraine;
