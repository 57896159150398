/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import Magnifier from 'react-magnifier';
// import Modal from '@material-ui/core/Modal';
// import Grow from '@material-ui/core/Grow';
import Fade from '@material-ui/core/Fade';
import { PotatoPageContext } from '../../../PotatoPageContext';
// import Turn from '../../../../../Turn';
import { useHistory } from 'react-router-dom';

const Row = styled.div`
  display: flex;
`;

const Cell = styled.div`
  position: relative;
  width: 33%;
  display: flex;

  img {
    width: 100%;
    object-fit: contain;
  }
`;

const Svg = styled.svg`
  cursor: pointer;
  transition: ${props => props.theme.transitions.create(['transform'])};
  z-index: 1;

  path {
    transition: ${props => props.theme.transitions.create(['stroke', 'stroke-width'])};
    fill: transparent;
  }

  &:hover {
    transform: scale3d(0.9, 0.9, 1);

    path {
      stroke-width: 12;
      stroke: black;
    }
  }
`;

// const StyledModal = styled(Modal)`
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

// const pages2 = [
// ];

const Book2 = props => {
  // const [open, setOpen] = React.useState(false);
  // const onClose = () => setOpen(false);
  // const onOpen = () => setOpen(true);

  return (
    <>
      {/* <StyledModal open={open} onClose={onClose}>
        <Grow in={open} timeout={500}>
          <Box width={800} height={600} style={{ outline: 'none' }}>
            <Turn className="magazine">
              {pages2.map((page, index) => (
                <div key={index} className="page">
                  <img src={page} alt="" />
                </div>
              ))}
            </Turn>
          </Box>
        </Grow>
      </StyledModal> */}
      <Svg
        // onClick={onOpen}
        width="634" height="513" viewBox="0 0 634 513" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M268 77C268 170.43 264.336 264.862 271.444 358.111C274.077 392.64 275.271 427.413 277 462" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M269 79C290.817 66.3461 313.601 55.6536 337.889 48.6667C346.365 46.2284 355.416 42.7996 364.278 42.1667C378.674 41.1384 393.639 41.9122 408 42.6667C449.617 44.8532 489.952 51.9158 528.667 67.5556C535.753 70.4181 535.627 74.4137 536.056 81.5556C537.083 98.6857 539.665 115.694 542.111 132.667C550.596 191.532 558.923 250.418 567.444 309.278C572.74 345.856 579.178 382.415 583.889 419.056C585.606 432.41 587 445.917 587 459.389C587 466.241 578.946 471.866 574 475.111C564.584 481.289 553.715 485.315 542.889 488.222C515.236 495.649 488.409 500.784 459.667 501C441.595 501.136 423.517 501 405.444 501C392.936 501 380.758 500.674 368.333 499C355.739 497.303 342.754 492.704 330.611 489C317.338 484.951 303.541 479.399 292.444 470.944C288.98 468.305 281.137 462 277 462" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M268 78C249.596 69.9065 230.844 62.3761 212.667 53.7778C201.411 48.4536 191.29 42.6502 179.056 39.6111C149.902 32.369 118.751 30.8844 88.8889 34.2778C67.3085 36.7301 44.4563 42.3889 24.7778 51.8889C19.6265 54.3757 22 61.6975 22 66.7778C22 81.7708 22.2833 96.7908 21.8889 111.778C21.3632 131.756 18.5915 151.453 16.8889 171.333C14.8267 195.413 15.8184 219.917 15.5 244.056C14.8067 296.609 15 349.166 15 401.722C15 422.653 16.5593 444.231 14.8889 465.111C14.3124 472.317 13 478.916 13 486.222C13 488.186 12.1854 492.38 13.4444 494.111C16.6692 498.545 25.0224 500.179 29.8889 501.778C38.187 504.503 46.6842 505.121 55.3333 506.056C67.9296 507.417 80.4267 508.884 93.1111 509C112.785 509.18 130.726 506.966 149.556 501.111C192.225 487.843 229.854 459 276 459" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M267 78C262.678 75.3649 261.053 72.287 257.444 69.1111C252.484 64.746 245.898 61.9381 240.444 58.2222C229.295 50.6256 215.576 46.0171 203.278 40.3889C188.983 33.8472 173.66 26.8945 158.111 24.1667C135.927 20.2747 113.774 21 91.3333 21C74.2208 21 56.0495 19.3012 39 21.2222C33.145 21.8819 29.2877 24.4554 27.9444 30.5C26.1598 38.5309 22 45.4237 22 54" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M269 77C265.48 67.8491 256.141 58.3478 248.222 52.6667C239.027 46.0702 228.209 40.9706 217.389 37.5C202.532 32.7347 187.175 29.696 172.222 25C158.553 20.7073 144.599 15.5512 130.222 14.2222C116.815 12.9829 103.456 10 90 10C74.2737 10 57.3124 7.98428 41.7778 10.0556C38.5761 10.4824 37 20.7229 37 23" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M269 78C258.48 52.3193 233.985 38.2938 209.056 28.5556C194.398 22.8301 179.593 17.0025 163.889 15C144.584 12.5383 125.547 9.08926 106.333 6.05555C95.2723 4.30908 83.9494 5 72.7778 5C67.3889 5 62 5 56.6111 5C52.5185 5 48.4259 5 44.3333 5C41.3274 5 35.1301 3.84479 32.2222 5.22222C32.1418 5.26029 35 10.0851 35 11C35 13.6949 37 14.1422 37 17" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M269 78C278.957 66.4637 288.639 54.9096 300.889 45.7222C306.012 41.88 311.881 36.7931 318.167 34.9444C324.425 33.1038 330.083 30.4935 336.667 29.6667C349.024 28.1149 361.785 28.8195 374.222 28C416.964 25.1837 462.499 20.6892 503.056 37.3889C512.818 41.4086 522.672 46.8367 531.667 52.3333C534.005 53.7621 536.607 54.9623 536.944 58C537.703 64.8313 537.018 70.3743 533 76" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M269 77C273.476 66.5254 277.323 53.2017 286.444 45.5555C293.91 39.2975 303.608 34.6499 312.833 31.7778C337.02 24.2479 362.859 22.8312 387.667 18.4444C412.058 14.1313 436.483 10.3068 461.333 9.99999C477.671 9.79829 496.38 8.64745 511.667 15.1111C515.452 16.7115 553.567 28.9917 542.944 40.5C539.447 44.289 536.822 48.7665 534 53" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M269 75C269.276 61.0576 273.94 50.0821 284.222 40.2222C290.352 34.3446 299.644 30.9739 307.389 28.0556C323.109 22.1319 339.441 18.3807 355.944 15.4444C403.978 6.89859 451.834 3 500.556 3C506.191 3 511.504 3.79299 517.111 4.55556C524.618 5.5765 532.851 5.4764 540.222 7.22222C544.204 8.16525 545 9.56681 545 13.5C545 18.4662 541.648 23.0273 539 27" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 493C12.5455 489.727 13.12 486.82 10.8889 484.111C6.01815 478.197 6.23733 475.808 5.38889 467.889C3.13372 446.841 3 426.196 3 405C3 346.963 3 288.926 3 230.889C3 180.5 3 130.111 3 79.7222C3 58.7179 16.3197 47.0204 27 31" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M538 47C541.554 85.3575 545.372 123.675 551.111 161.778C556.222 195.712 562.254 229.441 566.944 263.444C571.467 296.234 576.044 329.037 580.778 361.778C581.832 369.071 584.554 376.218 586.444 383.333C590.933 400.23 591.628 419.273 592.833 436.556C593.432 445.134 593.18 451.615 586 457" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M545 34C545.867 58.0636 555.433 80.4719 561 103.667C568.373 134.387 573.457 165.228 577.778 196.556C581.459 223.243 585.412 249.828 589.444 276.444C593.405 302.582 598.614 327.994 600.222 354.444C601.792 380.258 601 406.245 601 432.111C601 437.755 602.226 443.774 598 448C593.491 452.509 588.728 456.745 584 461" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M542 7C552.802 34.8124 562.343 63.2243 573.778 90.7778C579.42 104.373 584.427 117.878 588.056 132.167C592.193 148.457 599.266 164.14 602.333 180.667C604.313 191.333 606.768 202.057 608.389 212.778C611.628 234.206 617.251 255.103 621.944 276.222C627.226 299.987 630 325.542 630 349.833C630 367.926 630 386.019 630 404.111C630 413.817 630.711 421.949 624 429.778C616.216 438.859 608.475 447.458 599.333 455.333C595.553 458.59 586.4 462.199 584 467" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M31 67C36.6006 66.1039 41.9156 66 47.5556 66C49.739 66 52.399 66.4298 54.4444 65.5C56.9119 64.3784 55.1455 68.6817 57.4444 68.3333C63.7914 67.3717 69.324 68.3787 75.8889 67.7222C82.6022 67.0509 89.0883 65.5286 95.6667 64.1111C97.8265 63.6457 100.985 62.3689 103 64C108.516 68.4652 121.989 63.9837 128.222 63.1111C133.576 62.3616 138.76 61.7106 144.167 62.1111C149.363 62.496 153.64 65.2637 158.667 65.7778C166.409 66.5696 174.339 65.8822 182.111 66C188.096 66.0907 193.272 70.4974 198.611 72.7222C202.014 74.1402 205.934 75.472 209.5 76.3889C212.478 77.1546 215.576 76.3934 218.222 78C224.002 81.5089 230.145 84.4873 236 88" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M37 99C44.3859 99 51.1116 96.7946 58.3333 96.1667C60.1378 96.0098 61.3445 97.1979 62.4444 98.4444C64.1571 100.385 65.6337 99.7725 68 100C70.6604 100.256 71.5623 102.154 73 104.111C75.6234 107.682 79.681 106.038 83.5 105.056C94.7331 102.167 108.391 92.2544 120.222 96.4444C123.887 97.7425 127.195 99.7411 130.667 101.444C138.596 105.334 146.816 105.653 155.5 106C168.01 106.5 180.043 109.361 192.444 110.778C207.239 112.469 223.018 115.582 236 123" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M246 158C240.558 157.738 235.408 156.708 230 156.111C216.398 154.61 202.797 155.389 189.167 155.889C180.957 156.19 173.627 155.263 165.889 158.667C160.445 161.061 153.724 166.446 147.444 166.222C141.286 166.002 136.262 158.548 131.333 155.556C121.801 149.768 104.688 153.313 94.7778 156C86.5567 158.229 80.4486 159.751 71.6667 159.944C64.8604 160.094 58.4494 159.564 51.8889 161.556C47.8941 162.768 44.1149 163 40 163" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M236 195C227.621 194.831 219.262 194.403 210.889 194.056C207.358 193.909 194.468 191.532 190.944 195.056C187.706 198.294 184.019 200.932 180.444 203.778C175.338 207.844 168.891 203.038 163.444 201.222C156.077 198.766 147.573 200.185 140 200.778C124.118 202.021 108.853 202.027 93.6667 207.444C77.1394 213.341 61.285 215.695 44 218" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M234 251C214.136 251.366 193.863 251.866 174.333 255.833C165.099 257.709 158.525 257.903 148.833 256.444C142.272 255.457 137.11 252.577 131 251.167C124.886 249.756 118.854 253.196 113.611 255.722C107.198 258.812 99.6636 263.708 92.4444 264.778C86.2996 265.688 78.4215 259.546 72.6667 257.889C64.9214 255.658 55.627 257 47.6667 257C42.9565 257 38.4489 256.729 34 258" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M244 301C220.235 302.76 196.556 302.322 172.778 303.222C162.253 303.621 152.005 305.828 142 309.056C135.257 311.231 128.077 314.044 122.667 318.778C119.132 321.87 111.547 317.032 108.111 315.5C101.351 312.485 97.5875 310.205 90.4444 313.111C82.5014 316.342 77.76 317 69 317C57.6834 317 48.2641 317.868 38 323" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M47 380C51.8622 373.112 56.614 369.643 65.4444 370.222C72.2937 370.671 77.6533 375.826 84.4444 376C91.2827 376.175 97.9824 374.433 104.778 374.056C112.93 373.603 121.025 374.555 129.111 372.889C134.186 371.843 139.016 369.941 144.056 368.778C148.374 367.781 152.789 368.058 157.111 367C176.967 362.142 196.31 353.802 217 353C225.972 352.652 235.017 353 244 353" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M392 255C377.422 263.49 361.573 276.712 355.667 293.111C347.468 315.876 366.078 327.81 383.889 335.222C393.883 339.382 398.752 341.531 401.111 352.667C402.502 359.234 402.152 366.194 404.056 372.667C405.282 376.838 411.522 379.121 415 380.389C425.594 384.249 437.741 385.547 449 385C466.035 384.172 472.156 368.469 471.833 353.778C471.507 338.934 458.89 337.356 449.444 329.222C446.238 326.461 479.431 312.946 481.778 311.611C497.118 302.883 498.214 291.405 498 275.333C497.931 270.167 497.472 262.654 490.889 262.056C484.229 261.45 476.63 261.43 470 262.167C462.89 262.957 456.196 268.19 449.667 271C446.002 272.577 441.243 274.244 437.333 272.222C432.427 269.684 429.707 263.335 424.778 261.222C413.083 256.21 401.577 257 389 257" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M338 137C354.647 176.253 384.647 207.171 405.111 244.222C407.596 248.722 409.038 253.059 411.222 257.556C411.685 258.508 411.908 257.184 412 257" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M336 136C336.39 132.488 342.794 119.173 347.556 120.556C352.706 122.051 357.847 138.974 359.556 142.778C364.863 154.595 370.091 166.328 376.167 177.778C390.761 205.282 405.099 233.198 419 261" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M378 211C364.839 222.072 354.445 237.223 345.167 251.556C336.684 264.658 330.285 278.97 319.889 290.722C316.599 294.441 320.43 307.101 322.667 304C328.925 295.324 331.137 284.27 335.111 274.556C341.184 259.711 346.131 243.466 354.111 229.5C362.309 215.155 372.154 201.278 383.222 189C396.795 173.944 413.109 161.732 429.5 149.944C444.342 139.271 459.475 128.998 474.556 118.667C476.881 117.074 492.82 103.33 497.944 104.611C500.852 105.338 502.384 136.394 498.833 139.944C495.559 143.219 487.851 141.945 483.889 142C473.183 142.148 461.622 142.015 451.333 145.444C439.118 149.516 428.464 155.58 418.278 163.389C417.647 163.872 415.837 166.608 415 165.333C411.476 159.963 408.961 153.82 405.556 148.333C402.125 142.807 398.23 137.751 393.667 133.111C388.57 127.928 392.136 136.951 393.111 139.444C399.272 155.188 410.573 169.995 420.778 183.278C431.145 196.771 443.572 208.129 455.833 219.833C456.85 220.803 476.02 240.617 479.111 238.556C483.676 235.512 483.539 225.047 483.778 220.444C484.124 213.77 485.211 203.006 480.444 197.444C478.05 194.651 471.32 196 468 196" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M428 233C430.181 235.756 435.456 243.309 439.222 244.556C448.048 247.477 458.395 241.767 465.111 237.556C476.722 230.274 493.627 215.529 502.667 205.222C507.37 199.859 514.838 190.391 514.444 181.889C513.551 162.573 476.626 163.326 464.111 165.889C454.404 167.877 448.105 173.959 444.444 183.111C442.678 187.528 439.389 199.917 443.278 204.389C445.874 207.375 451.627 207.099 455.111 207.556C460.063 208.204 464.831 209.18 469.667 210.389C473.081 211.242 474.372 212.032 477.389 213.889C480.297 215.678 479 222.063 479 225" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M376 454C410.186 431.656 443.413 409.007 475 383.056C485.696 374.268 495.567 364.475 506.556 356.056C510.745 352.846 510 353.774 510 356.833C510 362.938 509.106 368.93 509 375C508.835 384.413 509.054 392.849 512.222 401.778C513.679 405.884 516.284 412.794 514.944 417.056C513.093 422.947 492.754 418.543 488.333 418.222C486.053 418.057 481.046 417.015 478.944 418.722C475.173 421.786 473.466 427.561 471.111 431.667C465.404 441.618 460.036 451.614 454.778 461.833C454.446 462.478 451.032 470.587 449.444 471.222C446.987 472.205 444.29 469.891 441.889 468.778C436.658 466.353 428.478 462.019 423.444 459.333C417.053 455.923 410.783 451.691 403.167 452.167C399.455 452.399 395.792 454.774 392.222 455.778C387.604 457.077 381.58 456 376.833 456C374.707 456 371.003 456.726 369 455.778C364.687 453.735 366.407 450.187 368 447" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M57 421C69.1573 416.185 79.3819 411.143 92.6667 411.889C106.8 412.682 120.701 414.41 134.889 414.222C150.376 414.018 165.712 413.162 181.167 412.944C194.5 412.757 201.626 411.912 213.5 406.556C223.05 402.247 235.151 394 246 394" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
      </Svg>
    </>
  );
};

const Book1 = props => (
  <Svg width="738" height="576" viewBox="0 0 738 576" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
  <path d="M425.416 537.801C421.079 473.841 416.727 409.892 411.73 345.983C409.728 320.376 407.506 294.807 406.621 269.126C405.471 235.753 406.117 202.281 406.117 168.89C406.117 155.336 405.383 141.906 405.044 128.396C404.835 120.028 404.981 111.641 404.981 103.272C404.981 97.9894 406.906 91.5146 401.197 88.934C392.018 84.7852 381.387 83.4615 371.617 81.9589C352.278 78.9844 332.885 76.2983 313.53 73.4336C293.075 70.4063 273.438 70.4627 252.793 70.4627C239.505 70.4627 225.465 69.4979 212.239 71.2377C193.621 73.687 176.405 82.4514 159.765 90.7424C117.805 111.649 71.8604 134.257 36.7785 166.436C29.7284 172.903 23.45 180.336 15.5869 185.812C11.6412 188.559 15.0598 199.312 15.7131 202.991C18.4381 218.339 26.6868 233.171 32.6158 247.426C57.6485 307.61 80.0746 368.898 106.408 428.523C117.2 452.958 127.801 477.415 137.123 502.472C139.697 509.391 143.305 516.004 145.448 523.075C146.53 526.646 146.31 526.89 149.548 528.5C166.693 537.028 183 546.687 200.824 553.882C220.626 561.876 240.768 567.977 262.065 569.576C277.911 570.766 293.868 570.351 309.746 570.351C329.07 570.351 347.096 563.115 365.436 557.047C378.003 552.889 389.737 553.517 402.585 551.493C411.282 550.122 419.452 543.039 423.146 535.476" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M406.116 92.5508C444.969 71.1036 489.926 51.8585 534.464 49.085C578.097 46.3679 624.142 63.1409 661.172 86.0923C666.349 89.3012 673.638 94.7706 677.192 100.107C682.742 108.444 685.889 119.131 688.04 128.848C692.729 150.037 694.717 171.963 699.014 193.304C710.409 249.903 716.131 307.584 722.16 364.971C723.792 380.5 728.411 396.24 728.531 411.86C728.619 423.399 728.286 434.959 729.161 446.477C729.741 454.106 732.126 461.275 732.945 468.824C733.83 476.977 732.093 485.162 734.207 493.172C737.27 504.779 730.993 512.357 723.359 521.138C701.938 545.775 665.396 551.758 635.628 559.178C591.786 570.107 546.578 574.386 501.541 572.612C489.147 572.124 480.418 566.097 469.313 561.697C462.143 558.857 454.962 556.083 447.869 553.043C445.593 552.067 441.584 551.062 440.174 548.715C437.879 544.896 434.408 540.511 430.461 538.317C428.365 537.152 425.831 537.064 424.281 535.476" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M406.117 90.2258C388.483 78.952 369.547 65.8364 349.354 59.4833C322.167 50.9301 292.161 54.1873 264.083 54.1873C228.688 54.1873 192.825 51.9853 158.125 60.5167C121.579 69.5021 86.3776 84.103 52.1675 99.6552C37.4519 106.345 20.6817 114.718 9.15377 126.523C2.97185 132.853 2.43386 137.49 3.35133 146.415C4.57029 158.273 13.3164 170.183 13.3164 182.066" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M408.387 94.8761C395.147 77.2631 383.414 61.6988 362.409 54.1874C338.201 45.5307 313.671 43.7246 288.176 43.7246C252.1 43.7246 216.024 43.7246 179.947 43.7246C150.401 43.7246 121.189 43.0495 91.9017 47.7289C84.6038 48.895 77.8007 51.9735 71.2778 55.4146C67.8345 57.231 60.9969 59.7263 58.6007 63.1002C53.5098 70.2683 42.3547 72.4517 34.7602 76.3401C23.5989 82.0549 25.8043 97.6084 25.8043 108.826" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M403.846 92.5508C412.476 82.0075 420.87 70.3773 432.354 62.8416C440.628 57.4119 450.158 53.5601 459.095 49.4725C476.579 41.4769 492.301 36.0672 511.191 33.6492C547.913 28.9487 582.592 24.9405 618.536 36.8138C633.113 41.6291 647.046 48.2182 661.298 53.9289C669.599 57.255 671.768 58.5402 671.768 67.4918C671.768 74.2252 672.699 81.5522 670.632 87.9007" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M401.575 87.9008C411.782 79.6663 416.4 66.414 424.281 56.1249C429.572 49.2166 436.309 44.4792 443.832 40.3661C463.855 29.4195 485.756 20.1547 508.038 15.4363C526.921 11.4375 546.523 10.3061 565.621 7.68605C585.986 4.89207 606.514 2.18364 627.114 3.22967C649.166 4.34942 676.926 9.16802 687.535 31.3888C693.63 44.1545 691.82 59.3973 684.382 71.367C681.579 75.8773 675.365 92.5509 669.497 92.5509" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M401.576 92.5509C383.215 95.2801 365.488 100.979 346.957 102.497C336.154 103.382 325.539 102.984 314.791 104.693C300.979 106.889 287.298 108.901 273.417 110.506C244.975 113.794 216.03 118.266 187.768 123.035C156.16 128.369 124.382 135.624 94.2983 147.061C75.731 154.12 51.2695 172.044 50.7799 194.854C50.6242 202.112 50.4323 210.019 53.3027 216.813C57.4313 226.583 64.5666 235.496 69.5748 244.843C89.5998 282.213 110.789 318.771 127.095 358.06C140.312 389.906 153.965 421.526 166.198 453.775C175.37 477.956 187.464 500.994 198.175 524.496C201.204 531.143 214.946 526.954 219.492 526.369C241.938 523.48 263.094 516.813 284.959 511.321C299.495 507.67 313.722 502.396 328.225 498.92C348.803 493.988 371.996 497.112 393.061 497.112C400.053 497.112 403.039 502.807 407.946 507.058C409.639 508.525 411.233 510.293 412.991 511.644C418.917 516.195 420.723 506.104 423.145 501.762" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M406.116 94.8759C436.871 92.5084 467.595 90.3 498.199 86.3507C510.791 84.7257 523.545 83.7194 536.23 83.3798C568.982 82.503 603.16 81.329 635.565 86.7382C641.666 87.7566 651.36 88.4669 653.856 95.651C657.12 105.046 656.917 116.229 658.271 126.006C662.257 154.81 668.472 182.535 677.444 210.096C704.596 293.51 733.452 383.983 719.448 472.893C718.711 477.571 719.552 490.113 715.286 493.624C709.575 498.326 701.089 500.754 694.22 502.408C664.356 509.599 632.619 512.29 602.075 514.227C580.164 515.616 558.278 515.209 536.356 515.842C526.477 516.127 516.71 518.3 506.902 519.458C492.241 521.191 477.353 523.85 462.564 523.85C451.07 523.85 447.45 515.19 439.67 508.285C434.888 504.042 429.086 505.25 423.145 505.25" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
  </Svg>
);

export default function Drawings() {
  const pageCtx = useContext(PotatoPageContext);
  useEffect(() => pageCtx.setBgColor('#215F53'), []);

  const [exit, setExit] = useState(false);
  const onExit = () => { setExit(true); pageCtx.setBgColor('#fff'); };
  const history = useHistory();
  const onExited = () => history.push('/read/teotr');

  return (
    <Fade in={!exit} timeout={2000} onExited={onExited}>
      <Box margin="auto" maxWidth={1200}>
        <Box margin={4}>
          <Row>
            <Cell>
              <div style={{ marginLeft: 'auto', width: '90%', marginBottom: 'auto', marginRight: 16 }}>
                <Magnifier src="/images/drawings/2.png" />
              </div>
            </Cell>
            <Cell>
              <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                <Magnifier src="/images/drawings/3.png" />
              </div>
            </Cell>
            <Cell>
              <div style={{ marginRight: 'auto', width: '60%', marginBottom: 'auto' }}>
                <Magnifier src="/images/drawings/4.png" />
              </div>
              <Book1
                onClick={onExit}
                style={{ position: 'absolute', width: '40%', height: '40%', right: -16, top: '10%' }}
              />
            </Cell>
          </Row>
          <Row>
            <Cell>
              <div style={{ marginLeft: 'auto', marginTop: -40, zIndex: 1 }}>
                <Magnifier src="/images/drawings/1.png" />
              </div>
            </Cell>
            <Cell>
              <div style={{ width: '60%', margin: '0 auto auto 0' }}>
                <Magnifier src="/images/drawings/6.png" />
              </div>
              <Book2
                onClick={onExit}
                style={{ position: 'absolute', width: '40%', height: '40%', right: 0, top: '10%' }}
              />
            </Cell>
            <Cell>
              <div style={{ flexShrink: 0, marginRight: 'auto', marginTop: -100, marginLeft: -10, width: '110%' }}>
                <Magnifier src="/images/drawings/5.png" />
              </div>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Box display="flex" flexDirection="column" width="100%" height="100%">
                <div style={{ width: '65%' }}>
                  <Magnifier src="/images/drawings/10.png" />
                </div>
                <div style={{ width: '40%', marginLeft: '25%' }}>
                  <Magnifier src="/images/drawings/11.png" />
                </div>
              </Box>
            </Cell>
            <Cell>
              <div style={{ marginLeft: '-30%', marginTop: '-25%', width: '115%' }}>
                <Magnifier src="/images/drawings/9.png" />
              </div>
            </Cell>
            <Cell>
              <div style={{ flexShrink: 0, width: '60%', marginTop: '-10%', marginLeft: '-15%' }}>
                <Magnifier src="/images/drawings/8.png" />
              </div>
              <div style={{ flexShrink: 0, width: '70%', marginTop: '-5%' }}>
                <Magnifier src="/images/drawings/7.png" />
              </div>
            </Cell>
          </Row>
        </Box>
      </Box>
    </Fade>
  );
}
