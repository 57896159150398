import React from 'react';

const Next = () => {
  return (
    <svg width="47" height="32" viewBox="0 0 47 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.04172 22C1.075 22.0593 5.80149 30.5556 4.8195 30.5556C3.57234 30.5556 3.04172 28.3895 3.04172 27.5C3.04172 25.6956 2.02924 22.7219 1.26394 21C-0.947507 16.0242 11.4357 22.8007 12.4306 23.4444C18.1262 27.1298 10.4695 14.7848 9.04172 13" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15.0417 13C17.254 19.775 18.9743 24.0675 25.0417 18" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15.0417 14C17.4901 12.0293 20.0482 8.99786 23.0417 8" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M17.0417 17C18.7084 16.3333 20.3751 15.6667 22.0417 15" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M31.0417 7C31.0417 7.85364 30.5374 22.487 29.0417 18" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M24.0417 10C29.2673 10.9406 33.9192 12.8617 39.0417 14" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M40.0417 4C41.658 7.16788 42.9107 10.4345 46.0417 12" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M36.0417 6C38.3418 4.56246 40.5245 3.27583 42.0417 1" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

  );
};

export default Next;
