import React from 'react';
import Box from '@material-ui/core/Box';
import { Footer } from './FolderWindow.styled';
import Window, { View } from './Window';

const FolderWindow = ({ title = 'Folder', icon = '/images/win98_folder_open.png', children, ...restProps }) => {
  return (
    <Window title={title} icon={icon} {...restProps}>
      <View>
        <Box padding={1} display="flex" flexWrap="wrap">
          {children}
        </Box>
      </View>
      <Footer>
        <div>
          {React.Children.toArray(children).length} object(s)
        </div>
        <Box display="flex">
          <Box marginRight="4px">
            <img src={icon} height={12} />
          </Box>
          {title}
        </Box>
      </Footer>
    </Window>
  );
};

export default FolderWindow;
