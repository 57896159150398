import React from 'react';
import Box from '@material-ui/core/Box';
import text from './text.svg';
import bubbles from './bubbles.svg';
import text_date from './text_date.svg';
import text_museum from './text_museum.svg';
import museum from './museum.png';
import imgs from './imgs.png';
import green from './green.png';
import fs from './fs.svg';

const Section9 = () => {
  return (
    <Box position="relative" marginTop="2350px">
      <Box className="rlx" data-rellax-speed={0.5} position="absolute">
        <img src={bubbles} />
      </Box>
      <Box className="rlx" position="absolute" right={0} top={-10}>
        <img src={text_date} />
      </Box>
      <Box className="rlx" data-rellax-speed={0.3} position="absolute" left={360} top={430}>
        <img src={text_museum} />
      </Box>
      <Box position="absolute" left={200} top={430}>
        <img src={museum} />
      </Box>
      <Box  className="rlx" data-rellax-speed={-0.2} position="absolute" left={-40} top={20}>
        <img src={imgs} width={235} />
      </Box>
      <Box className="rlx" data-rellax-speed={-1} position="absolute" left={-40} top={-50}>
        <img src={green} width={235} />
      </Box>
      <Box position="absolute" left={15} top={330}>
        <img src={fs} />
      </Box>
      <Box position="absolute" left={200}>
        <img src={text} />
      </Box>
    </Box>
  );
};

export default Section9;
