import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const Avocado = props => {
  const theme = useContext(ThemeContext);
  const avocadoColor = theme.palette.avocado;
  return (
    <svg width="43" height="43" viewBox="0 0 43 43" {...props}>
      <path shapeRendering="geometricPrecision" d="M1.5 23.5C-2.5 9.5 14 1 22 1C27 2 27.5 0 35.5 8C40.5362 12.331 40.5123 17.037 41.5 20C43.5 26 36.5 41.5 23.5 42C19.2871 42.162 13.8696 40.8172 10.5 38C5.55144 33.8626 3.28474 27.2678 1.5 23.5Z" fill={avocadoColor} stroke="black" />
    </svg>
  );
};

export default Avocado;
