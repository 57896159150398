import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { AvocadoPageContext } from '../../AvocadoPageContext';
import Monospace from '../../../../Monospace';
import Shit from './Shit';
import Collapse from '../../../Page6Potato/Subpages/ArtInUkraine/Subpages/Blueberry/Collapse';

const VerText = styled.div`
  position: absolute;
  font-size: 48px;
  left: -20px;
  top: 0px;
  writing-mode: vertical-lr;
  text-orientation: mixed;
  height: 700px;
  z-index: 1;
`;

const DImgSize = 200;

const StyledDImg = styled.img`
  object-fit: contain;
  width: ${DImgSize}px;
  height: ${DImgSize}px;
`;

const DImg = React.forwardRef(({ primary: primaryProp, secondary: secondaryProp }, ref) => {
  const [img, setImg] = useState();

  const [primaryLoaded, setPrimaryLoaded] = useState(false);
  const primary = useMemo(() => {
    const onLoad = () => {
      setPrimaryLoaded(true);
      setImg(primary);
    };
    return <StyledDImg key="primary" src={primaryProp} onLoad={onLoad} />;
  }, [primaryProp]);

  const [secondaryLoaded, setSecondaryLoaded] = useState(false);
  const secondary = useMemo(() => {
    const onLoad = () => setSecondaryLoaded(true);
    return <StyledDImg key="secondary" src={secondaryProp} onLoad={onLoad} />;
  }, [secondaryProp]);

  const onMouseEnter = useCallback(() => setImg(secondary), [secondary]);
  const onMouseLeave = useCallback(() => setImg(primary), [primary]);

  return (
    <Box width={DImgSize} height={DImgSize} ref={ref}>
      <Slide in={primaryLoaded && secondaryLoaded} timeout={500} direction="left">
        <Box onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          <Box display={img === primary ? 'block' : 'none'}>
            {primary}
          </Box>
          <Box display={img === secondary ? 'block' : 'none'}>
            {secondary}
          </Box>
        </Box>
      </Slide>
      <Box display="none">
        {primary}
        {secondary}
      </Box>
    </Box>
  );
});

const Association = () => {
  const pageCtx = useContext(AvocadoPageContext);
  useEffect(() => pageCtx.setBgColor('#A90000'), []);

  const [enter, setEnter] = useState(false);
  const onEntered = () => setEnter(true);
  const [enter2, setEnter2] = useState(false);
  const onEntered2 = () => setEnter2(true);

  const [exit, setExit] = useState(false);
  const onExit = () => setExit(true);
  const history = useHistory();
  const onExited = () => history.push('/read/avocado/google');

  return (
    <Grow in={!exit} timeout={{ enter: 0, exit: 1000 }} onExited={onExited}>
      <Box marginX="auto">
        <Slide in timeout={1000} direction="down" onEntered={onEntered}>
          <Box marginX="auto" position="relative" marginY={10}>
            <Collapse in={enter} timeout={1000}>
              <VerText>
                <Box height={800}>
                  associations and perception
                </Box>
              </VerText>
            </Collapse>
            <Fade in timeout={1000} onEntered={onEntered2}>
              <Box display="flex">
                <Box bgcolor="#E36F6F" maxWidth={640} paddingY={4} paddingLeft={6} paddingRight={4}>
                  <Monospace>
                    Mental associations connect very close with visual perception. In my opinion, the nature of it goes back to the Prehistoric art where everything depicted by the human hand was supposed to be standing for something: either simple gestures of existing things like animals, people, landscape objects or symbolical representations of Gods and Goddesses, myths and shamanistic stories. From that point through the whole history of art people were always trying to find explanation and reasoning to the depiction of certain things. In a lot of cases that process from observation to analysing went through making interpretations and associations.
                  </Monospace>
                  <br />
                  <Box position="relative">
                    <Monospace>
                      The first mentioning of association of ideas can be found in Plato’s Phaedo as part of the doctrine of anamnesis. The Truth consists in universals, in idea. The sensible things are not realities, but the shadows of The Truth. In the Phaedo the origin of ideas is sought for in a previous state of existence. They could be recovered from the experiences gained in the previous life’s through the ordinary law of association, by which in daily life the sight of one thing or person recalls another to our minds, and by which in scientific enquiry from any part of knowledge we may be led on to infer the whole. Aristotle, on the other hand, created The Laws of Association where the first one (Law of Contiguity) stands for associating things that occur close to each other in time or space, the second one (Law of Similarity) states that when the two things are very similar to each other, the thought of one will trigger the thought of the other, and the third one (The Law of Contrast) claims that the thought of one might most likely cause the thought of its direct opposite.
                    </Monospace>
                    <Grow in={enter2} timeout={1000}>
                      <Box position="absolute" left="100%" onClick={onExit}>
                        <Shit />
                      </Box>
                    </Grow>
                  </Box>
                  <br />
                  <Monospace>
                    In the course of time, phycologists and physicians tried to explain all mental acquisitions and the more complex mental processes connected to the action of association. The Associationist School. John Locke (1689) believed that there were some associations that were natural and justified and others that were illogical caused errors in judgment. He was the one to believe that the origin of associations could be based on the education and culture of the individual. Hume in his “Treatise on Human Nature” (1739-40) writes “When the mind, therefore, passes from the idea of impression of one object to the idea or belief of another, it is not determined by reason, but by certain principles, which associate together the ideas of these objects, and unite them in the imagination”. Hume sees these connections, created in the mind by observation and experience, as the tools for thinking. Later Associationists, like Sully (1882, 1883), have come to recognize that the mind exerts activity in attention, discrimination, judgment, reasoning, suggest a soul that activates all of these being a “train of thoughts” or a “stream of consciousness”.
                  </Monospace>
                  <br />
                  <Monospace>
                    I find mental association present especially strongly in the perception of visual art. Not only the audience sometimes consciously and unconsciously tries to connect their own experiences to the things they see in the works of art, but also the works of the artists of the previous generations come to the front because of the endless loop of associations. To avoid that, both the artist and the spectator have to be freed from their knowledge and the roots to the memory. But is it worth it? Does it really bother me that people connect my work to the work of Frank Stella or pop-artists? Does it really bother me if they see things that I did not intend to portray? Associations of the viewers can make many different versions of one work, in their head. But no matter what, the work remains to be what it is.
                  </Monospace>
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="space-evenly" marginLeft={10} width={DImgSize}>
                  {enter2 && (
                    <>
                      <DImg
                        primary="/images/association/umbrella.png"
                        secondary="/images/association/umbrella_2.png"
                      />
                      <DImg
                        primary="/images/association/postage_stamp.png"
                        secondary="/images/association/postage_stamp_2.png"
                      />
                      <DImg
                        primary="/images/association/avocado.png"
                        secondary="/images/association/avocado_2.png"
                      />
                      <DImg
                        primary="/images/association/pain_au.png"
                        secondary="/images/association/pain_au_2.png"
                      />
                    </>
                  )}
                </Box>
              </Box>
            </Fade>
          </Box>
        </Slide>
      </Box>
    </Grow>
  );
};

export default Association;
