import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import { createGlobalStyle } from 'styled-components';
import Rellax from 'rellax';
import Magnifier from 'react-magnifier';
import Monospace from '../../../Monospace';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #BABFBF !important;
  }
`;

export default function Untitled2() {
  useEffect(() => {
    new Rellax('.rellax', { center: true, speed: 0.5 });
  }, []);

  const [exit, setExit] = useState(false);
  const onExit = () => setExit(true);
  const history = useHistory();
  const onExited = () => history.push('/read/square-painting');

  return (
    <Grow in={!exit} timeout={1500} onExited={onExited}>
      <Box margin="auto" position="relative">
        <GlobalStyle />
        <svg width="944" height="3270" viewBox="0 0 944 3270" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M59 1L131 61L179 77H209L249 87L287 103L315 129L381 205L455 245L523 229L617 205L715 245L747 305L715 387L673 475L591 511L543 563L503 663V705L523 751L563 783L637 827L731 889L765 937L803 991L817 1047V1119V1199L803 1255L765 1305V1321H693H617L543 1365C544.333 1381 550.2 1413 563 1413C575.8 1413 604.333 1413 617 1413L683 1403L741 1413L765 1457L721 1511L659 1525L441 1557H351L231 1573L177 1583L113 1605L67 1657C62.3333 1687 55.8 1748.6 67 1755C78.2 1761.4 102.333 1797.67 113 1815L187 1829H231H341L423 1865L463 1939L489 2065V2205L513 2339L571 2445L647 2495L879 2543L927 2603L943 2721L927 2839L805 2945L603 3023L475 3059L291 3101L205 3121L101 3141L53 3177L27 3223V3251L1 3269" stroke="white"/>
        </svg>
        <Box
          position="absolute"
          top={45}
          left={383}
          textAlign="right"
        >
          <img src="/images/untitled2/title.svg" alt="title" />
          <Monospace>
            various sizes (~ 2 m x 1,3 m x 1 m)
            <br />
            acrylic on canvas, acrylic on cotton & wood
            <br />
            2020
          </Monospace>
        </Box>
        <Box className="rellax" position="absolute" top={171} left={112}>
          <Magnifier src="/images/untitled2/1.jpg" alt="" width={340} />
        </Box>
        <Box position="absolute" top={257} left={497}>
          <video autoPlay muted loop width={104}>
            <source src="/video/untitled2_5.mp4" type="video/mp4" />
          </video>
        </Box>
        <Box className="rellax" data-rellax-speed={-0.5} position="absolute" top={502} left={530}>
          <Magnifier src="/images/untitled2/3.jpg" alt="" width={398} />
        </Box>
        <Box
          position="absolute"
          top={811}
          left={187}
          textAlign="center"
          bgcolor="#C6CDCE"
          border="1px solid #000000"
          width={657}
          height={132}
          padding={2}
        >
          <Monospace>
            What is a painting? How is reality and the imaginary intertwined? By using
            black outlines, the artist is creating a cartoonish character of the forms
            to play with the contrast of the real authentic concept of the “painting”
            and its humorous painterly representation.
            <br />
            The work is available in different versions.
          </Monospace>
        </Box>
        <Box className="rellax"position="absolute" top={1069} left={110}>
          <Magnifier src="/images/untitled2/4.jpg" alt="" width={496} />
        </Box>
        <Box className="rellax" position="absolute" top={1533} left={501}>
          <Magnifier src="/images/untitled2/5.jpg" alt="" width={375} />
        </Box>
        <Box position="absolute" top={1005} left={645} width={286}>
          <video autoPlay muted loop width={272}>
            <source src="/video/untitled2_4.mp4" type="video/mp4" />
          </video>
        </Box>
        <Box position="absolute" top={1177} left={645} width={286}>
          <video autoPlay muted loop width={272}>
            <source src="/video/untitled2_3.mp4" type="video/mp4" />
          </video>
        </Box>
        <Box position="absolute" top={1449} left={261}>
          <video autoPlay muted loop width={272}>
            <source src="/video/untitled2_2.mp4" type="video/mp4" />
          </video>
        </Box>
        <Box position="absolute" top={1611} left={133}>
          <video autoPlay muted loop width={104}>
            <source src="/video/untitled2_1.mp4" type="video/mp4" />
          </video>
        </Box>
        <Box position="absolute" top={1882} left={133}>
          <Magnifier src="/images/untitled2/6.jpg" alt="" width={297} />
        </Box>
        <Box position="absolute" top={2189} left={524}>
          <Magnifier src="/images/untitled2/7.jpg" alt="" width={380} />
        </Box>
        <Box className="rellax" position="absolute" top={2385} left={121}>
          <Magnifier src="/images/untitled2/8.jpg" alt="" width={422} />
        </Box>
        <Box position="absolute" top={2538} left={602}>
          <Magnifier src="/images/untitled2/9.jpg" alt="" width={317} />
        </Box>
        <Box position="absolute" top={2743} left={643}>
          <Magnifier src="/images/untitled2/10.jpg" alt="" width={294} />
        </Box>
        <Box
          position="absolute"
          bottom={60}
          right={0}
        >
          <img src="/images/untitled2/previews.png" alt="previews" width={898} />
        </Box>
        <Box margin={8} position="absolute" bottom={-100} left="50%" style={{ transform: 'translateX(-50%)' }}>
          <Button onClick={onExit} endIcon={<ChevronRightIcon />}>
            next
          </Button>
        </Box>
      </Box>
    </Grow>
  );
}
