import styled, { createGlobalStyle } from 'styled-components';
import { Window as PBWindow } from 'packard-belle';

export const StyledPBWIndow = styled(PBWindow)`
  width: 100%;
  height: 100%;
  display: flex !important;
  flex-direction: column;
`;

export const View = styled.div`
  margin: 3px 1px 1px;
  background-color: #ffffff;
  box-shadow: inset -1px -1px 0px #ffffff, inset 1px 1px 0px 0px #808088, inset -2px -2px 0px #bbc3c4, inset 2px 2px 0px 0px #787878;
  padding: 2px;
  overflow: auto;
  flex-grow: 1;
  width: 100%;
  height: 100%;

  ::-webkit-scrollbar {
    width: 16px;
    height: 16px;
    background-color: #ffffff;
    background-image: url("data:image/gif;base64,R0lGODlhAgACAJEAAAAAAP///8zMzP///yH5BAEAAAMALAAAAAACAAIAAAID1CYFADs="); }
    ::-webkit-scrollbar-track {
      position: relative; }
    ::-webkit-scrollbar-thumb {
      background: #bbc3c4;
      box-shadow: inset -1px -1px 0px #808088, inset 1px 1px 0px 0px #ffffff;
      border: 1px solid #0c0c0c;
      border-top: 1px solid #bbc3c4;
      border-left: 1px solid #bbc3c4; }
    ::-webkit-scrollbar-button {
      background: #bbc3c4;
      box-shadow: inset -1px -1px 0px #808088, inset 1px 1px 0px 0px #ffffff;
      border: 1px solid #0c0c0c;
      border-top: 1px solid #bbc3c4;
      border-left: 1px solid #bbc3c4; }
      ::-webkit-scrollbar-button:start:decrement, ::-webkit-scrollbar-button:end:increment {
        height: 16px;
        width: 16px;
        display: block;
        background-repeat: no-repeat;
        background-color: #bbc3c4; }
        ::-webkit-scrollbar-button:start:decrement:active, ::-webkit-scrollbar-button:end:increment:active {
          border: 1px solid #808088;
          box-shadow: none;
          background-color: #bbc3c4; }
      ::-webkit-scrollbar-button:horizontal:decrement {
        background-image: url("data:image/gif;base64,R0lGODlhBAAHAJEAAAAAAP///////wAAACH5BAEAAAIALAAAAAAEAAcAAAIIlHEIy7ppBCgAOw==");
        background-position: 4px 3px; }
        ::-webkit-scrollbar-button:horizontal:decrement:active {
          background-position: 5px 4px; }
      ::-webkit-scrollbar-button:horizontal:increment {
        background-image: url("data:image/gif;base64,R0lGODlhBAAHAJEAAAAAAP///////wAAACH5BAEAAAIALAAAAAAEAAcAAAIIhA4maeyrlCgAOw==");
        background-position: 5px 3px; }
        ::-webkit-scrollbar-button:horizontal:increment:active {
          background-position: 6px 4px; }
      ::-webkit-scrollbar-button:vertical:decrement {
        background-image: url("data:image/gif;base64,R0lGODlhBwAEAJEAAAAAAP///////wAAACH5BAEAAAIALAAAAAAHAAQAAAIHlGEJq8sOCwA7");
        background-position: 3px 5px; }
        ::-webkit-scrollbar-button:vertical:decrement:active {
          background-position: 4px 6px; }
      ::-webkit-scrollbar-button:vertical:increment {
        background-image: url("data:image/gif;base64,R0lGODlhBwAEAJEAAAAAAP///////wAAACH5BAEAAAIALAAAAAAHAAQAAAIIhA+CKWoNmSgAOw==");
        background-position: 3px 5px; }
        ::-webkit-scrollbar-button:vertical:increment:active {
          background-position: 4px 6px; }
    ::-webkit-scrollbar-corner {
      /*
      background-image: url(resources/corner.png);
      background-repeat: no-repeat;
      */
      background-color: #bbc3c4; }
`;

export const GlobalPackardBelleCssOverrides = createGlobalStyle`
  /* I just don't need minimize, maximize */
  .Window__maximize {
    display: none !important;
  }
`;

export const GlobalPackardBelleWindowCss = createGlobalStyle`
.window,
.Window {
  position: relative;
  background-color: #bbc3c4;
  padding: 3px;
  box-shadow: inset -1px -1px 0px #0c0c0c, inset 1px 1px 0px #bbc3c4, inset -2px -2px 0px #808088, inset 2px 2px 0px #ffffff;
  display: inline-block; }
  .window__heading,
  .Window__heading {
    display: flex;
    background: linear-gradient(to right, #0000a2, #126fc2);
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 1px;
    padding: 0px 1px 0px 3px;
    align-items: center;
    letter-spacing: 1px; }
    .window__heading button,
    .Window__heading button {
      padding: 0px;
      min-width: initial;
      width: 16px;
      height: 14px;
      margin-left: 1px;
      image-rendering: pixelated;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      background-repeat: no-repeat;
      background-position: 1px 1px; }
      .window__heading button:focus, .window__heading button.clicked,
      .Window__heading button:focus,
      .Window__heading button.clicked {
        outline: none;
        border: none; }
      .window__heading button:active:focus, .window__heading button.clicked,
      .Window__heading button:active:focus,
      .Window__heading button.clicked {
        padding: 2px 8px 1px 4px;
        background-position: 2px 2px; }
  .window__icon,
  .Window__icon {
    padding: 8px;
    display: flex;
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 4px; }
  .window__title,
  .Window__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
    min-width: 0px;
    user-select: none; }
  .window__close,
  .Window__close {
    margin-left: 2px;
    background-image: url("data:image/gif;base64,R0lGODlhDQALAJEAAAAAAP///////wAAACH5BAEAAAIALAAAAAANAAsAAAIUlI+pKwDoVGxvucmwvblqo33MqBQAOw=="); }
  .window__restore,
  .Window__restore {
    background-image: url("data:image/gif;base64,R0lGODlhDQALAJEAAAAAAP///////wAAACH5BAEAAAIALAAAAAANAAsAAAIZlI9pwK3SnAKI1kjtwTlpyHjV830b9qRHAQA7"); }
  .window__minimize,
  .Window__minimize {
    background-image: url("data:image/gif;base64,R0lGODlhDQALAJEAAAAAAP///////wAAACH5BAEAAAIALAAAAAANAAsAAAIOlI+py+0PozSg2mXvFAUAOw=="); }
  .window__maximize,
  .Window__maximize {
    background-image: url("data:image/gif;base64,R0lGODlhDQALAJEAAAAAAP///////wAAACH5BAEAAAIALAAAAAANAAsAAAIXlI8Jy4wNXzJAznqwsjtPoYFfCDXfWQAAOw=="); }
  .window__help,
  .Window__help {
    background-image: url("data:image/gif;base64,R0lGODlhDQALAJEAAAAAAP///////wAAACH5BAEAAAIALAAAAAANAAsAAAIXlI8Jy4wNXzJAznqwsjtPoYFfCDXfWQAAOw==");
    background-image: url("data:image/gif;base64,R0lGODlhDQALAJEAAAAAAP///////wAAACH5BAEAAAIALAAAAAANAAsAAAIUlI9pwKDrBHtTxmcxvJTrn30VqBQAOw=="); }
  .window__menu,
  .Window__menu {
    display: flex;
    padding: 0px;
    font-size: 1rem;
    position: relative;
    overflow-y: visible;
    z-index: 20; }
    .window__menu > div,
    .Window__menu > div {
      position: relative; }
      .window__menu > div > button,
      .Window__menu > div > button {
        padding: 0px 4px;
        outline: none;
        border: none;
        user-select: none;
        color: #0c0c0c;
        display: inline-block;
        background-color: rgba(0, 0, 0, 0);
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
        padding: 3px 6px;
        text-transform: capitalize; }
        .window__menu > div > button + div,
        .window__menu > div > button + div,
        .Window__menu > div > button + div,
        .Window__menu > div > button + div {
          z-index: 20;
          visibility: hidden;
          position: absolute;
          max-height: 0px;
          top: 100%;
          left: 0px; }
          @media (min-height: 720px) and (min-width: 960px) {
            .window__menu > div > button + div,
            .window__menu > div > button + div,
            .Window__menu > div > button + div,
            .Window__menu > div > button + div {
              transition: max-height linear 750ms; } }
        .window__menu > div > button:hover,
        .Window__menu > div > button:hover {
          box-shadow: inset -1px -1px 0px #808088, inset 1px 1px 0px #ffffff; }
        .window__menu > div > button:active, .window__menu > div > button:focus, .window__menu > div > button:active:focus, .window__menu > div > button.active, .window__menu > div > button.clicked,
        .Window__menu > div > button:active,
        .Window__menu > div > button:focus,
        .Window__menu > div > button:active:focus,
        .Window__menu > div > button.active,
        .Window__menu > div > button.clicked {
          box-shadow: inset -1px -1px 0px #ffffff, inset 1px 1px 0px #808088;
          padding: 4px 5px 2px 7px; }
          .window__menu > div > button:active + div,
          .window__menu > div > button:active + div, .window__menu > div > button:focus + div,
          .window__menu > div > button:focus + div, .window__menu > div > button:active:focus + div,
          .window__menu > div > button:active:focus + div, .window__menu > div > button.active + div,
          .window__menu > div > button.active + div, .window__menu > div > button.clicked + div,
          .window__menu > div > button.clicked + div,
          .Window__menu > div > button:active + div,
          .Window__menu > div > button:active + div,
          .Window__menu > div > button:focus + div,
          .Window__menu > div > button:focus + div,
          .Window__menu > div > button:active:focus + div,
          .Window__menu > div > button:active:focus + div,
          .Window__menu > div > button.active + div,
          .Window__menu > div > button.active + div,
          .Window__menu > div > button.clicked + div,
          .Window__menu > div > button.clicked + div {
            visibility: visible;
            max-height: 480px; }
  .window section,
  .Window section {
    position: relative;
    border: 1px solid #ffffff;
    outline: 1px solid #808088;
    padding: 5px;
    margin: 16px 8px 8px; }
    .window section__title,
    .Window section__title {
      position: absolute;
      top: -10px;
      padding: 2px 4px;
      background-color: #bbc3c4; }
  .window--alert,
  .Window--alert {
    display: inline-flex;
    flex-direction: column;
    max-width: 250px; }
    .window--alert__message,
    .Window--alert__message {
      display: flex;
      align-items: center;
      user-select: none;
      min-height: 28px;
      padding: 10px 2px 6px; }
      .window--alert__message.has-icon,
      .Window--alert__message.has-icon {
        background-size: 28px 28px;
        background-repeat: no-repeat;
        background-position: 6px 6px;
        padding: 6px 4px 8px 40px; }
    .window--alert__actions,
    .Window--alert__actions {
      width: 100%;
      display: flex;
      justify-content: center; }
      .window--alert__actions .btn,
      .Window--alert__actions .btn {
        margin: 0px 4px 8px; }
`;
