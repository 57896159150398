import React from 'react';
import Box from '@material-ui/core/Box';
import Monospace from '../../../../../../Monospace';
import { useMemo } from 'react';

const WORDS = 'VARIATION. RAVIOTIAN. TARIAVION. VAOIANRTI. NORIATIAN. TORIANIAV. AVRIATION. ORAITAOIN. RONIATIAV. RAVIATION. NORTAIVAI. AIRATOINV. NOITAIRAV. IRAVNOITA. VATOINARI. TARIANIOV. ORAIATIVN. IVARATOIN. ITRIANAOV. RAVIATION. VNARIOTIA. NORIVATIA. TANOIIRAV. AITIRAVON. AIRAVNOIT. ROITNAIVA. AOITINVAR. TAINORIAV. RAITONIAV. ONTIVIARA. IONTIRAVI. ARIATIVON. ONTVAIARI. NVIRAIATO. RANIVAITO. NOVARITIA. OIANAVIRT. VIORANAIT. NOIRAVIAT. OAARINIVT. NORIATIAN. TORIANIAV. AVRIATION. ORAITAOIN. RONIATIAV. RAVIATION. NORTAIVAI. AIRATOINV. NOITAIRAV. IRAVNOITA. VATOINARI. TARIANIOV. ORAIATIVN. IVARATOIN. ITRIANAOV. RAVIATION. VNARIOTIA. NORIVATIA. TANOIIRAV. AITIRAVON. AIRAVNOIT. ROITNAIVA. AOITINVAR. TAINORIAV. RAITONIAV. ONTIVIARA. IONTIRAVI. ARIATIVON. ONTVAIARI. NVIRAIATO. RANIVAITO. NOVARITIA. OIANAVIRT. VIORANAIT. NOIRAVIAT. OAARINIVT.'.split(' ');

const Description = props => {
  const words = useMemo(() => WORDS.map((word, i) => {
    if (((i + 1) % 4) === 0) {
      return (
        <React.Fragment key={i}>
          {word}
          <br />
        </React.Fragment>
      );
    }
    return word;
  }), []);
  return (
    <Box {...props}>
      <Monospace>
        <b>
          {words}
          {words}
          {words}
          {words}
          {words}
          {words}
        </b>
      </Monospace>
    </Box>
  );
};

export default Description;
