import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Monospace from '../../../../Monospace';
import svgFrame from './frame.svg';
import pngTop from './top.png';
import pngBot from './bot.png';
import { useHistory } from 'react-router-dom';

const GlobalStyle = createGlobalStyle`
  body {
    transition: ${props => props.theme.transitions.create(['background-color'], { duration: 1500 })};
    background-color: ${props => props.bgColor || '#20B881'} !important;
  }
`;

const FrameImg = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const NextImg = styled.img`
  position: absolute;
  width: 130px;
  cursor: pointer;
  right: 60px;
  bottom: 20px;
`;

export default function Lime() {
  const [exit, setExit] = useState(false);
  const onExit = () => setExit(true);
  const history = useHistory();
  const onExited = () => history.push('/read/red-and-green');

  return (
    <>
      <GlobalStyle bgColor={exit && '#3C3737'} />
      <Fade in={!exit} timeout={1500} onExited={onExited}>
        <Box margin="auto">
          <Box position="relative" margin={8}>
            <Box position="absolute" right={0} bottom="100%" marginBottom={-5} zIndex={1}>
              <img src={pngTop} width={534} />
            </Box>
            <FrameImg src={svgFrame} />
            <Box margin={3} padding={5} maxWidth={1000}>
              <Monospace>
                Art market is one of the important criteria in evaluating and perceiving the works of art in society, which Michael Parsons doesn’t really include in his five stages of understanding art.
                <br /><br />
                It is a very complicated field, where the primary market selling new works for the first time and the secondary market, where the artworks are resold, mix organically. There are three core aspects that set up the value of the piece: quality, reputation and price. Aside from measurable factors that include state of prevention, scarcity and provenance, any other element of judgment concerning quality and originality is invariably subjective. If the artist has a large public visibility and recognition, it automatically reduces the chances of a bad investment, therefore the reputation of the maker becomes an important issue in promoting and selling. It is all about the artist’s brand that was established through various art prizes, right exhibitions and collaborations. The price is often connected to the two criteria listed above together with buyer’s expectations, market knowledge and personal taste. Sometimes, the so-called Veblen-Effect is present: a person wants to buy something just because of its monetary value, as a symbol for their luxury and prestige. Art news, artist rankings and price databases remain to be the most important evaluation tools in the contemporary art market.
                <br /><br />
                So, having all this information in mind, it means that if the artist wants their work to be valued highly in the world of art nowadays, they need to be active and visible. They have to participate in art events, promote their work and promote themselves. Established artists will have more chances to become successful as their work will be perceived differently than the one that was made by an unknown obscure artist. A young artist, with generally a limited production and less than five years of experience, is considered emerging. They usually set the prices by themselves, without the involvement of the galleries, whose fees would increase the final money value of the piece.
                <br /><br />
                Talking about contemporary art market we should not forget about trends. If you type “art market trends” in Google, you will get loads of results showing the lists of trending things connected to the current world events to the temporary extravagant novelties. Banksy shredding his painting or banana taped to the wall selling for $120k. There are artists who intentionally seek for hype and attention. In these kind of circumstances, you can say that the art world is an endless race for recognition, where perception of the artwork can be influenced by a lot of outside factors, sometimes not even connected to the actual quality or importance of the piece.
              </Monospace>
            </Box>
            <NextImg src={pngBot} onClick={onExit} />
          </Box>
        </Box>
      </Fade>
    </>
  );
}
