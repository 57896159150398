import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import Transition, { timeoutType } from '@hitagi/core/Transition';

const Collapse = forwardRef(function Collapse({ children, ...restProps }, ref) {
  const style = useMemo(() => ({
    enter: node => ({
      height: `${node.scrollHeight}px`,
    }),
    exit: {
      height: 0,
      overflow: 'hidden',
    },
  }), []);

  return (
    <Transition style={style} ref={ref} {...restProps}>
      {children}
    </Transition>
  );
});

Collapse.propTypes = {
  children: PropTypes.element.isRequired,
  timeout: timeoutType,
};

Collapse.defaultProps = {
  timeout: 400,
};

Collapse.hiName = 'Collapse';

export default Collapse;
