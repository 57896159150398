import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { monospaceCss } from '../../../../../../Monospace';

const blaSize = 14;

const Bla = styled.div`
  width: ${blaSize}px;
  height: ${blaSize}px;
  cursor: default;
`;

const Wrapper = styled.div`
  ${monospaceCss}

  font-size: ${blaSize}px;
  font-weight: 600;
  width: 47.6vw;
  height: 50vh;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
`;

const Blas = () => {
  const [blas, setBlas] = useState();

  useEffect(() => {
    const bla = () => {
      setBlas(Array(
        Math.floor(window.innerWidth / 2.1 / blaSize) * Math.floor(window.innerHeight / 2 / blaSize)
      ).fill());
    };
    bla();
    window.addEventListener('resize', bla);
    return () => window.removeEventListener('resize', bla);
  }, []);

  return (
    <Wrapper>
      {blas && blas.map((_, i) => {
        let letter;
        switch (i % 3) {
          case 0:
            letter = 'B';
            break;
          case 1:
            letter = 'L';
            break;
          case 2:
            letter = 'A';
            break;
          default:
            return null;
        }
        return <Bla key={i}>{letter}</Bla>;
      })}
    </Wrapper>
  );
};

export default Blas;
