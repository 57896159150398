import React from 'react';
import MuiTooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: ({ color = 'white' }) => theme.palette.common[color],
    color: ({ color = 'white' }) => color === 'white' ? 'rgba(0, 0, 0, 0.87)' : 'rgba(255, 255, 255, 0.87)',
    boxShadow: theme.shadows[10],
    fontSize: 13,
    padding: theme.spacing(1, 2),
    textAlign: 'center',
  },
  arrow: {
    color: ({ color = 'white' }) => theme.palette.common[color],
  },
}))(MuiTooltip);

const TooltipAnchor = styled.span`
  color: ${props => props.theme.palette.primary.main};
  cursor: help;
`;

export const Tooltip = ({ children, content, ...restProps }) => (
  <StyledTooltip arrow placement="top" title={content} {...restProps}>
    <TooltipAnchor>
      {children}
    </TooltipAnchor>
  </StyledTooltip>
);

export default Tooltip;
