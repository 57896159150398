import React, { useState, useEffect } from 'react';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import Buttons from './Buttons';
import Monospace from '../../Monospace';
import Tooltip from '../../Tooltip';

const TIMEOUT = 1000;

const Page4 = () => {
  const [bIn, setBIn] = useState(false);
  const [cIn, setCIn] = useState(false);

  useEffect(() => {
    const bTimeout = setTimeout(() => {
      setBIn(true);
    }, TIMEOUT / 2);
    const cTimeout = setTimeout(() => {
      setCIn(true);
    }, TIMEOUT);
    return () => {
      clearTimeout(bTimeout);
      clearTimeout(cTimeout);
    };
  }, []);

  return (
    <Box padding={3} maxWidth={1200} margin="auto">
      <Fade in timeout={TIMEOUT}>
        <Box>
          <Monospace>
            Visual perception is complex. It involves the interpretation, acquisition, selection and organization of sensory information in order to make sense of the world around us. It is also a very fragile thing because the way we perceive anything is influenced by our
            {' '}
            <Tooltip content="involve the ways in which our eyes, nervous system and brain reworks the information">
              physiological factors
            </Tooltip>
            {', '}
            <Tooltip content="mental processes, such as our past experiences, social and cultural background, memory, language, motivations, emotions">
              psychological factors
            </Tooltip>
            {' and '}
            <Tooltip content="the predisposition, or ‘readiness’ to perceive something in accordance with what we expect it to be">
              perceptual set
            </Tooltip>
            {' , that in turn can be influenced by '}
            <Tooltip content="setting or environment in which a perception is made">
              context
            </Tooltip>
            {', '}
            <Tooltip content="processes within us which activate behavior that is directed towards achieving a particular goal">
              motivation
            </Tooltip>
            {', '}
            <Tooltip content="different emotions can set us to perceive information in a particular way which is consistent with the emotion being experienced">
              emotional state
            </Tooltip>
            {', '}
            <Tooltip content="everything we learn through experiences, both intentionally and unintentionally">
              past experience
            </Tooltip>
            {' and '}
            <Tooltip content="the way of life of a particular community or group that sets it apart from other communities of groups">
              culture
            </Tooltip>
            .
          </Monospace>
        </Box>
      </Fade>

      <Fade in={bIn} timeout={TIMEOUT}>
        <Box marginY={6}>
          <Monospace>
            Moreover, perception as a topic is frequently used in various research studies. From R. W. Pickford, whose interest in art therapy developed a wider urge to do experiments in the field of neuroscience in order to understand visual perception and its role in art therapy, to Theodor Lipps, who suggested that aesthetic appreciation is experienced as belonging to the work of art rather than to the observer (empathy theory). Rudolf Arnheim in his “Art and Visual Perception” (1954) argued that perception was strongly identified with thinking, and that artistic expression was another way of reasoning. He also called perception the experiencing of ‘visual forces’ placing dynamic perception as opposed to mere mechanical recording of visual elements at the very root of aesthetic experience. There is also “Aesthetics and psychobiology” (1971) by Daniel Berlyne, in which he describes interpretations of measurable responses to art objects, where the aesthetic pleasure is pretty much dependent on the changes in levels of arousal, and motivational factors such as novelty, surprise and complexity that replace formal beauty or harmony as the fundamental basis of psychophysical aesthetics. Silvia P.J. (2005a, 2005b) claimed that evaluations of events, rather than events themselves, cause the emotional experience. Therefore, viewers get an emotional response from the artworks through its influence on appraisals (appraisals theory). Not to forget “Phenomenology of Perception” (1945) by Merleau-Ponty, in which the author expounds his thesis of “the primacy of perception”.
          </Monospace>
        </Box>
      </Fade>

      <Fade in={cIn} timeout={TIMEOUT}>
        <Box display="flex">
          <Buttons />
          <Box marginLeft={6}>
            <Monospace>
              There are plenty of thoughts, philosophical discussions and scientific experiments going on around this subject. It seems like every aspect of the word ‘perception’ has already been investigated under the microscope. But still, it remains mysterious and fascinating to me how our cognitive abilities sense the visual stimuli and create a certain reaction to it.
            </Monospace>
          </Box>
        </Box>
      </Fade>
    </Box>
  );
};

export default Page4;
