import React, { useContext, useEffect } from 'react';
import { ThemeContext } from 'styled-components';
import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import { PotatoPageContext } from '../../../../PotatoPageContext';
import styled from 'styled-components';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

const Corner = styled.div`
  width: 8px;
  height: 8px;
  position: absolute;
  transition: ${props => props.theme.transitions.create(['box-shadow', 'width', 'height'])};
`;

const BoomerBox = styled.div`
  background-color: white;
  max-width: 800px;
  padding: ${props => props.theme.spacing(3, 4, 4)};
  text-align: center;
  font-family: "VT323", sans-serif;
  font-weight: bold;
  font-size: 32px;
  line-height: 1;
  position: relative;
  transition: ${props => props.theme.transitions.create(['box-shadow', 'color'])};
  cursor: pointer;
  box-shadow: /* top */
              0 -3px rgba(255, 255, 255, 0.7),
              0 -6px black,
              0 -7px rgba(255, 255, 255, 0.2),
              0 -10px black,
              0 -13px white,
              0 -15px rgba(255, 255, 255, 0.7),
              0 -19px black,
              /* bottom */
              0 3px black,
              0 4px rgba(255, 255, 255, 0.2),
              0 7px black,
              0 10px rgba(0, 0, 0, 0.3),
              0 13px white,
              0 17px black,
              0 19px rgba(255, 255, 255, 0.1),
              /* left */
              -3px 0 white,
              -9px 0 black,
              -16px 0 white,
              /* right */
              3px 0 white,
              9px 0 black,
              16px 0 white;

  &::before, &::after {
    transition: ${props => props.theme.transitions.create(['width'])};
  }

  &::before {
    position: absolute;
    content: "";
    background: white;
    top: 11px;
    bottom: 11px;
    width: 8px;
    left: -16px;
    box-shadow: inset 4px 0 black,
                /* top */
                0 -5px white,
                0 -7px rgba(255, 255, 255, 0.7),
                0 -11px black,
                /* bottom */
                0 7px white,
                0 11px black;
  }

  &::after {
    position: absolute;
    content: "";
    background: white;
    top: 11px;
    bottom: 11px;
    width: 8px;
    right: -16px;
    box-shadow: inset -4px 0 black,
                /* top */
                0 -5px white,
                0 -7px rgba(255, 255, 255, 0.7),
                0 -11px black,
                /* bottom */
                0 7px white,
                0 11px black;
  }

  &:hover {
    box-shadow: none;
    color: white;

    &::before, &::after {
      width: 0;
    }

    ${Corner} {
      width: 0;
      height: 0;
      box-shadow: none;
    }
  }
`;

const TopLeftCorner = styled(Corner)`
  border-right: 4px solid black;
  border-bottom: 4px solid black;
  top: -4px;
  left: -4px;
  box-shadow: 0 2px rgba(0, 0, 0, 0.2),
              4px -2px rgba(255, 255, 255, 0.2);
`;

const TopRightCorner = styled(Corner)`
  border-left: 4px solid black;
  border-bottom: 4px solid black;
  top: -4px;
  right: -4px;
  box-shadow: 0 2px rgba(0, 0, 0, 0.2),
              -4px -2px rgba(255, 255, 255, 0.2);
`;

const BottomRightCorner = styled(Corner)`
  border-left: 4px solid black;
  border-top: 4px solid black;
  bottom: -4px;
  right: -4px;
`;

const BottomLeftCorner = styled(Corner)`
  border-right: 4px solid black;
  border-top: 4px solid black;
  bottom: -4px;
  left: -4px;
`;

const BlueberryBoomer = () => {
  const pageCtx = useContext(PotatoPageContext);
  const theme = useContext(ThemeContext);
  useEffect(() => pageCtx.setBgColor(theme.palette.blueberry), []);

  const [exit, setExit] = useState(false);
  const onExit = () => setExit(true);
  const history = useHistory();
  const onExited = () => history.push('/read/potato/art-in-ukraine/blueberry/variation1/i')

  return (
    <Grow in={!exit} timeout={1000} onExited={onExited}>
      <Box margin="auto">
        <BoomerBox onClick={onExit}>
          <TopLeftCorner />
          <TopRightCorner />
          <BottomRightCorner />
          <BottomLeftCorner />
          Visual perception is not only important to me because of the
          different cultural roots influencing the way people value and
          judge the work of art, but also because my whole artistic
          process is focused around the wide concept of perception.
          To me it is about the dialogue between the artist and the
          audience, the artist and his history, the artist and his work.
        </BoomerBox>
      </Box>
    </Grow>
  );
};

export default BlueberryBoomer;
