import React, { useRef, useEffect } from 'react';
import Rellax from 'rellax';
import Box from '@material-ui/core/Box';
import EverythingLines from './EverythingLines';
import text from './text.svg';
import text_something from './text_something.svg';
import text_date from './text_date.svg';
import white_stroke from './white_stroke.svg';
import fake_me_hard from './fake_me_hard.png';
import eyes from './eyes.png';
import drawing from './drawing.png';

const Section3 = () => {
  const dateRef = useRef();
  const strokeRef = useRef();
  const fakeRef = useRef();
  const eyesRef = useRef();
  const drawingRef = useRef();
  useEffect(() => {
    new Rellax(dateRef.current,   { speed: -1 });
    new Rellax(strokeRef.current, { speed: -1 });
    new Rellax(fakeRef.current,   { speed: -0.2 });
    new Rellax(eyesRef.current,   { speed: -0.9 });
    new Rellax(drawingRef.current,{ speed: -0.8 });
  }, []);

  return (
    <Box position="relative" marginTop="598px">
      <Box position="absolute" top={-57} left={741}>
        <img src={text_something} />
      </Box>
      <Box ref={dateRef} position="absolute" top={175} left={320}>
        <img src={text_date} />
      </Box>
      <Box ref={fakeRef} position="absolute" top={-36} left={-4}>
        <img src={fake_me_hard} width={202} />
      </Box>
      <Box ref={eyesRef} position="absolute" left={148} top={60}>
        <img src={eyes} width={54} />
      </Box>
      <Box ref={strokeRef} position="absolute" top={-40} left={5}>
        <img src={white_stroke} />
      </Box>
      <Box position="absolute" left={232}>
        <img src={text} />
      </Box>
      <Box ref={drawingRef} position="absolute" left={89} top={130}>
        <img src={drawing} width={113} />
      </Box>
      <Box position="absolute" top={380} zIndex={1}>
        <EverythingLines />
      </Box>
    </Box>
  );
};

export default Section3;
