import React, { useState } from 'react';
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import UntitledGallery from '../../../UntitledGallery';
import Monospace from '../../../Monospace';
import { useHistory } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    transition: ${props => props.theme.transitions.create(['background-color'], { duration: 3000 })};
    background-color: ${props => props.bgColor || '#E8E9E7'} !important;
  }
`;

const IMAGES = [
  {
    sizes: '(max-width: 2740px) 100vw, 2740px',
    srcSet: [
      '/images/what-is-painting/IMG_0379_sirxzm_c_scale,w_600.jpg 600w',
      '/images/what-is-painting/IMG_0379_sirxzm_c_scale,w_1268.jpg 1268w',
      '/images/what-is-painting/IMG_0379_sirxzm_c_scale,w_1726.jpg 1726w',
      '/images/what-is-painting/IMG_0379_sirxzm_c_scale,w_2115.jpg 2115w',
      '/images/what-is-painting/IMG_0379_sirxzm_c_scale,w_2518.jpg 2518w',
      '/images/what-is-painting/IMG_0379_sirxzm_c_scale,w_2740.jpg 2740w',
    ],
    src: '/images/what-is-painting/IMG_0379_sirxzm_c_scale,w_2740.jpg',
  },
  {
    sizes: '(max-width: 3024px) 100vw, 3024px',
    srcSet: [
      '/images/what-is-painting/IMG_0380_epbkea_c_scale,w_600.jpg 600w',
      '/images/what-is-painting/IMG_0380_epbkea_c_scale,w_1404.jpg 1404w',
      '/images/what-is-painting/IMG_0380_epbkea_c_scale,w_1906.jpg 1906w',
      '/images/what-is-painting/IMG_0380_epbkea_c_scale,w_2443.jpg 2443w',
      '/images/what-is-painting/IMG_0380_epbkea_c_scale,w_2901.jpg 2901w',
      '/images/what-is-painting/IMG_0380_epbkea_c_scale,w_3024.jpg 3024w',
    ],
    src: '/images/what-is-painting/IMG_0380_epbkea_c_scale,w_3024.jpg',
  },
  {
    sizes: '(max-width: 3024px) 100vw, 3024px',
    srcSet: [
      '/images/what-is-painting/IMG_0382_rictkt_c_scale,w_600.jpg 600w',
      '/images/what-is-painting/IMG_0382_rictkt_c_scale,w_1428.jpg 1428w',
      '/images/what-is-painting/IMG_0382_rictkt_c_scale,w_2000.jpg 2000w',
      '/images/what-is-painting/IMG_0382_rictkt_c_scale,w_2490.jpg 2490w',
      '/images/what-is-painting/IMG_0382_rictkt_c_scale,w_2954.jpg 2954w',
      '/images/what-is-painting/IMG_0382_rictkt_c_scale,w_3024.jpg 3024w',
    ],
    src: '/images/what-is-painting/IMG_0382_rictkt_c_scale,w_3024.jpg',
  },
  {
    sizes: '(max-width: 3024px) 100vw, 3024px',
    srcSet: [
      '/images/what-is-painting/IMG_0383_e9brg6_c_scale,w_600.jpg 600w',
      '/images/what-is-painting/IMG_0383_e9brg6_c_scale,w_1410.jpg 1410w',
      '/images/what-is-painting/IMG_0383_e9brg6_c_scale,w_1977.jpg 1977w',
      '/images/what-is-painting/IMG_0383_e9brg6_c_scale,w_2461.jpg 2461w',
      '/images/what-is-painting/IMG_0383_e9brg6_c_scale,w_2910.jpg 2910w',
      '/images/what-is-painting/IMG_0383_e9brg6_c_scale,w_3024.jpg 3024w',
    ],
    src: '/images/what-is-painting/IMG_0383_e9brg6_c_scale,w_3024.jpg',
  },
  {
    sizes: '(max-width: 3024px) 100vw, 3024px',
    srcSet: [
      '/images/what-is-painting/IMG_0384_e57cnv_c_scale,w_600.jpg 600w',
      '/images/what-is-painting/IMG_0384_e57cnv_c_scale,w_1402.jpg 1402w',
      '/images/what-is-painting/IMG_0384_e57cnv_c_scale,w_1906.jpg 1906w',
      '/images/what-is-painting/IMG_0384_e57cnv_c_scale,w_2449.jpg 2449w',
      '/images/what-is-painting/IMG_0384_e57cnv_c_scale,w_2923.jpg 2923w',
      '/images/what-is-painting/IMG_0384_e57cnv_c_scale,w_3024.jpg 3024w',
    ],
    src: '/images/what-is-painting/IMG_0384_e57cnv_c_scale,w_3024.jpg',
  },
];

const WhatIsPaining = () => {
  const [ready, setReady] = useState(false);

  const [exit, setExit] = useState(false);
  const onExit = () => setExit(true);
  const history = useHistory();
  const onExited = () => history.push('/read/pinky/untitled2');

  return (
    <Slide in direction="up" timeout={500}>
      <Box margin="auto">
        <GlobalStyle bgColor={exit ? '#BABFBF' : undefined} />
        <Fade in={ready && !exit} timeout={3000} onExited={onExited}>
          <Box margin={4}>
            <Box display="flex" alignItems="flex-end">
              <UntitledGallery images={IMAGES} onLoad={setReady} />
              <Box bgcolor="#D2D3D5" width={700} marginLeft={1} padding={2} border={1}>
                <Monospace>
                  This is a documentation of my thinking process in the crowded and empty space, part of my research on the definition of the painting.
                  <br /><br />
                  What is a painting to me? I realized that traditional way of characterizing the word “painting” (a solid surface with applied paint, pigment or other medium on it) was no longer relevant for me. Meanwhile, whenever I sat in my studio and thought about my future works, I always portrayed the wanted result in my head. Could that thinking process be a painting on its own? How can I grasp that temporary image? I took a piece of fabric and started to write down everything what was in my mind at that moment: from the colour of the painting I wanted to make to the placement I wanted it to have. However, people decided to come to the room and my disturbed documentation begun to absorb all the outside elements that surrounded me: the conversations, sounds, random observations and everything what was occupying my thoughts. I stopped when the fabric finished (10 min). I repeated this experiment in the empty room and it took me 60 min to finish exactly the same piece of fabric.
                  <br /><br />
                  I call this a painting.
                </Monospace>
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" marginTop={2}>
              <Monospace>
                <b>What is a painting? (2019)</b><br />
                ink on fabric, wooden frame
              </Monospace>
              <Monospace style={{ cursor: 'pointer' }} onClick={onExit}>
                <b>NEXT</b>
              </Monospace>
            </Box>
          </Box>
        </Fade>
      </Box>
    </Slide>
  );
};

export default WhatIsPaining;
