import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import { useHistory } from 'react-router-dom';
import Monospace from '../../../../Monospace';
import DrawingBox from '../../../../DrawingBox';
import ReadMore from '../../../../ReadMore';

const QuotationMark = styled.div.attrs({ children: '”' })`
  font-family: "VT323", sans-serif;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 100px;
  line-height: 1;
  transform: translateY(-24px);
  color: rgba(70, 70, 70);
`;

const GtMark = styled.div.attrs({ children: '>' })`
  font-family: "VT323", sans-serif;
  font-size: 100px;
  line-height: 1;
  color: rgba(70, 70, 70);
  text-align: right;
  cursor: pointer;
  position: absolute;
  left: 100%;
  transition: ${props => props.theme.transitions.create(['padding-left'])};

  animation-delay: 1s;
  animation-fill-mode: forwards;
  animation: ${keyframes`
    from {
      opacity: 0;
      margin-left: -20px;
    }
    to {
      opacity: 1;
      margin-left: 0;
    }
  `} 1s;

  &:hover {
    padding-left: ${props => props.theme.spacing(1)}px;
  }
`;

const Home = () => {
  const [isDrawinBoxDrawn, setIsDrawinBoxDrawn] = useState(false);
  const onDrawingBoxDrawn = () => {
    setTimeout(() => {
      setIsDrawinBoxDrawn(true);
    }, 1000);
  };

  const [slide, setSlide] = useState(false);
  const onSlide = () => {
    setSlide(true);
  };

  const history = useHistory();
  const onExited = () => {
    history.push('/read/potato/desktop')
  };

  return (
    <Slide in={!slide} direction="right" timeout={{ enter: 0, exit: 1000 }} onExited={onExited}>
      <Box maxWidth={1200} display="flex" margin="auto" paddingY={3} paddingX={2} textAlign="justify" position="relative">
        <Fade in timeout={1000}>
          <Box width="55%">
            <Monospace>
              I grew up in Ukraine, where aesthetical criteria, beauty and craftmanship are very important in order to perceive artworks. In fact, a good artwork is put on a pedestal when it is made with a great amount of effort and skills and represents something beautiful (usually figurative drawings or paintings): could be a snowy mountain landscape or a holy scene. Of course, people who are more or less aware of the worldwide art scene have different opinions but the majority of the population in Ukraine will say something like “Art is not a bunch of sticks and shit” when looking at the contemporary art piece.
            </Monospace>
            <br />
            <Monospace>
              I used to think in the same way until I came to The Netherlands.
            </Monospace>
            <br />
            <Monospace>
              Having some distance from the place where I was born and seeing a bunch of new possibilities in a different country made me realize how much the cultural background, knowledge and willingness to make art accessible for everyone can change the way people perceive it, and therefore, establish peculiar values. At first, I was shocked by the criteria in which art was judged here, then I was filled with anger at the stereotypical Ukrainian mind set. I wanted to get rid of it because I thought that this way of thinking was stopping me from making steps in my artistic practice. The idea of how society perceived art and identified beauty was always present in the background of making any artwork for me. After some time, I finally realised that the only way to move forward was to accept it.
            </Monospace>
            <br />
            <Monospace>
              It is true that for a lot of people Kandinsky’s paintings are just a mysterious and weird combination of lines, circles and waves, and the work of a realistic painter such us Ilya Repin is considered as a more beautiful and valuable in my country. And I find it interesting how depending on the location the work of different artists will be more or less important in art history. Plus, depending on the knowledge behind the viewer’s back the work of art will also be perceived differently.
            </Monospace>
            <br />
            <Monospace>
              It is still hard for me to talk with my grandmother about what I am working on now. It is still hard to explain to her why I switched from pleasant traditional academic paintings to installations made of crappy materials.
            </Monospace>
          </Box>
        </Fade>
        <Box width={50} />
        <DrawingBox width="45%" minWidth={540} position="relative" onDrawn={onDrawingBoxDrawn}>
          <QuotationMark />
          <Box overflow="hidden" height="100%" display="flex" flexDirection="column">
            <Monospace>
              How does expertise influence the perception of representational and abstract paintings? We asked 20 experts on art history and 20 laypersons to explore and evaluate a series of paintings ranging in style from representational to abstract in five categories. We compared subjective esthetic judgments and emotional evaluations, gaze patterns, and electrodermal reactivity between the two groups of participants. The level of abstraction affected esthetic judgments and emotional valence ratings of the laypersons but had no effect on the opinions of the experts: the laypersons’ esthetic and emotional ratings were highest for representational paintings and lowest for abstract paintings, whereas the opinions of the experts were independent of the abstraction level. The gaze patterns of both groups changed as the level of abstraction increased: the number of fixations and the length of the scanpaths increased while the duration of the fixations decreased. The viewing strategies – reflected in the target, location, and path of the fixations – however indicated that experts and laypersons paid attention to different aspects of the paintings. The electrodermal reactivity did not vary according to the level of abstraction in either group but expertise was reflected in weaker responses, compared with laypersons, to information received about the paintings.
            </Monospace>
            <Box flexGrow={1} />
            <Monospace>
              <ReadMore href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3170917/" target="_blank" rel="noreferrer noopener">
                Pihko E., Virtanen A., Saarinen V., Pannasch S., Hirvenkari L., Tossavainen T., Haapala A., Hari R. Experiencing Art: The Influence of Expertise and Painting Abstraction Level. Front Hum Neurosci. June 14, 2011.
              </ReadMore>
            </Monospace>
          </Box>
        </DrawingBox>
        {isDrawinBoxDrawn && (
          <Fade in={!slide} timeout={900}>
            <Box alignSelf="center" marginTop="-100px">
              <GtMark onClick={onSlide} />
            </Box>
          </Fade>
        )}
      </Box>
    </Slide>
  );
};

export default Home;
