import React from 'react';

const Pepper = props => {
  return (
    <svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M50.4017 44.1058C39.5453 39.6427 43.6193 51.725 47.6603 58.1806C52.3537 63.8722 56.6725 67.9914 50.9723 72.6918C46.5272 76.3573 42.8822 80.225 35.5166 78.2523C31.3026 77.1237 28.2605 71.9384 25.2847 68.3296C17.5048 58.895 9.70568 46.0054 11.5188 35.6172C12.5042 29.9713 19.6929 26.6418 23.5372 23.4717C28.0453 19.7544 35.057 10.0267 42.8491 12.5761C47.186 13.9951 50.9193 14.6278 55.2702 17.4207C57.9171 19.1197 59.496 21.9348 62.2892 23.4471C64.9943 24.9116 67.4744 26.167 69.9437 28.3108C76.4553 33.9641 76.3351 43.0384 70.2627 45.1304C63.6504 47.4084 58.0488 47.2495 50.4017 44.1058Z"
        fill="#FF3D00"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Pepper;
