import React from 'react';

const EverythingLines = () => {
  return (
    <svg width="796" height="154" viewBox="0 0 796 154" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 9C12.2869 6.17827 24.3615 5.78466 35.8889 4.05556C46.4858 2.46602 56.8702 1 67.6111 1C98.8787 1 130.157 2 161.444 2C168.389 2 175.333 2 182.278 2C186.91 2 190.042 3.49232 194.333 5.77778C203.415 10.6143 214.363 15.8903 221.5 23.5556C234.483 37.4997 239.724 55.5301 250.111 71.1111C266.952 96.3723 290.831 108.281 320.111 114.222C337.006 117.65 355.208 118.681 372.444 117.889C381.814 117.458 391.082 115.656 400.444 115C417.452 113.809 434.518 114 451.556 114C466.094 114 479.263 114.43 493.167 119.111C511.586 125.313 529.218 133.697 547.111 141.222C575.393 153.117 601.785 158.199 631.444 148C655.235 139.819 678.659 129.931 701.5 119.389C709.085 115.888 716.372 111.905 724.222 109C747.088 100.537 770.901 98.6052 795 96" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M1 12C20.0549 12 37.9012 10.489 56.4444 6.55556C74.36 2.7553 93.7873 8.09237 111.944 5.44445C128.83 2.98201 144.986 2.00001 162.111 2.00001C177.192 2.00001 193.012 0.743507 208.056 2.11112C221.388 3.3232 226.09 15.6057 232.889 24.8889C239.447 33.8432 244.217 42.5905 248.222 52.8889C255.482 71.5576 269.437 85.6911 288 93.7778C304.904 101.142 325.629 98 343.889 98C361.759 98 379.63 98 397.5 98C419.433 98 442.338 100.483 463.222 107.444C490.24 116.451 515.74 126.785 544.222 130.5C557.065 132.175 569.172 136.447 582.222 136.889C603.548 137.612 625.092 137.585 646 133C674.753 126.694 701.557 115 730.556 110C746.381 107.271 762.869 108.568 778.333 104.444C782 103.467 786.217 104 790 104" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4 18C26.2166 15.5315 48.7459 13.9435 70.7778 10.0555C78.5534 8.68337 86.5329 6.99998 94.4444 6.99998C111.185 6.99998 127.926 6.99998 144.667 6.99998C169.369 6.99998 206.507 0.85804 221 26.7778C232.521 47.3833 242.966 71.3486 261 87.3333C273.473 98.3885 290.65 105.244 306.333 110.111C328.601 117.022 350.698 124 374.111 124C394.296 124 414.481 124 434.667 124C457.029 124 479.832 125.184 502.167 123.889C509.809 123.446 517.02 122 524.778 122C538.48 122 552.188 121.877 565.889 122C585.126 122.173 603.642 126.414 622.889 125.944C642.73 125.46 661.588 119.518 680.778 115.333C693.838 112.486 707.127 110.898 720.167 108C726.772 106.532 732.842 104.553 739.611 104C751.704 103.013 763.674 103.263 775.778 103C781.017 102.886 785.782 101 791 101" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4 24C12.9417 22.8536 21.383 19.9676 30.3333 19C36.8628 18.2941 43.6708 18.5715 50.1111 17.4445C67.8527 14.3397 86.0892 12.566 103.556 8.8889C119.376 5.55826 136.048 7.00001 152.278 7.00001C158.948 7.00001 165.672 6.72252 172.333 7.05556C185.183 7.69803 196.174 14.2658 206.222 21.8889C216.704 29.8406 225.44 40.7541 232.611 51.6667C239.387 61.9779 245.882 72.7705 254.556 81.4445C264.348 91.2371 279.189 99.9321 292.611 103C313.78 107.839 335.417 103.96 356.667 103.056C378.791 102.114 401.073 102 423.222 102C440.938 102 458.8 101.251 476.444 102.778C485.698 103.579 494.406 105.887 503.444 108C512.437 110.102 521.581 110.337 530.778 110.778C553.213 111.853 577.061 108.388 599.333 112C616.495 114.783 633.057 116.901 650.111 112.333C673.557 106.053 694.826 96.3095 719 93.5556C743.336 90.7831 767.524 91 792 91" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4 33C18.0925 32.573 31.5176 28.6047 45.3333 27.1111C64.1477 25.0771 82.8556 25.0068 101.333 19.9445C108.812 17.8954 115.981 15.1925 123.556 13.4445C130.639 11.8099 137.326 8.1483 144.667 8.00001C154.436 7.80265 164.228 8.00001 174 8.00001" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3 43C23.0469 41.8863 41.1895 32 61.3333 32C75.802 32 90.696 33.0514 105.056 31C116.648 29.3439 127.31 25.6046 137.889 20.5556C144.199 17.5441 152.073 15.0173 158.889 13.4444C163.017 12.4917 167.282 13.4061 171.444 12.7778C176.347 12.0378 180.131 11 185 11" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M2 36C19.1456 36 36.6171 33.8099 53.4444 30.4444C60.7462 28.9841 67.9552 27.385 75.3889 26.5C85.5332 25.2923 95.9077 27.0475 106 25.5556C127.127 22.4325 145.673 11 167.5 11C175.667 11 183.833 11 192 11" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4 28C21.5957 25.899 39.1562 24.0524 56.6667 21.4444C69.7323 19.4985 83.47 19.0591 96.1111 15C112.384 9.77466 128.931 7 146 7" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M2 51C25.4326 48.1768 49.3036 46.6632 72 39.7778C80.0486 37.3361 87.9494 35.072 96.4444 35C105.011 34.9274 113.397 35.0956 121.889 33.7778C131.026 32.3599 138.881 30.4886 147.556 27C154.8 24.0864 161.346 19.2048 169.333 19C179.542 18.7382 189.787 19 200 19" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12 46C23.4438 43.9401 35.2331 42.4409 46.7778 41.0556C61.7487 39.259 76.4767 34.2713 91.5 32.1111C112.232 29.13 133.951 27.7681 154.278 22.4444C161.95 20.4352 168.891 18.7347 176 15.2222C180.675 12.9122 183.932 12 189 12C196.715 12 199.09 15.3601 201 23" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M2 57C13.82 56.8769 25.3915 55.9389 37.1111 54.4444C43.3837 53.6446 49.0558 51.5263 55.2222 50.4444C64.6137 48.7968 74.5655 48.5525 83.6667 45.7778C94.2478 42.5518 105.216 40.3393 116.111 38.4444C120.899 37.6118 125.751 38.3701 130.556 37.7778C135.274 37.1961 139.663 34.6472 144.056 33C154.459 29.0988 164.678 24.8691 174.722 20.1111C180.777 17.2431 187.332 18 193.889 18C199.264 18 201.051 20.4819 204.944 23.8889C209.644 28.0009 211.641 29.7282 218 31" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

export default EverythingLines;
