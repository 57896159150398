import React, { useState, useLayoutEffect, useRef, useEffect } from 'react';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import Definition from '../Definition';
import RedButt from '../RedButt/RedButt';

const RedButtDevourer = createGlobalStyle`
  body {
    background-color: ${props => props.theme.palette.devoured} !important;
    transition: background-color 1s;
  }
`;

const Page0 = () => {
  const history = useHistory();

  const [shouldShowRedButt, setShouldShowRedButt] = useState(false);
  useLayoutEffect(() => {
    const timeout = setTimeout(() => setShouldShowRedButt(true), 5000);
    return () => clearTimeout(timeout);
  }, []);

  const [isDevouring, setIsDevouring] = useState(false);
  const onDevourStart = () => setIsDevouring(true);
  const onDevourEnd = () => history.push('/read/1')

  const [defHeight, setDefHeight] = useState();
  const defRef = useRef();
  useEffect(() => {
    setDefHeight(defRef.current.clientHeight);
  }, []);

  return (
    <Box position="relative" margin="auto" padding={1}>
      <Fade in timeout={1000}>
        <Definition ref={defRef} />
      </Fade>
      {shouldShowRedButt && (
        <Box position="absolute" left="100%" top={defHeight} marginTop={4} marginLeft={8}>
          {isDevouring && <RedButtDevourer />}
          <RedButt onDevourStart={onDevourStart} onDevourEnd={onDevourEnd} />
        </Box>
      )}
    </Box>
  );
};

export default Page0;
