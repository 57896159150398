import React, { useState, useCallback } from 'react';
import Fade from '@material-ui/core/Fade';
import styled from 'styled-components';

const defs = {
  1: { x: 267, y: 60,   width: 162, height: 211,
       caption: ['The Starting Point, 2017', '42 x 60 cm', 'paper and wood assemblage'], },
  2: { x: 688, y: 386,  width: 194, height: 145,
       caption: ['Untitled, 2018', 'various sizes', 'acrylic on fabric'], },
  3: { x: 227, y: 541,  width: 154, height: 180,
       caption: ['Untitled, 2018', '30 x 30 cm', 'wood, fabric and metal', 'assemblage'], },
  4: { x: 692, y: 873,  width: 169, height: 168,
       caption: ['Untitled, 2018', '30 x 30 cm', 'wood, fabric and metal assemblage'], },
  5: { x: 276, y: 1080, width: 171, height: 228,
       caption: ['Untitled, 2018', '40 x 100 cm', 'wood and fabric construction'], },
  6: { x: 600, y: 1386, width: 172, height: 228,
       caption: ['Foldable Painting, 2018', '130 x 200 cm', 'acrylic on fabric'], },
  7: { x: 283, y: 1683, width: 147, height: 198,
       caption: ['Simple And Complex, 2018', '30 x 40 cm', 'acrylic on fabric, charmeuse'], },
};

const Caption = styled.text`
  fill: white;
  font-size: 8px;
  font-family: "Courier New", Courier, monospace;
`;

const GridImage = ({ onClick, id }) => {
  const [hover, setHover] = useState(false);
  const onMouseEnter = useCallback(() => setHover(true), []);
  const onMouseLeave = useCallback(() => setHover(false), []);

  const { caption, ...def } = defs[id];
  const renderCaptionLine = useCallback((line, i) => (
    <tspan x={def.x + 5} dy={i > 0 ? 10 : 0}>
      {line}
    </tspan>
  ), [def.x]);

  return (
    <g className="reimg">
      <image
        {...def}
        href={`/images/grid/${id}.jpg`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        data-id={id}
      />
      <Fade in={hover} timeout={200}>
        <g>
          <rect
            x={def.x}
            y={def.y + def.height}
            width={def.width}
            height={caption.length * 10 + 8}
            fill="black"
          />
          <Caption y={def.y + def.height + 10}>
            {caption.map(renderCaptionLine)}
          </Caption>
        </g>
      </Fade>
    </g>
  );
};

export default GridImage;
