import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const Pinky = props => {
  const theme = useContext(ThemeContext);
  const pinkyColor = theme.palette.pinky;
  return (
    <svg width="53" height="56" viewBox="0 0 53 56" {...props}>
      <path shapeRendering="geometricPrecision" d="M0.5 26C0.5 11 18.5 0.5 27 1.5C35.5 2.5 40 4.8 46 10C50.8017 14.1615 54 27.5 51 33C51 39 48.0369 45.6827 43 50C29 62 14.5 48.5 13.5 48C12.5 47.5 0.5 41 0.5 26Z" fill={pinkyColor} stroke="black" />
    </svg>
  );
};

export default Pinky;
