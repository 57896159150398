import React from 'react';

const Pitaya = props => {
  return (
    <svg width="50" height="56" viewBox="0 0 50 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M24 0.999966C9.58189 0.656654 5.16668 13.3332 4.00002 20.4999C-3.51655 34.698 7.5 49.6341 15.5 53C26.2951 57.542 40.3627 53.9119 45.5 38.5C47.6483 34.069 48.5768 30.0756 48.5 26C48.255 13.0129 39.9839 1.38056 24 0.999966Z"
        fill="#DB6060"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Pitaya;
