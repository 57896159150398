import styled from 'styled-components';

export const QuotesWrapper = styled.div`
  position: relative;
  margin: auto;
  width: 1000px;
  height: 500px;

  @media screen and (min-height: 800px) {
    transform: scale(1.2);
  }
`;
