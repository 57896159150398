import React from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import Page0 from './Page0';
import Page1 from './Page1';
import Page2 from './Page2';
import Page3 from './Page3';
import Page4 from './Page4';
import Page5Avocado from './Page5Avocado';
import Page6Potato from './Page6Potato';
import Page7Pinky from './Page7Pinky';
import Exercise from './Exercise';
import RedAndGreen from './RedAndGreen';
import SquarePainting from './SquarePainting';
import Teotr from './Teotr';

const Thesis = () => {
  const { url } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${url}`}>
        <Page0 />
      </Route>
      <Route path={`${url}/1`}>
        <Page1 />
      </Route>
      <Route path={`${url}/2`}>
        <Page2 />
      </Route>
      <Route path={`${url}/3`}>
        <Page3 />
      </Route>
      <Route path={`${url}/4`}>
        <Page4 />
      </Route>
      <Route path={`${url}/avocado`}>
        <Page5Avocado />
      </Route>
      <Route path={`${url}/potato`}>
        <Page6Potato />
      </Route>
      <Route path={`${url}/pinky`}>
        <Page7Pinky />
      </Route>
      <Route path={`${url}/exercise`}>
        <Exercise />
      </Route>
      <Route path={`${url}/red-and-green`}>
        <RedAndGreen />
      </Route>
      <Route path={`${url}/square-painting`}>
        <SquarePainting />
      </Route>
      <Route path={`${url}/teotr`}>
        <Teotr />
      </Route>
    </Switch>
  );
};

export default Thesis;
