import React from 'react';

const EverythingLines = () => (
  <svg width="831" height="402" viewBox="0 0 831 402" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M822 401C793.36 401 763.389 402.054 735.111 396.556C730.683 395.695 726.011 395.674 721.778 394C717.611 392.353 714.831 391 710.222 391C693.366 391 676.378 391.599 659.556 390.444C635.132 388.768 611.35 387 586.889 387C574.222 387 561.556 387 548.889 387C540.037 387 531.185 387 522.333 387C512.278 387 498.857 389.402 489.333 385.889C473.296 379.973 458.079 368.552 445.222 357.556C438.952 352.193 433.442 346.027 426.444 341.556C417.748 335.998 408.229 331.381 398.611 327.667C390.138 324.395 381.222 321.452 372.444 319.111C362.154 316.367 351.088 316.475 340.611 314.5C317.139 310.075 292.846 307.404 268.944 307.056C225.499 306.421 182.004 307 138.556 307C126.649 307 112.576 309.335 101.667 303.5C92.5147 298.605 83.8507 290.596 77 282.889C63.8646 268.112 50.8443 252.526 43 234.222C40.1718 227.623 38.7218 220.684 35.3889 214.389C33.0641 209.998 31.2685 205.411 29 201C24.7355 192.708 17.6185 186.861 14.6667 177.667C9.75119 162.356 8.42861 144.108 11.2222 128.278C13.3716 116.098 19.5671 103.353 24 91.7778C26.6631 84.8241 31.5612 78.2113 35.3889 71.9444C41.5701 61.8243 47.9469 51.9096 54.7778 42.2222C59.0143 36.214 62.172 29.3511 66.2222 23.4444C69.7549 18.2926 71.6248 12.0627 75 7" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M830.195 401C812.269 401 794.343 401 776.417 401C768.482 401 760.628 400 752.75 400C747.624 400 742.013 400.654 736.972 399.778C732.916 399.072 728.103 397.142 724.195 397C717.88 396.77 711.515 397 705.195 397C688.841 397 672.966 395 656.695 395C634.713 395 612.732 395 590.75 395C571.872 395 553.341 393 534.417 393C518.112 393 500.58 392.601 484.861 388C471.422 384.067 459.168 374.248 448.417 365.778C434.606 354.897 423.129 340.293 406.084 334.444C401.163 332.756 396.061 331.287 390.972 330.222C381.928 328.329 373.766 325.042 364.917 322.5C333.402 313.448 300.37 313.275 267.861 312C244.422 311.081 221.287 307 197.806 307C180.602 307 163.398 307 146.195 307C134.122 307 121.98 305.667 109.917 304.889C102.074 304.383 93.252 303.039 87.9725 296.667C86.3065 294.656 84.7831 292.708 83.028 290.778C75.6436 282.655 71.275 272.982 65.2503 263.944C59.7759 255.733 54.6588 246.626 49.9725 237.889C39.9088 219.126 26.4933 202.129 17.1947 183C13.6162 175.639 10.2733 167.846 6.97247 160.333C1.3998 147.651 0.369587 135.198 1.30581 121.389C2.57826 102.62 16.3155 88.4772 27.6391 74.8889C34.14 67.0879 39.395 58.3752 46.3614 50.9444C52.5621 44.3303 59.9723 39.3605 66.4169 33.1111C75.0205 24.7683 78.5421 11.9577 82.1947 1" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M823 395C804.667 395 786.333 395 768 395C762.185 395 755.312 396.119 749.778 394C742.081 391.052 732.773 389.45 724.556 389C718.912 388.691 713.634 386.677 708 386.222C700.351 385.605 692.555 386 684.889 386C669.899 386 654.679 385.152 639.778 387C624.671 388.874 609.814 391 594.556 391C581.296 391 568.037 391 554.778 391C539.411 391 524.339 391.59 509.333 387.444C498.922 384.568 486.678 379.525 478.333 372.778C472.656 368.188 465.389 363.961 461.222 357.778C457.642 352.465 452.385 346.728 447.778 342.222C441.013 335.608 430.544 333.467 422.222 329.778C414.191 326.218 406.825 321.468 399 317.556C385.445 310.778 369.626 309.04 354.667 307C342.402 305.328 330.33 303.442 318 302.222C288.142 299.269 258.381 293 228.333 293C211.079 293 193.753 292.533 176.556 294.222C165.717 295.287 155.185 297.909 144.222 298C130.468 298.115 112.79 301.293 101 292.778C90.0084 284.839 84.5878 272.739 75.2222 263.222C60.6295 248.394 55.2507 226.073 42.4444 209.444C33.784 198.199 25.2172 183.013 22.4444 169.222C20.173 157.925 21 146 21 134.556C21 128.034 19.6474 119.69 21.6667 113.333C25.2205 102.145 33.4872 91.8213 41.1111 83.2222C49.8357 73.3817 57.9198 62.6666 64.7778 51.4444C73.3579 37.4042 90 22.7179 90 5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

export default EverythingLines;
