import React from 'react';
import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0% {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
`;

const PSVG = styled.svg`
  pointer-events: none;
  animation: ${pulse} 1s infinite;
`;

const Wrapper = styled.div`
  transition: transform 0.2s ease;

  &:hover {
    cursor: pointer;
    transform: scale3d(1.2, 1.2, 1);
  }
`;

const Shit = () => {
  return (
    <Wrapper>
      <PSVG width="81" height="82" viewBox="0 0 81 82" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.1817 30.1918L18.5674 41.5179L18.9657 55.007L27.3286 52.8569L43.2073 56.1095L52.2738 49.1041L61.8695 61.523L70.936 54.5177L74.7159 42.1117L67.2063 32.3925C63.4515 27.533 57.3179 29.6852 52.7287 23.7458L42.2986 10.247C40.2961 7.65518 34.1247 10.239 31.2893 11.855L21.5254 19.3992L23.1817 30.1918Z" fill="#C18231" stroke="black" stroke-width="2"/>
      </PSVG>
    </Wrapper>
  );
};

export default Shit;
