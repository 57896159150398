import React, { useContext, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { AvocadoPageContext } from '../AvocadoPageContext';
import MultilineTypist from '../../../MultilineTypist';
import { monospaceCss } from '../../../Monospace';

const ItRemindsMeOfSomething = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@900&display=swap');
  font-family: 'Rubik', sans-serif;
  font-size: 72px;
  line-height: 1;
  color: #CDCBC9;
  transform: scale3d(1.1, 0.9, 1);
`;

const Info = styled.div`
  ${monospaceCss}
  transform: rotate(10deg);
  margin-top: -30px;
  font-size: 14px;
`;

const ImgBox = styled.div`
  cursor: pointer;
  transition: ${props => props.theme.transitions.create(['transform'])};

  &:hover {
    transform: scale(1.05);
  }
`;

const Something = () => {
  const pageCtx = useContext(AvocadoPageContext);
  useEffect(() => pageCtx.setBgColor('#A90000'), []);

  const [typingDone, setTypingDone] = useState(false);
  const onTypingDone = () => setTypingDone(true);

  const [enter, setEnter] = useState(false);
  const onEntered = () => setEnter(true);

  const [exit, setExit] = useState(false);
  const onExit = () => setExit(true);
  const history = useHistory();
  const onExited = () => history.push('/read/avocado/association');

  return (
    <Fade in={!exit} timeout={{ enter: 0, exit: 1000 }}>
      <Box margin="auto" display="flex" flexDirection="column">
        <MultilineTypist disableCursor alignItems="flex-start" onTypingDone={onTypingDone}>
          <ItRemindsMeOfSomething>ЧТО-ТО &nbsp; ЭТО &nbsp; МНЕ</ItRemindsMeOfSomething>
          <ItRemindsMeOfSomething>НАПОМИНАЕТ</ItRemindsMeOfSomething>
        </MultilineTypist>
        <Slide in={typingDone && !exit} timeout={1000} direction="left" onEntered={onEntered} onExited={onExited}>
          <Box zIndex={1} marginLeft="auto" marginTop="-125px" onClick={onExit}>
            <ImgBox>
              <img src="/images/something_artwork.png" width={400} />
              <Fade in={enter} timeout={500}>
                <Info>
                  <b>It Reminds me of something</b>, 2018
                  <br />
                  1,20 x 1,20 m
                  <br />
                  wood, charcoal, plaster on canvas
                </Info>
              </Fade>
            </ImgBox>
          </Box>
        </Slide>
      </Box>
    </Fade>
  );
};

export default Something;
