import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import styled, { createGlobalStyle, keyframes, css } from 'styled-components';
import anime from 'animejs';
import { useHistory } from 'react-router-dom';
import Page1Quotes from './Page1Quotes';
import Page1Perception from './Page1Perception';

const Devoured = createGlobalStyle`
  body {
    transition: ${props => props.theme.transitions.create(['background-color'])};
    background-color: ${props => props.theme.palette.devoured} !important;
  }
`;

const Wrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
`;

const Section1 = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${props => props.open ? css`
    height: initial;
    min-height: 100vh;
  ` : css`
    cursor: pointer;
  `};
`;

const Section2 = styled.div`
  height: 100vh;
  width: ${props => props.width};
  transition: width 1s;
  display: flex;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
`;

const UnvourerWave = styled.svg.attrs({ viewBox: '0 0 159 1080' })`
  height: 100vh;
  width: ${props => props.width};
  transition: width 1s;
  fill: ${props => props.theme.palette.background.default};
  margin-right: -1px;
  flex-shrink: 0;

  ${props => props.open && css`
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    animation-duration: 0.5s;
    animation-name: ${keyframes`
      0% {
        filter: drop-shadow(0px 0px 0px #000000);
      }
      100% {
        filter: drop-shadow(-5px 0px 0px #000000);
      }
    `};
  `}
`;

const UnvourerBox = styled.div`
  height: 100vh;
  overflow: hidden;
  background-color: ${props => props.theme.palette.background.default};
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const d0 = `
  M107.906 1080L103.933 1044C99.6598 1008 39.2478 980 34.9747 908C31.0014 836 99.8847 656.5 107.906 584.5C115.628 512.5 -2.74908 535.5 0.999288 463.5C4.74765 391.5 111.714 329.5 87.5 257.5C63.7353 185.5 154.691 72 134.975 36L114.958 -4.98512e-06L158.896 -6.90568e-06L158.896 36C158.896 72 158.896 144 158.896 216C158.896 288 158.896 360 158.896 432C158.896 504 158.896 576 158.896 648C158.896 720 158.896 792 158.896 864C158.896 936 158.896 1008 158.896 1044L158.896 1080L107.906 1080Z
`;

const d1 = `
  M134.907 1080L130.933 1044C126.66 1008 119.164 936 114.89 864C110.917 792 110.917 720 118.939 648C126.66 576 143.153 504 146.901 432C150.65 360 143.153 288 118.939 216C95.174 144 54.6916 72 34.9752 36L14.9589 0L158.896 -6.2917e-06L158.896 36C158.896 72 158.896 144 158.896 216C158.896 288 158.896 360 158.896 432C158.896 504 158.896 576 158.896 648C158.896 720 158.896 792 158.896 864C158.896 936 158.896 1008 158.896 1044L158.896 1080L134.907 1080Z
`;

const Page1 = () => {
  const waveRef = useRef();
  const history = useHistory();

  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);

  const [waved, setWaved] = useState(false);
  useLayoutEffect(() => {
    anime({
      targets: waveRef.current,
      duration: 1500,
      easing: 'easeInOutSine',
      d: [d1],
      complete: () => {
        setTimeout(() => {
          setWaved(true);
        }, 100);
      },
    });
  }, []);

  const openQuotesAnimation = {
    targets: waveRef.current,
    duration: 1500,
    easing: 'easeInOutSine',
    d: [d0],
  };

  const [openQuotesDir, setOpenQuotesDir] = useState();
  const onQuotesClick = () => {
    if (waved) {
      setOpenQuotesDir('right');
      anime(openQuotesAnimation);
    }
  };
  const onPerceptionClick = () => {
    if (waved) {
      setOpenQuotesDir('left');
      const complete = () => history.push('/read/2');
      anime({ ...openQuotesAnimation, complete });
    }
  };

  let waveWidth = 0;
  if (openQuotesDir === 'left') {
    waveWidth = 'calc(100vw + 200px)';
  } else if (openQuotesDir === 'right') {
    waveWidth = 0;
  } else if (mounted) {
    waveWidth = '66vw';
  }

  return (
    <Wrapper>
      <Devoured />
      <Section1 onClick={onQuotesClick} open={Boolean(openQuotesDir)}>
        <Page1Quotes in={waved} open={Boolean(openQuotesDir)} />
      </Section1>
      <Section2 width={waveWidth}>
        <UnvourerWave open={!openQuotesDir}>
          <path ref={waveRef} d={d0} />
        </UnvourerWave>
        <UnvourerBox>
          <Page1Perception in={waved && !openQuotesDir} exitDir={openQuotesDir} onPerceptionClick={onPerceptionClick} />
        </UnvourerBox>
      </Section2>
    </Wrapper>
  );
};

export default Page1;
