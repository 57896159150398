import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import blue from '@material-ui/core/colors/blue';
import ListenAnimation from './ListenAnimation';

const OutlinedIconButton = styled(IconButton)`
  padding: ${props => props.theme.spacing(1)}px !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  transition-duration: ${props => props.theme.transitions.duration.standard}ms !important;
  position: relative;

  &[data-play="true"] {
    border-color: ${blue[200]} !important;
    background-color: ${blue[50]} !important;
  }
`;

const Listen = () => {
  const audio = useMemo(() => new Audio('/audio/perception.mp3'), []);

  const [playing, setPlaying] = useState(false);

  const onClick = () => {
    audio.currentTime = 0;
    audio.play();
    setPlaying(true);
  };

  useEffect(() => {
    const onEnded = () => setPlaying(false);
    audio.addEventListener('ended', onEnded);
    return () => audio.removeEventListener('ended', onEnded);
  }, [audio]);

  return (
    <OutlinedIconButton
      aria-label="Listen"
      onClick={onClick}
      color="primary"
      variant="outlined"
      data-play={playing}
    >
      <ListenAnimation play={playing} />
    </OutlinedIconButton>
  );
};

export default Listen;
