import React, { useRef, useEffect } from 'react';
import Rellax from 'rellax';
import Box from '@material-ui/core/Box';
import text from './text.svg';
import text_jan from './text_jan.svg';
import whitestroke from './whitestroke.svg';
import img_text from './img_text.png';
import pep1 from './pep1.svg';
import pep2 from './pep2.svg';
import pep3 from './pep3.svg';
import pep4 from './pep4.svg';
import pep5 from './pep5.svg';
import pep_double from './pep_double.svg';
import pep_triple from './pep_triple.svg';
import pep_mega from './pep_mega.svg';
import img_drawing1 from './img_drawing1.png';
import img_drawing2 from './img_drawing2.png';
import img_drawing3 from './img_drawing3.png';
import img_drawing4 from './img_drawing4.png';
import toxic_pear from './toxic_pear.svg';

const Section6 = () => {
  const whiteStrokeRef = useRef();
  const textImgRef = useRef();
  const toxicPearRef = useRef();
  const pep1Ref = useRef();
  const pep2Ref = useRef();
  const pep3Ref = useRef();
  const pep4Ref = useRef();
  const pep5Ref = useRef();
  const pepDoubleRef = useRef();
  const pepTripleRef = useRef();
  const pepMegaRef = useRef();
  const drawing1Ref = useRef();
  const drawing2Ref = useRef();
  const drawing3Ref = useRef();
  const drawing4Ref = useRef();
  useEffect(() => {
    new Rellax(whiteStrokeRef.current,  { speed: -0.6,  center: true });
    new Rellax(textImgRef.current,      { speed:  0.5 });
    new Rellax(toxicPearRef.current,    { speed: -0.4 });
    new Rellax(pep1Ref.current,         { speed: -0.6,  center: true });
    new Rellax(pep2Ref.current,         { speed: -0.6,  center: true });
    new Rellax(pep3Ref.current,         { speed: -0.6,  center: true });
    new Rellax(pep4Ref.current,         { speed:  0.4,  center: true });
    new Rellax(pep5Ref.current,         { speed: -0.5,  center: true });
    new Rellax(pepDoubleRef.current,    { speed: -0.4,  center: true });
    new Rellax(pepTripleRef.current,    { speed: -0.5,  center: true });
    new Rellax(pepMegaRef.current,      { speed: -0.7,  center: true });
    new Rellax(drawing1Ref.current,     { speed: -0.2,  center: true });
    new Rellax(drawing2Ref.current,     { speed: -0.4,  center: true });
    new Rellax(drawing3Ref.current,     { speed: -0.5,  center: true });
    new Rellax(drawing4Ref.current,     { speed: -0.7,  center: true });
  }, []);

  return (
    <Box position="relative" marginTop="1500px">
      <Box position="absolute" left={205} zIndex={2}>
        <img src={text} />
      </Box>
      <Box position="absolute" top={300} left={10} zIndex={2}>
        <img src={text_jan} />
      </Box>
      <Box ref={whiteStrokeRef} position="absolute" top={-85} left={164} zIndex={1}>
        <img src={whitestroke} />
      </Box>
      <Box ref={textImgRef} position="absolute" top={50} left={-26}>
        <img src={img_text} width={123} />
      </Box>
      <Box ref={drawing1Ref} position="absolute" top={60} left={49}>
        <img src={img_drawing1} width={113} />
      </Box>
      <Box ref={drawing2Ref} position="absolute" top={160} left={109}>
        <img src={img_drawing2} width={90} />
      </Box>
      <Box ref={drawing3Ref} position="absolute" top={275} left={200}>
        <img src={img_drawing3} width={70} />
      </Box>
      <Box ref={drawing4Ref} position="absolute" top={290} left={302}>
        <img src={img_drawing4} width={50} />
      </Box>
      <Box ref={pep5Ref} position="absolute" top={281} left={716}>
        <img src={pep5} />
      </Box>
      <Box ref={pep4Ref} position="absolute" top={328} left={551}>
        <img src={pep4} />
      </Box>
      <Box ref={pep3Ref} position="absolute" top={303} left={405}>
        <img src={pep3} />
      </Box>
      <Box ref={pep2Ref} position="absolute" top={266} left={325}>
        <img src={pep2} />
      </Box>
      <Box ref={pep1Ref} position="absolute" top={368} left={281}>
        <img src={pep1} />
      </Box>
      <Box ref={pepDoubleRef} position="absolute" top={260} left={160}>
        <img src={pep_double} />
      </Box>
      <Box ref={pepTripleRef} position="absolute" top={310} left={84}>
        <img src={pep_triple} />
      </Box>
      <Box ref={pepMegaRef} position="absolute" top={130} left={-12}>
        <img src={pep_mega} />
      </Box>
      <Box ref={toxicPearRef} position="absolute" top={-90} left={80}>
        <img src={toxic_pear} />
      </Box>
    </Box>
  );
};

export default Section6;
